import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Popover } from '@headlessui/react'
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import FormModal from './forms/form-modal'
import TextAreaField from './forms/fields/text-area-field'
import { formatPhoneNumber } from './format-service'

const getAgentName = (agent) => {
  let name = '';
  if (agent.prefix) name += `${agent.prefix} `
  if (agent.firstName) name += `${agent.firstName} `
  if (agent.middleName) name += `${agent.middleName} `
  if (agent.lastName) name += `${agent.lastName} `
  if (agent.suffix) name += `${agent.suffix} `
  return name;
}

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export default function Showings() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [myShowings, setMyShowings] = useState()
  const [tab, setTab] = useState(0)
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/showing`).then(x => { setData(x.incomingShowings); setMyShowings(x.myShowings) }) }, [])

  const [_selected, setSelected] = useState()
  const [selectedResponse, setSelectedResponse] = useState()
  const [responseNotes, setResponseNotes] = useState()
  const [filters, setFilters] = useState({})
  if (!data) return <Loading></Loading>
  const getColour = (status) => {
    if (status == "pending") return 'yellow'
    if (status == "declined") return 'red'
    if (status == "confirmed") return 'green'
  }
  let selected = data.find(x => x._id == _selected?._id)

  return (<>
    <PageHeader breadcrumbs={[{ name: 'Showings', url: null }]}></PageHeader>

    <FormModal open={!!selected} setOpen={() => setSelected(false)}>
      <div className="uppercase mb-5 text-neutral-800 font-medium text-xl">Showing Details</div>

      {selected && <div className="text-neutral-600">
        <div className="grid grid-cols-1 lg:grid-cols-2 justify-items-start my-5">
          <div className="flex">
            <div>
              <ImageViewer className="w-16 h-16 object-cover rounded shadow" image={selected.listing?.photos[0]} small></ImageViewer>
            </div>
            <div className="ml-5">
              <div>
                {selected.listing?.address}
              </div>
              <div className={`text-${getColour(selected.status)}-500`}>
                <span className={`inline-block w-2 h-2 rounded-full mr-5 bg-${getColour(selected.status)}-500`}></span>
                <span className="capitalize">{selected.status}</span>
              </div>
            </div>
          </div>
          <div className="mt-5 lg:mt-0">
            <div><CalendarDaysIcon className="inline w-6 mr-4" />{moment(selected.date).format('MM/DD/YYYY')}</div>
            <div><ClockIcon className="inline w-6 mr-4" />{moment(selected.date).format('hA')}</div>
          </div>
        </div>
        <div className="flex space-x-4 my-5">
          <ImageViewer person className="w-16 h-16 object-cover rounded-full shadow" image={selected.owner?.agent?.image} small></ImageViewer>
          <div className="flex-1">
            <div className="text-neutral-800 font-medium">{getAgentName(selected.owner?.agent)}</div>
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div><EnvelopeIcon className="w-6 inline mr-3"></EnvelopeIcon> {selected.owner?.agent?.contactEmail}</div>
              <div><PhoneIcon className="w-6 inline mr-3"></PhoneIcon>{formatPhoneNumber(selected.owner?.agent?.mobileNumber)}</div>
            </div>

          </div>
        </div>

        <div className="flex justify-between space-x-4">
          <div className="flex-1">
            <Button onClick={() => setSelectedResponse('confirmed')} text="Confirm" className="bg-green-500 hover:bg-green-600 w-full"></Button>
          </div>
          <div className="flex-1">
            <Button onClick={() => setSelectedResponse('declined')} text="Decline" className="bg-red-500 hover:bg-red-600 w-full"></Button>
          </div>
        </div>

        {selectedResponse && <div className="">
          <div className="my-3">
            <TextAreaField label="Message" value={responseNotes} onChange={(val) => setResponseNotes(val)} rows="3" />
          </div>
          {selectedResponse == 'confirmed' && <Button text="Confirm" onClick={() => api(`${process.env.REACT_APP_API_URL}/agent/set-showing-status`, { data: { _id: selected._id, status: selectedResponse, notes: responseNotes } }).then(x => { setResponseNotes(''); setSelected(null); setSelectedResponse(null); api(`${process.env.REACT_APP_API_URL}/agent/showing`).then(x => { setData(x.incomingShowings); setMyShowings(x.myShowings) }) })}></Button>}
          {selectedResponse == 'declined' && <Button text="Decline" className="bg-red-500 hover:bg-red-600" onClick={() => api(`${process.env.REACT_APP_API_URL}/agent/set-showing-status`, { data: { _id: selected._id, status: selectedResponse, notes: responseNotes } }).then(x => { setResponseNotes(''); setSelected(null); setSelectedResponse(null) })}></Button>}
        </div>}
      </div>}
    </FormModal>

    <div className="hidden text-red-500 bg-red-500 border-l-red-500 text-green-500 bg-green-500 border-l-green-500 text-yellow-500 bg-yellow-500 border-l-yellow-500"></div>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto my-5">
        <div className="flex">
          <div onClick={() => setFilters({ ...filters, status: null })} className={`${!filters.status ? 'bg-white text-blue-500 border-b-2 border-blue-500' : 'bg-slate-200 text-neutral-400 font-light'} text-xs lg:text-lg p-1 lg:p-3 cursor-pointer hover:bg-slate-50 px-2  lg:px-6`}>All <span className="hidden lg:inline">Requests</span></div>
          <div onClick={() => setFilters({ ...filters, status: 'pending' })} className={`${filters.status == 'pending' ? 'bg-white text-blue-500 border-b-2 border-blue-500' : 'bg-slate-200 text-neutral-400 font-light'} text-xs lg:text-lg p-1 lg:p-3 cursor-pointer hover:bg-slate-50 px-2  lg:px-6`}>Pending</div>
          <div onClick={() => setFilters({ ...filters, status: 'confirmed' })} className={`${filters.status == 'confirmed' ? 'bg-white text-blue-500 border-b-2 border-blue-500' : 'bg-slate-200 text-neutral-400 font-light'} text-xs lg:text-lg  p-1 lg:p-3 cursor-pointer hover:bg-slate-50  px-2 lg:px-6`}>Confirmed</div>
          <div onClick={() => setFilters({ ...filters, status: 'declined' })} className={`${filters.status == 'declined' ? 'bg-white text-blue-500 border-b-2 border-blue-500' : 'bg-slate-200 text-neutral-400 font-light'} text-xs lg:text-lg p-1 lg:p-3 cursor-pointer hover:bg-slate-50  px-2 lg:px-6`}>Declined</div>
        </div>
        <div className="bg-white rounded shadow overflow-hidden">
          

          <div className="p-2">
            <div className="mb-5">
              <input value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="rounded border p-1 px-2 w-full outline-none placeholder:text-neutral-300" placeholder="Search..."></input>
            </div>

            {![...(myShowings ?? []), ...(data ?? [])].length && <div className="text-center mt-10 mb-10">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-semibold text-neutral-700">No Showings Yet</h3>
              <p className="mt-1 text-sm text-gray-500">You will be notified when someone requests a showing.</p>

            </div>}

            <div className="flex flex-col space-y-2 justify-between">
              {[...myShowings, ...data].sort((a, b) => new Date(a.date).getTime() - new Date(a.date).getTime()).filter(x => (!filters.status || x.status == filters.status) && (!filters.search || Object.values(flattenObject({ ...x })).findIndex(y => ('' + y).toLowerCase().includes(filters.search.toLowerCase())) != -1)).map(x => <div onClick={() => setSelected(x)} className={`flex space-x-2 rounded border border-l-4 border-l-${getColour(x.status)}-500 cursor-pointer p-4 justify-between text-neutral-600 items-center`}>
                <div className="flex-1">
                  <div>
                    {x.listing?.address}
                  </div>
                  <div className={`text-${getColour(x.status)}-500`}>
                    <span className={`inline-block w-2 h-2 rounded-full mr-5 bg-${getColour(x.status)}-500`}></span>
                    <span className="capitalize">{x.status}</span>
                  </div>

                </div>
                <div className="flex-1">
                  <div><CalendarDaysIcon className="inline w-6 mr-4" />{moment(x.date).format('MM/DD/YYYY')}</div>
                  <div><ClockIcon className="inline w-6 mr-4" />{moment(x.date).format('hA')}</div>
                </div>
                <div className="flex-1 space-x-4 pr-10 hidden lg:flex">
                  <ImageViewer person className="w-16 h-16 object-cover rounded-full shadow" image={x.owner?.agent?.image} small></ImageViewer>
                  <div>
                    <div className="text-neutral-800 font-medium">{getAgentName(x.owner?.agent)}</div>
                    <div>{x.owner?.agent?.mobileNumber}</div>
                    <div>{x.owner?.agent?.contactEmail}</div>
                  </div>
                </div>
              </div>)}
            </div>




          </div>


        </div>
      </div>
    </div>
  </>
  )
}