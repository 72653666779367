import ReactInputMask from "react-input-mask";



export default function PhoneField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, type, required }) {
    return <div className={className + ' mt-2'} >
        <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
            {label} {required && <span className="text-red-500">*</span>}
        </label>
        <div className="mt-1">
            <ReactInputMask mask="999-999-9999"  value={value} onChange={(e) => onChange(e.target.value)}>
                {(inputProps) => <input {...inputProps} required={required} type={type ? type : 'text'} onBlur={onBlur} placeholder={placeholder} className={`bg-slate-100 block w-full rounded border-0 px-3 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
                }
            </ReactInputMask>
        </div>
    </div>
}