import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";
import ImageUpload from "./fields/image-upload";
import CurrencyField from "./fields/currency-field";
import DateField from "./fields/date-field";
import CheckboxField from "./fields/checkbox-field";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import NumberField from "./fields/number-field";
import Button from "../components/button";
import FileView from "../components/file-view";
import { useState } from "react";

export default function OfferForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [fillObj, setFillObj] = useState({});
    const [tab, setTab] = useState(0);


    return <div >
        <PageHeader breadcrumbs={[{ name: 'Create Offer', url: null }]}></PageHeader>
        <div className="lg:hidden flex text-center">
            <div onClick={() => setTab(0)} className={`${tab == 0 ? 'bg-white border-b-2 border-blue-500' : 'bg-slate-50 border-b'} flex-1 text-lg text-neutral-600 p-2`}>Form</div>
            <div onClick={() => setTab(1)} className={`${tab == 1 ? 'bg-white border-b-2 border-blue-500' : 'bg-slate-50 border-b'} flex-1 text-lg text-neutral-600 p-2`}>Document</div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 h-[calc(100vh-140px)] pb-10 lg:overflow-hidden">
            <div className={`${tab == 1 ? 'lg:block hidden' : ''} lg:overflow-y-auto bg-white p-5 m-3 rounded shadow`}>


                <FormWrapper noStyle={true} url="agent/offer" noLoad noId {...props} callback={() => navigate('/app/offers')} defaultValue={{ listing: params.id }}>
                    {(values, setValues) =>
                        <div className="w-full">
                            <div className="mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Offer Details</div>

                            <div className="grid grid-cols-1 gap-4">
                                <SelectField optionListName={'buyers'} label="Buyer" value={values.client} onChange={(val) => { setValues({ ...values, client: val }); setFillObj({ ...values, client: val }) }} />
                                <div className="text-xs text-neutral-700 underline cursor-pointer" onClick={() => navigate('/manage-client/new')}>Create a new Buyer</div>
                                <CurrencyField className="w-full" onBlur={() => setFillObj(values)} label="Offer Amount" value={values.offerAmount} onChange={(val) => setValues({ ...values, offerAmount: val })} />
                                <CurrencyField className="w-full" onBlur={() => setFillObj(values)} label="Earnest Money Deposit" value={values.goodFaithDeposit} onChange={(val) => setValues({ ...values, goodFaithDeposit: val })} />
                                <CurrencyField className="w-full" onBlur={() => setFillObj(values)} label="Down Payment (Includes EDM)" value={values.downPayment} onChange={(val) => setValues({ ...values, downPayment: val })} />
                                <CurrencyField className="w-full" onBlur={() => setFillObj(values)} label="Subject to Mortgage" value={values.subjectToMortgageAmount} onChange={(val) => setValues({ ...values, subjectToMortgageAmount: val })} />
                                <CurrencyField className="w-full" onBlur={() => setFillObj(values)} label="Cash on Closing" value={values.cashOnClosing} onChange={(val) => setValues({ ...values, cashOnClosing: val })} />
                                <CurrencyField className="w-full" onBlur={() => setFillObj(values)} label="Seller Concession" value={values.sellerConcession} onChange={(val) => setValues({ ...values, sellerConcession: val })} />
                                <SelectField onBlur={() => setFillObj(values)} options={[{ text: 'Variable Rate', value: 'Variable Rate' }, { text: 'Fixed Rate', value: 'Fixed Rate' }]} label="Mortgage Type" value={values.mortgageType} onChange={(val) => setValues({ ...values, mortgageType: val })} />
                                <NumberField onBlur={() => setFillObj(values)} label="Mortgage Period " value={values.mortgagePeriod} onChange={(val) => setValues({ ...values, mortgagePeriod: val })} />
                                <TextField onBlur={() => setFillObj(values)} label="Mortgage Held By" value={values.mortgageHeldBy} onChange={(val) => setValues({ ...values, mortgageHeldBy: val })} />
                                <NumberField onBlur={() => setFillObj(values)} label="Mortgage Interest" value={values.mortgageInterest} onChange={(val) => setValues({ ...values, mortgageInterest: val })} />
                                <NumberField onBlur={() => setFillObj(values)} label="Monthly Payment" value={values.mortgageMonthlyPayment} onChange={(val) => setValues({ ...values, mortgageMonthlyPayment: val })} />
                                <DateField onBlur={() => setFillObj(values)} label="Payment Due Date" value={values.paymentDueDate} onChange={(val) => setValues({ ...values, paymentDueDate: val })} />
                                <NumberField onBlur={() => setFillObj(values)} label="Mortgage Due (days from formal contract)" value={values.mortgageDueDays} onChange={(val) => setValues({ ...values, mortgageDueDays: val })} />
                                <DateField onBlur={() => setFillObj(values)} label="Proposed Formal Contract Date" value={values.formalContractDate} onChange={(val) => setValues({ ...values, formalContractDate: val })} />
                                <DateField onBlur={() => setFillObj(values)} label="Proposed Closing Date" value={values.proposedClosingDate} onChange={(val) => setValues({ ...values, proposedClosingDate: val })} />
                                <TextField onBlur={() => setFillObj(values)} label="Proposed Closing Location" value={values.proposedClosingLocation} onChange={(val) => setValues({ ...values, proposedClosingLocation: val })} />
                                
                                <TextField onBlur={() => setFillObj(values)} label="Buyer's Attorney" value={values.buyersAttorney} onChange={(val) => setValues({ ...values, buyersAttorney: val })} />
                                <TextField onBlur={() => setFillObj(values)} label="Buyer's Attorney's Address" value={values.buyersAttorneyAddress} onChange={(val) => setValues({ ...values, buyersAttorneyAddress: val })} />
                                <TextField onBlur={() => setFillObj(values)} label="Buyer's Attorney's Phone Number" value={values.buyersAttorneyPhone} onChange={(val) => setValues({ ...values, buyersAttorneyPhone: val })} />
                                
                                <TextField onBlur={() => setFillObj(values)} label="Sellers's Attorney" value={values.sellersAttorney} onChange={(val) => setValues({ ...values, sellersAttorney: val })} />
                                <TextField onBlur={() => setFillObj(values)} label="Sellers's Attorney's Address" value={values.sellersAttorneyAddress} onChange={(val) => setValues({ ...values, sellersAttorneyAddress: val })} />
                                <TextField onBlur={() => setFillObj(values)} label="Sellers's Attorney's Phone Number" value={values.sellersAttorneyPhone} onChange={(val) => setValues({ ...values, sellersAttorneyPhone: val })} />
                                
                                <TextAreaField label="Note To Listing Agent" value={values.notes} onChange={(val) => setValues({ ...values, notes: val })} />
                                <CheckboxField label="Send to client for signature" value={values.requiresSignature} onChange={(val) => setValues({ ...values, requiresSignature: val })} />
                                <div className="text-xs text-neutral-500">If checked the generated offer document will be sent to the client for approval and a signature before being submitted.</div>

                            </div>
                        </div>
                    }
                </FormWrapper>


            </div>

            <div className={`${tab == 0 ? 'lg:block hidden' : ''} col-span-2 flex-1 bg-white m-3 rounded shadow first-letter:flex justify-center lg:overflow-y-auto mt-3`}>
                <FileView url={`agent/offer-document/${params.id}`} object={fillObj}></FileView>

            </div>
        </div>

    </div>



}