import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";
import ImageUpload from "./fields/image-upload";
import CurrencyField from "./fields/currency-field";
import DateField from "./fields/date-field";
import CheckboxField from "./fields/checkbox-field";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import Button from "../components/button";

export default function ReplyToOfferForm(props) {

    return <div>
        <FormWrapper noStyle={true} url="agent/reply-to-offer" noId {...props} callback={() => props.callback()} defaultValue={{ id: props.id, status: props.status }}>
            {(values, setValues) =>
                <div className="">
                    <div className="capitalize text-xl font-semibold text-neutral-800 mb-10">{props.status} Offer</div>
                    <TextAreaField label="Message" value={values.statusMessage} onChange={(val) => setValues({ ...values, statusMessage: val })} />
                </div>
            }
        </FormWrapper>
    </div>
}