import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";
import ImageUpload from "./fields/image-upload";
import DateField from "./fields/date-field";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import PhoneField from "./fields/phone-field";

export default function ClientForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader breadcrumbs={[{ name: 'Clients', url: 'clients' }, { name: 'Manage Client', url: null }]}></PageHeader>
        <FormWrapper defaultValue={{status: 'active'}} url="agent/client" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) =>
                <>
                    <div className="mb-5 text-slate-600 font-medium text-xl uppercase leading-0">General Details</div>
                    <ImageUpload profile value={values.image} onChange={(val) => setValues({ ...values, image: val })}></ImageUpload>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <SelectField required placeholder="Select Client Type" options={[{ value: 'buyer', text: 'Buyer' }, { value: 'seller', text: 'Seller' }]} label="Client Type" value={values.type} onChange={(val) => setValues({ ...values, type: val })}></SelectField>
                        <SelectField required options={[{ value: 'active', text: 'Active' }, { value: 'inactive', text: 'Inactive' }]} label="Status" value={values.status} onChange={(val) => setValues({ ...values, status: val })}></SelectField>
                    </div>

                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Primary Client Details</div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <TextField required label="First Name" value={values.firstName} onChange={(val) => setValues({ ...values, firstName: val })}></TextField>
                        <TextField required label="Last Name" value={values.lastName} onChange={(val) => setValues({ ...values, lastName: val })}></TextField>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <PhoneField label="Phone Number" value={values.phone} onChange={(val) => setValues({ ...values, phone: val })}></PhoneField>
                        <TextField required type='email' label="Email Address" value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                    </div>
                    <TextField placeholder={'Enter your clients full address'} label="Address" value={values.address} onChange={(val) => setValues({ ...values, address: val })}></TextField>

                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Secondary Client Details</div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <TextField label="First Name" value={values.secondaryFirstName} onChange={(val) => setValues({ ...values, secondaryFirstName: val })}></TextField>
                        <TextField label="Last Name" value={values.secondaryLastName} onChange={(val) => setValues({ ...values, secondaryLastName: val })}></TextField>
                    </div>

                </>
            }
        </FormWrapper>
    </div>
}