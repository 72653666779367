import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';

export default function FileView({ url, object }) {

  const [numPages, setNumPages] = useState(null);

  const params = useParams();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [])

  const [initialWidth, setInitialWidth] = useState(null);
  const pdfWrapper = useRef();

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width - 20);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setPdfSize());
    setPdfSize();
    return () => {
      window.removeEventListener('resize', setPdfSize());
    };
  }, []);

  const [file, setFile] = useState()
  useEffect(() => {
    if (object) {
      fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ offer: object })
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          setFile(url)
        });
    }
    else {
      fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          setFile(url)
        });
    }

  }, [url, object])


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="w-full h-full flex-1" ref={pdfWrapper}>
      <Document onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} file={file} >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={initialWidth}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ),
        )}

      </Document>
    </div>

  )
}

