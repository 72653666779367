import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginCheck } from './api.service';
import logo from './logo.svg'
import loginPhoto from './signin_page.png'

export default function ResetPassword() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setValues({ ...values, token: searchParams.get('token'), id: searchParams.get('id') })
  }, []);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(res => { return res.json() }).then(x => {
      if (x.status === "success") {
        window.location.replace("/login")
      }
      else {
        setError("Failed to reset password. Please try again or contact us for help.")
      }
    })
  }

  return (
    <>
      <div className="flex max-h-screen flex-col bg-slate-50">
        <div className="grid lg:grid-cols-2 grid-cols-1 flex-1">
          <div className="flex flex-col justify-center max-h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-64 h-auto mb-10"
                src={logo}
              />
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="bg-white rounded py-8 px-4 shadow sm:px-10">
                <div className="font-medium text-xl text-neutral-800 text-center">Reset Password</div>
                <div className="font-light text-sm text-neutral-500 text-center my-5">Enter your new password below</div>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-neutral-800 text-center">
                      {error}
                    </label>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-neutral-800">
                      Password
                    </label>
                    <div className="mt-1">
                      <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-neutral-800">
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input value={values.confirmPassword} onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                      />
                    </div>
                  </div>


                  <div>
                    <button
                      onClick={() => submit()}
                      type="button"
                      disabled={!values.password || values.password != values.confirmPassword}
                      className="flex rounded  w-full justify-center border border-transparent bg-blue-500 py-2 px-4 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Reset Password
                    </button>
                    <div className="my-5 text-center text-neutral-500  text-sm">
                      Go back to <a className="text-blue-400 hover:text-blue-500" href="/login">Sign In</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="lg:flex hidden max-h-screen">
            <img src={loginPhoto} className="object-cover flex-1"></img>
          </div>
        </div>

      </div>
    </>
  )
}
