import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Transition } from '@headlessui/react'
import logo from './logo-dark.svg'
import {
  ArrowDownIcon,
  UserGroupIcon,
  XMarkIcon,
  ChartBarIcon,
  BuildingOfficeIcon,
  HomeIcon,
  InboxIcon,
  CalendarIcon,
  UserIcon,
  Bars3Icon,
  BellAlertIcon,
  BellIcon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { api } from './api.service'
import ImageViewer from './components/image-viewer'

const navigation = [
  { name: 'Home', href: '/app', icon: HomeIcon },
  { name: 'Clients', href: '/app/clients', icon: UserGroupIcon },
  { name: 'Listings', href: '/app/listings', icon: BuildingOfficeIcon },
  { name: 'Offers', href: '/app/offers', icon: InboxIcon },
  { name: 'Showings', href: '/app/showings', icon: CalendarIcon }
]
const secondaryNavigation = [
  { name: 'Logout', href: '/logout', icon: ArrowDownIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Layout() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({})
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/basic-data`).then(x => setProfileData(x)) }, [])

  const deleteNotification = (id) => {
    api(`${process.env.REACT_APP_API_URL}/agent/archive-notification/${id}`).then(x => api(`${process.env.REACT_APP_API_URL}/agent/basic-data`).then(x => setProfileData(x)))
  }
  const notificationsViewed = () => {
    api(`${process.env.REACT_APP_API_URL}/agent/read-notifications`).then(x => api(`${process.env.REACT_APP_API_URL}/agent/basic-data`).then(x => setProfileData(x)))
  }

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-slate-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-slate-700 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="w-[80%] h-auto object-cover"
                      src={logo}
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-slate-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <>
                          {item.category && <div className="text-lg">{item.category}</div>}
                          <Link
                            onClick={() => setSidebarOpen(false)}
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              location.pathname.endsWith(item.href)
                                ? 'bg-slate-800 text-white'
                                : 'text-slate-100 hover:text-white hover:bg-slate-600',
                              'group flex items-center px-2 py-2 text-base font-medium rounded'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-slate-200" aria-hidden="true" />
                            {item.name}
                          </Link>

                        </>

                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="group flex items-center rounded px-2 py-2 text-base font-medium text-slate-100 hover:bg-slate-600 hover:text-white"
                          >
                            <item.icon className="mr-4 h-6 w-6 text-slate-200" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto bg-slate-800 pt-10 px-2 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="w-full mx-auto object-cover"
                src={logo}
              />
            </div>

            <nav className="mt-5 flex flex-1 flex-col divide-y divide-slate-800 overflow-y-auto" aria-label="Sidebar">
              <div className="space-y-2 px-2 flex-1">
                {navigation.map((item) => (
                  <>
                    {item.category && <div className="text-xs text-slate-500 font-semibold ml-3 pt-5">{item.category}</div>}
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname.endsWith(item.href) ? 'bg-slate-700 text-white' : 'text-slate-500 hover:text-slate-100 hover:bg-slate-600',
                        'group flex items-center px-4 py-3 text-sm leading-6 font-medium rounded'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon className="mr-4 h-6 w-6 flex-shrink-0 " aria-hidden="true" />
                      {item.name}
                    </Link>
                  </>

                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="space-y-1 px-2">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group flex items-center rounded px-4 py-3 text-sm font-medium leading-6 text-slate-500 hover:bg-slate-600 hover:text-slate-100"
                    >
                      <item.icon className="mr-4 h-6 w-6" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-1 flex-col lg:pl-64 bg-slate-50 min-h-screen">
          <div className="lg:bg-white bg-slate-800 text-white lg:text-neutral-800 h-16 flex px-5">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
            <div className=" h-16 2xl:max-w-6xl max-w-5xl w-full mx-auto flex items-center space-x-4 justify-end">
              <div>

                <Popover className="relative">
                  <Popover.Button><div className="relative">
                    <BellIcon onClick={() => notificationsViewed()} className="w-8 text-neutral-500"></BellIcon>
                    {profileData?.notifications?.findIndex(x => !x.viewed) != -1 && <span className="animate-pulse absolute w-2 h-2 rounded-full inline-block bg-blue-500 top-1 right-1"></span>}
                  </div></Popover.Button>

                  <Popover.Panel className="fixed right-0 lg:-right-5 z-20 bg-white shadow rounded">
                    <div className="flex flex-col p-3 w-full lg:w-96">
                      {!profileData.notifications?.length && <div className="font-semibold text-neutral-600 text-center p-3">No Notifications</div>}
                      {profileData?.notifications?.slice().reverse().map(x => <div className="border-b border-neutral-200 py-2 relative">
                        <div className="absolute top-1 right-1"><XMarkIcon onClick={() => deleteNotification(x._id)} className="w-6 text-neutral-500 hover:text-neutral-700 cursor-pointer"></XMarkIcon></div>
                        <div className="text-sm font-semibold text-neutral-700">{x.title} {!x.viewed && <span className="inline-block w-2 h-2 rounded-full bg-blue-500 ml-2"></span>}</div>
                        <div className="text-xs text-neutral-500 line-clamp-4">{x.description}</div>
                      </div>)}
                    </div>
                  </Popover.Panel>
                </Popover>

              </div>
              <div className="flex space-x-4 cursor-pointer" onClick={() => navigate('/app/profile')}>
                <ImageViewer person className="my-auto rounded-full shadow w-10 h-10 object-cover" image={profileData.picture} xs></ImageViewer>
                <div className="my-auto font-medium">{profileData.name}</div>
              </div>
            </div>

          </div>
          <main className="flex-1 px-5">

            <Outlet></Outlet>
          </main>
        </div>
      </div>
    </>
  )
}
