import { HomeIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from 'react-router-dom'
import ImageViewer from "./image-viewer";
import logo from '../logo.svg'

export default function PageHeader({ breadcrumbs, actions, showLogo, homeUrl, column }) {
    const navigate = useNavigate();
    return <div className="">
        <div className="lg:mx-auto lg:max-w-6xl">
            <div className={`py-6 flex lg:flex-row ${column ? 'flex-col' : ''}  items-center justify-between`}>
                {showLogo && <div><img src={logo}></img></div>}

                <div className="min-w-0 flex-1">
                    <div className="flex items-center">
                        <div>
                            <div className="flex items-center">
                                {breadcrumbs && <HomeIcon onClick={() => navigate(homeUrl ??'/app')} className="text-slate-500 h-5 inline cursor-pointer hover:text-slate-700"></HomeIcon>}
                                {breadcrumbs?.map(x => <div onClick={() => x.url ? navigate('/app/' + x.url) : {}}>
                                    <ChevronRightIcon className="text-slate-400 h-6 inline"></ChevronRightIcon>
                                    <span className="text-slate-500 font-medium text-sm cursor-pointer hover:text-slate-700">{x.name}</span>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:mt-6 flex items-center space-x-3 mt-0 ml-4">
                    {actions}
                </div>
            </div>
        </div>
    </div>
}