import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Popover } from '@headlessui/react'
import { formatPhoneNumber } from './format-service'
import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/24/outline'


function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export default function Clients() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({})

  const [data, setData] = useState()
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/agent/client/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/agent/client`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/client`).then(x => { setData(x); if (width > 1024) setSelected(x[0]) }) }, [])

  const [selected, setSelected] = useState()

  if (!data) return <Loading></Loading>

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
    <PageHeader breadcrumbs={[{ name: 'Clients', url: 'clients' }]} actions={<Button text="Add Client" onClick={() => navigate('/app/manage-client/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto my-5">
        {!data.length && <div className="text-center mt-10">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-neutral-700">No Clients Yet</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by creating a new client.</p>
          <div className="mt-6">
            <button onClick={() => navigate('/app/manage-client/new')}
              type="button"
              className="inline-flex items-center rounded bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              New Client
            </button>
          </div>
        </div>}
        {data.length > 0 && <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
          <div className={`${selected ? 'hidden lg:block' : ''}`}>
            <div className="bg-white rounded shadow p-2">
              <div className="mb-5">
                <input value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="outline-none rounded border p-1 px-2 w-full placeholder:text-neutral-300" placeholder="Search Client or Listing Address..."></input>
              </div>
              {data.filter(x => (!filters.search || Object.values(flattenObject({ ...x })).findIndex(y => ('' + y).toLowerCase().includes(filters.search.toLowerCase())) != -1)).map(x => <div onClick={() => setSelected(x)} className={`${selected?._id == x._id ? 'bg-blue-300' : 'hover:bg-blue-200'} flex space-x-2 rounded cursor-pointer p-2`}>
                <ImageViewer person className="w-16 h-16 object-cover rounded-md shadow" image={x.image} small></ImageViewer>
                <div className="my-auto">
                  {x.type == "buyer" && <div className="font-medium text-neutral-600 text-sm"><div className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-500"></div>Buyer</div>}
                  {x.type == "seller" && <div><span className="inline-block w-2 h-2 mr-2 rounded-full bg-red-500"></span>Seller</div>}
                  <div className="font-medium text-neutral-700">{x.firstName} {x.lastName}</div>
                </div>
              </div>)}
            </div>
          </div>
          <div className="col-span-2">
            {selected && <div onClick={() => setSelected(null)} className="block lg:hidden  mb-2 text-neutral-500 font-medium hover:text-neutral-600 text-sm"><ChevronLeftIcon className="w-6 inline" /> Client List</div>}

            {selected && <div className="bg-white rounded shadow p-5">
              <div className="flex">
                <ImageViewer person className="w-16 h-16 object-cover rounded-full shadow mr-5" image={selected.image}></ImageViewer>
                <div className={`my-auto inline-block w-2 h-2 mr-2 rounded-full ${selected.type == "buyer" ? 'bg-blue-500' : ''} ${selected.type == "seller" ? 'bg-red-500' : ''}`}></div>
                <div className="my-auto text-2xl font-medium text-neutral-700">{selected.firstName} {selected.lastName}</div>
                <div className="flex-1"></div>
                <div>
                  <Popover className="relative">
                    <Popover.Button><EllipsisHorizontalIcon className="w-6 text-neutral-400 hover:text-neutral-500 cursor-pointer"></EllipsisHorizontalIcon></Popover.Button>

                    <Popover.Panel className="absolute -left-5 z-10 bg-white shadow rounded">
                      <div className="flex flex-col">
                        <div className="hover:bg-neutral-100 py-2 px-4 text-medium cursor-pointer text-neutral-700 text-sm" onClick={() => navigate(`/app/manage-client/${selected._id}`)}>Edit</div>
                        <div className="hover:bg-neutral-100 py-2 px-4 text-medium cursor-pointer text-neutral-700 text-sm" onClick={() => setDeleteId(selected._id)}>Delete</div>
                      </div>
                    </Popover.Panel>
                  </Popover>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6 mt-10">
                <div>
                  <div className="text-neutral-500 font-light mb-1 text-sm">Status</div>
                  {selected.status == "active" && <div className="inline p-2 bg-green-50/50 text-green-500 font-semibold rounded text-sm">Active</div>}
                  {selected.status == "inactive" && <div className="inline p-2 bg-red-50 text-red-500 font-semibold rounded text-sm">Inactive</div>}
                </div>
                <div>
                  <div className="text-neutral-500 font-light mb-1 text-sm">Relationship</div>
                  {selected.type == "buyer" && <div className="text-neutral-700 font-medium">Buyer</div>}
                  {selected.type == "seller" && <div className="text-neutral-700 font-medium">Seller</div>}
                </div>

                <div>
                  <div className="text-neutral-500 font-light mb-1 text-sm">First Name</div>
                  <div className="text-neutral-700 font-medium">{selected.firstName}</div>
                </div>

                <div>
                  <div className="text-neutral-500 font-light mb-1 text-sm">Last Name</div>
                  <div className="text-neutral-700 font-medium">{selected.lastName}</div>
                </div>

                <div className="col-span-2 lg:col-span-1">
                  <div className="text-neutral-500 font-light mb-1 text-sm">Email Address</div>
                  <div className="text-neutral-700 font-medium">{selected.email}</div>
                </div>

                <div className="col-span-2 lg:col-span-1">
                  <div className="text-neutral-500 font-light mb-1 text-sm">Phone Number</div>
                  <div className="text-neutral-700 font-medium">{formatPhoneNumber(selected.phone)}</div>
                </div>

                <div className="col-span-2">
                  <div className="text-neutral-500 font-light mb-1 text-sm">Address</div>
                  <div className="text-neutral-700 font-medium">{selected.address}</div>
                </div>
              </div>
            </div>}

            {selected && <div className="bg-white rounded shadow p-5 mt-5">
            <div className="flex">
                <div className={`my-auto inline-block w-2 h-2 mr-2 rounded-full ${selected.type == "buyer" ? 'bg-blue-500' : ''} ${selected.type == "seller" ? 'bg-red-500' : ''}`}></div>
                <div className="my-auto text-2xl font-medium text-neutral-700">Secondary Client</div>
                
              </div>
              <div className="grid grid-cols-2 gap-6 mt-10">
               
                <div>
                  <div className="text-neutral-500 font-light mb-1 text-sm">First Name</div>
                  <div className="text-neutral-700 font-medium">{selected.secondaryFirstName}</div>
                </div>

                <div>
                  <div className="text-neutral-500 font-light mb-1 text-sm">Last Name</div>
                  <div className="text-neutral-700 font-medium">{selected.secondaryLastName}</div>
                </div>

              </div>
            </div>}
          </div>
        </div>}

      </div>
    </div>
  </>
  )
}