import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";
import ImageUpload from "./fields/image-upload";
import DateField from "./fields/date-field";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import Button from "../components/button";
import { useState } from "react";
import MultiSelectField from "./fields/multiselect-field";
import PhoneField from "./fields/phone-field";

export default function AdminAgentForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader breadcrumbs={[{ name: 'Agent', url: null }]}></PageHeader>
        <FormWrapper url="admin/agent" id={id} {...props} callback={() => navigate('/admin/users')}>
            {(values, setValues) =>
                <div className="flex flex-col">
                    <div className="mb-5 text-slate-600 font-medium text-xl uppercase leading-0">General Details</div>

                    <ImageUpload profile value={values.image} onChange={(val) => setValues({ ...values, image: val })}></ImageUpload>

                    <div className="grid md:grid-cols-8 grid-cols-3 gap-2">
                        <TextField label="Prefix" value={values.prefix} onChange={(val) => setValues({ ...values, prefix: val })}></TextField>
                        <TextField className="col-span-2" label="First Name" value={values.firstName} onChange={(val) => setValues({ ...values, firstName: val })}></TextField>
                        <TextField className="col-span-2" label="Middle Name" value={values.middleName} onChange={(val) => setValues({ ...values, middleName: val })}></TextField>
                        <TextField className="col-span-2" label="Last Name" value={values.lastName} onChange={(val) => setValues({ ...values, lastName: val })}></TextField>
                        <TextField label="Suffix" value={values.suffix} onChange={(val) => setValues({ ...values, suffix: val })}></TextField>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
                        <PhoneField label="Mobile Number" value={values.mobileNumber} onChange={(val) => setValues({ ...values, mobileNumber: val })}></PhoneField>
                        <PhoneField label="Office Number" value={values.officeNumber} onChange={(val) => setValues({ ...values, officeNumber: val })}></PhoneField>
                        <TextField type='email' label="Contact Email" value={values.contactEmail} onChange={(val) => setValues({ ...values, contactEmail: val })}></TextField>
                    </div>
                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">License Details</div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <SelectField optionListName={'states'} label="License State" value={values.licenseState} onChange={(val) => setValues({ ...values, licenseState: val })}></SelectField>
                        <TextField label="License Number" value={values.licenseNumber} onChange={(val) => setValues({ ...values, licenseNumber: val })}></TextField>

                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        <DateField label="Licensed Date" value={values.licenseStartDate} onChange={(val) => setValues({ ...values, licenseStartDate: val })}></DateField>
                        <DateField label="License Expiration" value={values.licenseEndDate} onChange={(val) => setValues({ ...values, licenseEndDate: val })}></DateField>
                    </div>

                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Company Details</div>
                    <SelectField optionListName={'brokers'} label="Company" value={values.broker} onChange={(val) => setValues({ ...values, broker: val })}></SelectField>


                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Team Details</div>
                    <ImageUpload profile value={values.teamLogo} onChange={(val) => setValues({ ...values, teamLogo: val })}></ImageUpload>

                    <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
                        <TextField label="Team Name" value={values.teamName} onChange={(val) => setValues({ ...values, teamName: val })}></TextField>
                        <TextField label="Team Leader" value={values.teamLeader} onChange={(val) => setValues({ ...values, teamLeader: val })}></TextField>
                        <TextField label="Team Website" value={values.teamWebsite} onChange={(val) => setValues({ ...values, teamWebsite: val })}></TextField>
                    </div>
                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Associations</div>

                    <MultiSelectField optionListName={'stateAssociations'} label="State Associations" value={values.stateAssociations} onChange={(val) => setValues({ ...values, stateAssociations: val })}></MultiSelectField>
                    <MultiSelectField optionListName={'localAssociations'} label="Local Associations" value={values.localAssociations} onChange={(val) => setValues({ ...values, localAssociations: val })}></MultiSelectField>
                    <MultiSelectField optionListName={'mlsAssociations'} label="MLS Associations" value={values.mlsAssociations} onChange={(val) => setValues({ ...values, mlsAssociations: val })}></MultiSelectField>
                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Serviced Areas</div>

                    {values.serviceAreas?.map((x, i) => <TextField label={'Service Area ' + (i + 1)} value={x} onChange={(val) => setValues({ ...values, serviceAreas: values.serviceAreas.map((y, j) => j === i ? val : y) })}></TextField>)}
                    <Button className="w-64 mx-auto mt-5" text="Add Served Area" onClick={() => setValues({ ...values, serviceAreas: values.serviceAreas ? values.serviceAreas.concat(['']) : [''] })}></Button>
                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Social Profiles</div>

                    {values.socialProfile?.map((x, i) => <TextField label={'Profile URL ' + (i + 1)} value={x.url} onChange={(val) => setValues({ ...values, socialProfile: values.socialProfile.map((y, j) => j === i ? { ...y, url: val } : y) })}></TextField>)}
                    <Button className="w-64 mx-auto mt-5" text="Add Social Profile" onClick={() => setValues({ ...values, socialProfile: values.socialProfile ? values.socialProfile.concat([{}]) : [{}] })}></Button>
                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Professional Profiles</div>

                    {values.professionalProfile?.map((x, i) => <TextField label={'Profile URL ' + (i + 1)} value={x.url} onChange={(val) => setValues({ ...values, professionalProfile: values.professionalProfile.map((y, j) => j === i ? { ...y, url: val } : y) })}></TextField>)}
                    <Button className="w-64 mx-auto mt-5" text="Add Professional Profile" onClick={() => setValues({ ...values, professionalProfile: values.professionalProfile ? values.professionalProfile.concat([{}]) : [{}] })}></Button>
                </div>
            }
        </FormWrapper >
    </div >
}