import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import moment from 'moment'

export default function Dashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [offers, setOffers] = useState()
  const [showings, setShowings] = useState()

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/agent`).then(x => {
      if (x.status == "no agent") {
        navigate('/app/profile')
      }
      else {
        setData(x)
      }
    })
  }, [])

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/offer`).then(x => { setOffers(x); }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/showing`).then(x => { setShowings(x.incomingShowings); }) }, [])
  const getColour = (status) => {
    if (status == "Approved") return 'green'
    if (status == "Countered") return 'gray'
    if (status == "Rejected") return 'red'
    return 'yellow'
  }
  const getColourListing = (status) => {
    if (status == "confirmed") return 'green'
    if (status == "rejected") return 'red'
    return 'yellow'
  }

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={"Dashboard"} ></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto">
        <div className="bg-white rounded shadow p-10">
          <div className="text-blue-500 font-semibold mb-5 text-3xl">Welcome to RealtyView, {data.name}</div>
          <div className="text-neutral-700 text-md">RealtyView has one mission; To create a simple platform that helps Agents conduct the core functions of their business. We will rely heavily on the Agent and Broker community for feedback in order to carry out our mission. To start, we are launching with forever-free access to your relationship, listing, and offer management tools.
            Click on the feature below to learn more.
          </div>
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-6 mt-10">
            <div className="col-span-2">
              <div className="text-xl font-semibold text-neutral-800">Listings</div>
              <div className="text-sm text-neutral-700 mt-2 mb-8">Easily import and manage the tasks and incoming offers on your listings in the listing manager. In order to receive offer directly to your offer inbox, you must import your listing.</div>
              <div className="text-xl font-semibold text-neutral-800">Showings</div>
              <div className="text-sm text-neutral-700 mt-2 mb-8">With the offer manager, you can securely send and receive offers on any listing while staying organized in one central offer inbox. Buyer Agents can submit an offer via RealtyView even if the Listing Agent isn't a RealtyView member yet.</div>
              <div className="text-xl font-semibold text-neutral-800">Offers</div>
              <div className="text-sm text-neutral-700 mt-2">With the offer manager, you can securely send and receive offers on any listing while staying organized in one central offer inbox. Buyer Agents can submit an offer via RealtyView even if the Listing Agent isn't a RealtyView member yet.</div>
            </div>
            <div>
              <div className="text-2xl font-semibold text-green-500 mb-5">Get Involved</div>
              <div className="text-sm text-neutral-700">
                You can visit our support portal to learn more about RealtyView and answer any questions you may have.
              </div>
              <div className="my-5">
                <a target="_blank" href="https://app.loopedin.io/realtyview#/give-feedback" className="text-blue-400 hover:text-blue-500 cursor-pointer">Give Feedback</a>
              </div>
              <div className="my-5">
                <a target="_blank" href="https://app.loopedin.io/realtyview#/ideas" className="text-blue-400 hover:text-blue-500 cursor-pointer">Share an Idea</a>
              </div>
              <div className="my-5">
                <a target="_blank" href="https://app.loopedin.io/realtyview#/updates" className="text-blue-400 hover:text-blue-500 cursor-pointer">Check our Updates</a>
              </div>
              <div className="my-5">
                <a target="_blank" href="https://app.loopedin.io/realtyview#/roadmap" className="text-blue-400 hover:text-blue-500 cursor-pointer">View our Feature Roadmap</a>
              </div>

              <a target="_blank" href="https://app.loopedin.io/realtyview/kb/">
                <Button className="bg-green-500 w-full hover:bg-green-600" text="Visit the Knowledge Base"></Button>
              </a>

            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 mt-10">
          <div className="bg-white rounded shadow p-10">
            <div className="flex justify-between items-center">
              <div className="text-xl font-semibold text-neutral-800">Offers</div>
              <div className="text-xs font-medium text-neutral-500 cursor-pointer hover:opacity-70" onClick={() => navigate('/app/offers')}>See All</div>
            </div>
            {!offers?.length && <div className="text-center my-10">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-semibold text-neutral-700">No Offers Yet</h3>
              <p className="mt-1 text-sm text-gray-500">You will be notified when an offer is sent to you.</p>
            </div>}
            {offers?.map(x => <div onClick={() => navigate('/app/offers')} className={`hover:bg-blue-200 flex space-x-2 rounded cursor-pointer p-2`}>
              <ImageViewer className="w-16 h-16 object-cover rounded-md shadow" image={x.listing?.photos[0]} small></ImageViewer>
              <div className="my-auto">
                <div className="font-medium text-neutral-600 text-sm">{x.listing?.address}</div>
                <div className={`text-${getColour(x.approvalStatus)}-500 text-sm`}>
                  <span className={`inline-block w-2 h-2 rounded-full mr-2 bg-${getColour(x.approvalStatus)}-500`}></span>
                  <span className="capitalize">{x.approvalStatus ?? 'Pending'}</span>
                </div>
              </div>
            </div>)}
          </div>
          <div className="bg-white rounded shadow p-10">
            <div className="flex justify-between items-center">
              <div className="text-xl font-semibold text-neutral-800">Showings</div>
              <div className="text-xs font-medium text-neutral-500 cursor-pointer hover:opacity-70" onClick={() => navigate('/app/showings')}>See All</div>

            </div>
            {!showings?.length && <div className="text-center mt-10 mb-10">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-semibold text-neutral-700">No Showings Yet</h3>
              <p className="mt-1 text-sm text-gray-500">You will be notified when someone requests a showing.</p>

            </div>}
            {showings?.map(x => <div onClick={() => navigate('/app/showings')} className={`hover:bg-blue-200 flex space-x-2 rounded cursor-pointer p-2`}>
              <ImageViewer className="w-16 h-16 object-cover rounded-md shadow" image={x.listing?.photos[0]} small></ImageViewer>
              <div className="my-auto">
                <div className="font-medium text-neutral-600 text-sm">{x.listing?.address}</div>
                <div className="font-medium text-neutral-600 text-sm">{moment(x.date).format('ddd DD MMM - hA')}</div>
                <div className={`text-${getColourListing(x.status)}-500 text-sm`}>
                  <span className={`inline-block w-2 h-2 rounded-full mr-2 bg-${getColourListing(x.status)}-500`}></span>
                  <span className="capitalize">{x.status ?? 'Pending'}</span>
                </div>
              </div>
            </div>)}
          </div>
        </div>

      </div>
    </div>
  </>
  )
}