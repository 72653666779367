import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useRef, useState } from 'react'
import { api, api_delete, loginCheck } from './api.service'
import { useNavigate, useParams } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import { Popover } from '@headlessui/react'
import ReactImageGallery from 'react-image-gallery'
import { ChevronLeftIcon, ChevronRightIcon, CalendarDaysIcon, ClockIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import FormModal from './forms/form-modal'
import OfferForm from './forms/offer-form'
import ShowingForm from './forms/showing-form'
import { formatPhoneNumber } from './format-service'
import CtaRegister from './cta-register'
import FullScreenModal from './forms/full-screen-modal'

const getAgentName = (agent) => {
  let name = '';
  if (agent.prefix) name += `${agent.prefix} `
  if (agent.firstName) name += `${agent.firstName} `
  if (agent.middleName) name += `${agent.middleName} `
  if (agent.lastName) name += `${agent.lastName} `
  if (agent.suffix) name += `${agent.suffix} `
  return name;
}

export default function Listing({ isPublic }) {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [offerOpen, setOfferOpen] = useState(false)
  const [showingOpen, setShowingOpen] = useState(false)
  const [agent, setAgent] = useState()
  const [showingBooked, setShowingBooked] = useState(false)
  const [contactOpen, setContactOpen] = useState(false);
  const [ctaOpen, setCtaOpen] = useState(false);

  let showingRef = useRef();
  const params = useParams();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/public-listing/${params.id}`).then(x => { setData(x.listing); setAgent(x.agent) }) }, [])
  useEffect(() => { loginCheck(`${process.env.REACT_APP_API_URL}/agent`).then(x => (isPublic && x) ? navigate('/app/listing/' + params.id) : {}) }, [])

  if (!data) return <Loading></Loading>

const formatAddress = () => {
  let address = data.address;
  if (data.unitNumber) address += ", " + data.unitNumber
  if (data.city) address += ", " + data.city
  if (data.state) address += ", " + data.state
  if (data.zip) address += ", " + data.zip
  return address;
}

  return (<div className="px-5">
    {!isPublic && <PageHeader column={true} breadcrumbs={[{ name: formatAddress(), url: null }]} actions={<div className="lg:flex-row lg:space-x-1 lg:space-y-0 flex flex-col space-y-1 "><Button text="Schedule a Showing" onClick={() => { showingRef.scrollIntoView() }}></Button><Button text="Contact Agent" onClick={() => { setContactOpen(true) }}></Button><Button text="Send an Offer" onClick={() => { navigate('/app/make-offer/' + params.id) }}></Button></div>}></PageHeader>}
    {isPublic && <PageHeader column={true} breadcrumbs={[{ name: formatAddress(), url: null }]} actions={<div className="lg:flex-row lg:space-x-1 lg:space-y-0 flex flex-col space-y-1 "><Button text="Schedule a Showing" onClick={() => { setCtaOpen(true) }}></Button><Button text="Contact Agent" onClick={() => { setCtaOpen(true) }}></Button><Button text="Send an Offer" onClick={() => { setCtaOpen(true) }}></Button></div>}></PageHeader>}
    <FormModal open={offerOpen} setOpen={setOfferOpen}><OfferForm id={params.id} close={() => setOfferOpen(false)}></OfferForm></FormModal>
    <FormModal open={!!showingOpen} setOpen={() => setShowingOpen(null)}><ShowingForm id={params.id} date={showingOpen} close={() => { setShowingOpen(false); setShowingBooked(true) }}></ShowingForm></FormModal>
    <FormModal open={contactOpen} setOpen={() => setContactOpen(false)}>

      <div className="flex space-x-4">

        <ImageViewer className="w-16 h-16 rounded my-auto object-cover" image={agent.image} small></ImageViewer>
        <div className="flex-1 my-auto">
          <div className="font-semibold text-neutral-800 truncate">{getAgentName(agent)}</div>
          {agent.mobileNumber && <div className="text-neutral-600 text-sm truncate">Mobile: {formatPhoneNumber(agent.mobileNumber)}</div>}
          {agent.officeNumber && <div className="text-neutral-600 text-sm truncate">Office: {formatPhoneNumber(agent.officeNumber)}</div>}
          <div className="text-neutral-600 text-sm truncate">{agent.contactEmail}</div>
        </div>

      </div>

      {agent.broker?.name && <div className="flex space-x-4 mt-5">

        <ImageViewer className="w-16 h-16 rounded my-auto object-cover" image={agent.broker?.companyLogo} small></ImageViewer>
        <div className="flex-1 my-auto">
          <div className="font-semibold text-neutral-800 truncate">{agent.broker?.name}</div>
          {agent.mobileNumber && <div className="text-neutral-600 text-sm truncate">Address: {agent.broker?.companyAddress}</div>}
          {agent.officeNumber && <div className="text-neutral-600 text-sm truncate">Phone: {formatPhoneNumber(agent.broker?.companyPhone)}</div>}
        </div>

      </div>}

    </FormModal>
    <FullScreenModal open={ctaOpen} setOpen={setCtaOpen}>
      <CtaRegister id={params.id}>

      </CtaRegister>
    </FullScreenModal>
    <div className="flex bg-slate-50">
      <div className="w-full pb-20 max-w-6xl mx-auto my-5">
        <div>
          {data && <div className="">
            <div className="flex flex-col">
              <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
                <div className="col-span-2 bg-white shadow rounded p-5">
                  {data.photos?.length > 0 && <ReactImageGallery renderLeftNav={(onClick, disabled) => (<div className={`pointer-events-none absolute h-full flex z-10 left-5 ${disabled ? 'hidden' : ''}`}><div onClick={onClick} className="pointer-events-auto my-auto bg-neutral-800/50 p-2 rounded hover:bg-neutral-800/70 cursor-pointer"><ChevronLeftIcon className="text-white w-8" /></div></div>)}
                    renderRightNav={(onClick, disabled) => (<div className={`pointer-events-none absolute h-full flex z-10 right-5 ${disabled ? 'hidden' : ''}`}><div onClick={onClick} className="pointer-events-auto my-auto bg-neutral-800/50 p-2 rounded hover:bg-neutral-800/70 cursor-pointer"><ChevronRightIcon className="text-white w-8" /></div></div>)}
                    showPlayButton={false} showFullscreenButton={false} items={data.photos.map(x => { return { original: `https://ik.imagekit.io/verticode/${x}?tr=w-1200,h-800`, thumbnail: `https://ik.imagekit.io/verticode/${x}?tr=w-200,h-130` } })}></ReactImageGallery>}
                  {!data.photos?.length && <div>
                    <ImageViewer className="w-full h-96 rounded my-auto object-cover"></ImageViewer>
                  </div>}
                </div>
                <div className="lg:mt-0 mt-5">
                  <div className="bg-white rounded shadow p-5">
                    <div className="flex space-x-4">

                      <ImageViewer className="w-16 h-16 rounded my-auto object-cover" image={agent.image} small></ImageViewer>
                      <div className="flex-1 my-auto">
                        <div className="font-semibold text-neutral-800">{getAgentName(agent)}</div>
                        {agent.mobileNumber && <div className="text-neutral-600 text-sm">Mobile: {agent.mobileNumber}</div>}
                        {agent.officeNumber && <div className="text-neutral-600 text-sm">Office: {agent.officeNumber}</div>}
                        <div className="text-neutral-600 text-sm">{agent.contactEmail}</div>
                      </div>

                    </div>
                    {agent.broker?.name && <div className="flex space-x-4 mt-5">

                      <ImageViewer className="w-16 h-16 rounded my-auto object-cover" image={agent.broker?.companyLogo} small></ImageViewer>
                      <div className="flex-1 my-auto">
                        <div className="font-semibold text-neutral-800">{agent.broker?.name}</div>
                        {agent.mobileNumber && <div className="text-neutral-600 text-sm">Address: {agent.broker?.companyAddress}</div>}
                        {agent.officeNumber && <div className="text-neutral-600 text-sm">Phone: {agent.broker?.companyPhone}</div>}
                      </div>

                    </div>}
                  </div>
                </div>

              </div>

              <div className="p-5 bg-white rounded shadow my-3">
                <div className="font-bold text-neutral-800 my-5 text-2xl">Address</div>

                <div className="my-5 grid grid-cols-3 gap-2">
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Address</div>
                    <div className="text-neutral-700 font-medium">{data.address}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Address Line 2</div>
                    <div className="text-neutral-700 font-medium">{data.unitNumber}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">City</div>
                    <div className="text-neutral-700 font-medium">{data.city}</div>
                  </div>

                </div>
                <div className="my-5 grid grid-cols-3 gap-2">
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Neighborhood</div>
                    <div className="text-neutral-700 font-medium">{data.area}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">State</div>
                    <div className="text-neutral-700 font-medium">{data.state}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Zip</div>
                    <div className="text-neutral-700 font-medium">{data.zip}</div>
                  </div>
                </div>



              </div>

              <div className="p-5 bg-white rounded shadow my-5">
                <div className="font-bold text-neutral-800 my-3 text-2xl">Overview</div>
                <div className="flex justify-between text-left">
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Property Type</div>
                    <div className="text-neutral-700 font-medium">{data.propertyType}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Bedrooms</div>
                    <div className="text-neutral-700 font-medium">{data.bedrooms}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Bathrooms</div>
                    <div className="text-neutral-700 font-medium">{data.bathrooms}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">SqFt</div>
                    <div className="text-neutral-700 font-medium">{data.sqFt}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500 font-light mb-1 text-sm">Lot Size SqFt</div>
                    <div className="text-neutral-700 font-medium">{data.lotSizeSqFt}</div>
                  </div>
                </div>

              </div>

              <div className="p-5 bg-white rounded shadow my-5">
                <div className="font-bold text-neutral-800 my-3 text-2xl">Description</div>
                <div className="my-5">
                  <div className="text-neutral-700 font-medium">{data.description}</div>
                </div>
              </div>

              <div className="p-5 bg-white rounded shadow my-3" ref={(r) => showingRef = r}>
                <div className="font-bold text-neutral-800 border-b my-5 text-2xl">Showings</div>
                <div className="my-5">
                  <div className="text-neutral-700 font-medium">{data.showingRemarks}</div>
                </div>
                <BookShowing showingBooked={showingBooked} data={data} openForm={(date) => { isPublic ? navigate('/register?r=%2Fapp%2Flisting%2F' + params.id) : setShowingOpen(date) }}></BookShowing>
              </div>

            </div>
          </div>}
        </div>

      </div>
    </div>
  </div>
  )
}

const BookShowing = ({ data, openForm, showingBooked }) => {
  const [offset, setOffset] = useState(0);
  const [dates, setDates] = useState()
  const [slots, setSlots] = useState()
  const [selectedDate, setSelectedDate] = useState(3)
  const [selectedSlot, setSelectedSlot] = useState()
  const slotLength = 30;
  let centerDate = moment().add(offset + 3, 'days').toDate()

  useEffect(() => {
    let dates = [];
    dates.push(moment(centerDate).add(-3, 'days'))
    dates.push(moment(centerDate).add(-2, 'days'))
    dates.push(moment(centerDate).add(-1, 'days'))
    dates.push(moment(centerDate))
    dates.push(moment(centerDate).add(1, 'days'))
    dates.push(moment(centerDate).add(2, 'days'))
    dates.push(moment(centerDate).add(3, 'days'))
    setDates(dates)
  }, [offset])

  useEffect(() => {
    if (dates) {
      let date = dates[selectedDate];
      let slots = [];

      switch (moment(date).format('ddd')) {
        case 'Sun':
          if (!data.sundayAvailable) {
            setSlots([])
          }
          else {
            for (let slot of data.sundayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        case 'Mon':
          if (!data.mondayAvailable) {
            setSlots([])
          }
          else {
            for (let slot of data.mondayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        case 'Tue':
          if (!data.tuesdayAvailable) {
            setSlots([])
          }
          else {

            for (let slot of data.tuesdayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        case 'Wed':
          if (!data.wednesdayAvailable) {
            setSlots([])
          }
          else {
            for (let slot of data.wednesdayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        case 'Thu':
          if (!data.thursdayAvailable) {
            setSlots([])
          }
          else {

            for (let slot of data.thursdayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        case 'Fri':
          if (!data.fridayAvailable) {
            setSlots([])
          }
          else {

            for (let slot of data.fridayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        case 'Sat':
          if (!data.saturdayAvailable) {
            setSlots([])
          }
          else {
            for (let slot of data.saturdayAvailability) {
              let current = new Date(new Date().setHours(slot.start.split(':')[0], slot.start.split(':')[1]));
              while (current < new Date(new Date().setHours(slot.end.split(':')[0], slot.end.split(':')[1]))) {
                slots.push(new Date(current))
                current = moment(current).add(slotLength, 'minutes').toDate();
              }
            }
          }
          break;
        default:
          break;
      }
      setSlots(slots)
    }
  }, [selectedDate, dates])
  if (showingBooked) return <div className="text-2xl font-medium text-center text-neutral-800">Your showing request has been submitted</div>

  return <>

    <div className="uppercase text-neutral-600 font-semibold text-xl mt-10"><CalendarDaysIcon className="inline w-8 text-blue-500"></CalendarDaysIcon> Select a Day</div>
    <div className="flex space-x-2 lg:space-x-4 justify-evenly mt-10">
      {offset > 0 && <div className="select-none cursor-pointer hover:opacity-70 p-1 lg:p-2 rounded bg-slate-50 my-auto" onClick={() => setOffset(offset - 1)}><ChevronLeftIcon className="lg:w-10 w-4"></ChevronLeftIcon></div>}
      {offset == 0 && <div className="opacity-0 pointer-events-none"><ChevronLeftIcon className="lg:w-10 w-4"></ChevronLeftIcon></div>}

      {dates?.map((x, i) => <div onClick={() => setSelectedDate(i)} className={`${i == selectedDate ? 'bg-indigo-500/30 border-indigo-500 border-2 text-indigo-500' : 'bg-neutral-200/20 text-neutral-700 border '} cursor-pointer hover:opacity-80 rounded text-center p-1 lg:p-5`}>
        <div className="font-light text-xs lg:text-sm opacity-80">{x.format('ddd')}</div>
        <div className="font-medium text-sm lg:text-2xl">{x.format('DD')}</div>
        <div className="font-light text-xs lg:text-sm opacity-80">{x.format('MMM')}</div>
      </div>)}
      <div className="select-none cursor-pointer hover:opacity-70 p-1 lg:p-2 rounded bg-slate-50 my-auto" onClick={() => setOffset(offset + 1)}><ChevronRightIcon className="lg:w-10 w-4"></ChevronRightIcon></div>
    </div>
    <div className="uppercase text-neutral-600 font-semibold text-xl mt-10"><ClockIcon className="inline w-8 text-blue-500"></ClockIcon> Select a Time</div>
    <div className="text-neutral-500 font-light mt-3">Selected date: <b>{moment(dates ? dates[selectedDate] : '')?.format('ddd DD MMM')}</b></div>
    <div className="flex flex-col space-y-2 mt-5 h-96 overflow-auto">
      {slots?.map(x => <div onClick={() => setSelectedSlot(x)} className={`${x == selectedSlot ? 'border-indigo-500 border-2 text-indigo-500' : 'border '} hover:opacity-60 cursor-pointer font-medium p-3 bg-neutral-200/20 rounded text-center`}>{moment(x).format('h:mmA')}</div>)}
      {(!slots || slots?.length == 0) && <div className="text-neutral-700 text-center my-5">No Showings Available on {moment(dates ? dates[selectedDate] : '')?.format('ddd DD MMM')} </div>}
    </div>
    <div className="flex justify-center my-5">
      <Button text="Book Showing" disabled={!selectedSlot} onClick={() => openForm(selectedSlot)}></Button>

    </div>

  </>

}