import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import { CheckIcon } from '@heroicons/react/20/solid'

export default function ClientDashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState()

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/client`).then(x => {
      setData(x)

    })
  }, [])

  if (!data) return <Loading></Loading>
  const getColour = (status) => {
    if (status == "Approved") return 'green'
    if (status == "Countered") return 'gray'
    if (status == "Rejected") return 'red'
    return 'yellow'
  }

  return (<>
    <PageHeader headline={"Dashboard"} ></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto">

        <div className="bg-white rounded shadow p-10">
          <div className="text-blue-500 font-semibold mb-5 text-3xl">Welcome to RealtyView!</div>
          <div className="text-neutral-700 text-md">View offers below that need your approval and signature.
          </div>
        </div>

        <div className="bg-white rounded shadow p-10 my-5">
          <div className="text-xl font-medium text-neutral-700 mb-5">Offers</div>


          {data.offers?.map(x => <div onClick={() => navigate('/client/offer/' + x._id)} className={`hover:bg-blue-200 flex space-x-2 rounded cursor-pointer p-4`}>
            <ImageViewer className="w-16 h-16 object-cover rounded-md shadow" image={x.listing?.photos[0]} small></ImageViewer>
            <div className="my-auto">
              <div className="font-medium text-neutral-600 text-sm">{x.listing?.address}</div>
              <div className={`text-${getColour(x.approvalStatus)}-500 text-sm`}>
                <span className={`inline-block w-2 h-2 rounded-full mr-2 bg-${getColour(x.approvalStatus)}-500`}></span>
                <span className="capitalize">{x.approvalStatus ?? 'Pending'}</span>
              </div>
            </div>
            <div className="flex-1"></div>
            {x.signed && <div className="flex space-x-2 text-neutral-600 items-center">
              <CheckIcon className="w-8 text-green-500"></CheckIcon> <div>Document Signed</div>
            </div>}
          </div>)}
        </div>
      </div>
    </div>
  </>
  )
}