import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginCheck } from './api.service';
import logo from './logo.svg'
import loginPhoto from './signin_page.png'

export default function RequestReset() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/request-token`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(x => {
      setSubmitted(true)
    })
  }

  if (submitted) {
    return <>
      <div className="flex min-h-screen flex-col bg-slate-50">
        <div className="grid lg:grid-cols-2 grid-cols-1 flex-1 max-h-screen">
          <div className="flex flex-col justify-center max-h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-64 h-auto mb-10"
                src={logo}
              />
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="bg-white rounded py-8 px-4 shadow sm:px-10">
                <div className="font-medium text-xl text-neutral-800 text-center">Instructions sent</div>
                <div className="font-light text-sm text-neutral-500 text-center my-5">
                  If that email address exists, instructions for resetting your password have been sent to your email.
                </div>
                <div className="font-light text-sm text-neutral-500 text-center my-5">
                  You'll receive this email within 5 minutes. Be sure to check your spam folder, too.
                </div>
                <div className="space-y-6">
                  
                  <div>
                    <button
                      onClick={() => navigate('/login')}
                      type="button"
                      disabled={!values.email || values.email != values.confirmEmail}
                      className="flex rounded  w-full justify-center border border-transparent bg-blue-500 py-2 px-4 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Go back to login
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="lg:flex hidden max-h-screen">
            <img src={loginPhoto} className="object-cover flex-1"></img>
          </div>
        </div>

      </div>
    </>
  }

  return (
    <>
      <div className="flex min-h-screen flex-col bg-slate-50">
        <div className="grid lg:grid-cols-2 grid-cols-1 flex-1 max-h-screen">
          <div className="flex flex-col justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-64 h-auto mb-10"
                src={logo}
              />
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="bg-white rounded py-8 px-4 shadow sm:px-10">
                <div className="font-medium text-xl text-neutral-800 text-center">Forgot password?</div>
                <div className="font-light text-sm text-neutral-500 text-center my-5">Enter the email address associated with your account, and we'll email you a link to reset your password.</div>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-neutral-800 text-center">
                      {error}
                    </label>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-neutral-800">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-neutral-800">
                      Confirm Email address
                    </label>
                    <div className="mt-1">
                      <input value={values.confirmEmail} onChange={(e) => setValues({ ...values, confirmEmail: e.target.value })}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={() => submit()}
                      type="button"
                      disabled={!values.email || values.email != values.confirmEmail}
                      className="flex rounded  w-full justify-center border border-transparent bg-blue-500 py-2 px-4 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Send reset link
                    </button>
                    <div className="my-5 text-center text-neutral-500  text-sm">
                      Go back to <a className="text-blue-400 hover:text-blue-500" href="/login">Sign In</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="lg:flex hidden max-h-screen">
            <img src={loginPhoto} className="object-cover flex-1"></img>
          </div>
        </div>

      </div>
    </>
  )
}
