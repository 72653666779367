import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";
import ImageUpload from "./fields/image-upload";
import CurrencyField from "./fields/currency-field";
import DateField from "./fields/date-field";
import CheckboxField from "./fields/checkbox-field";
import TimeField from "./fields/time-field";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import Button from "../components/button";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";

export default function ListingForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader breadcrumbs={[{ name: 'Listings', url: 'listings' }, { name: 'Manage Listing', url: null }]}></PageHeader>
        <FormWrapper url="agent/listing" id={id} {...props} callback={() => navigate(-1)} defaultValue={
            {
                sundayAvailable: true, sundayAvailability: [{ start: '09:00', end: '17:00' }],
                mondayAvailable: true, mondayAvailability: [{ start: '09:00', end: '17:00' }],
                tuesdayAvailable: true, tuesdayAvailability: [{ start: '09:00', end: '17:00' }],
                wednesdayAvailable: true, wednesdayAvailability: [{ start: '09:00', end: '17:00' }],
                thursdayAvailable: true, thursdayAvailability: [{ start: '09:00', end: '17:00' }],
                fridayAvailable: true, fridayAvailability: [{ start: '09:00', end: '17:00' }],
                saturdayAvailable: true, saturdayAvailability: [{ start: '09:00', end: '17:00' }],
                status: 'Active',
                saleType: 'Standard',
                listingType: 'Exclusive Agency',
                propertyType: 'Single Family Residence',
                privacy: 'Public'
            }}>
            {(values, setValues) =>
                <>
                    <div className="mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Listing Details</div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <SelectField required optionListName={'sellers'} label="Seller" value={values.client} onChange={(val) => setValues({ ...values, client: val })} />
                        <TextField label="MLS Number" value={values.mlsNumber} onChange={(val) => setValues({ ...values, mlsNumber: val })} />

                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                        <TextField required label="Address" value={values.address} onChange={(val) => setValues({ ...values, address: val })} />
                        <TextField label="Address 2 or Unit Number" value={values.unitNumber} onChange={(val) => setValues({ ...values, unitNumber: val })} />
                        <TextField required label="City" value={values.city} onChange={(val) => setValues({ ...values, city: val })} />

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                        <TextField label="Neighborhood" value={values.area} onChange={(val) => setValues({ ...values, area: val })} />
                        <SelectField optionListName={'states'} required label="State" value={values.state} onChange={(val) => setValues({ ...values, state: val })} />
                        <TextField required label="Zip Code" value={values.zip} onChange={(val) => setValues({ ...values, zip: val })} />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
                        <DateField label="Listing Date" value={values.date} onChange={(val) => setValues({ ...values, date: val })} />
                        <DateField label="Expiration Date" value={values.expiration} onChange={(val) => setValues({ ...values, expiration: val })} />
                        <CurrencyField label="Listing Price" value={values.listingPrice} onChange={(val) => setValues({ ...values, listingPrice: val })} />
                        <CurrencyField label="Annual Taxes" value={values.annualTaxes} onChange={(val) => setValues({ ...values, annualTaxes: val })} />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                        <SelectField optionListName={'statuses'} label="Listing Status" value={values.status} onChange={(val) => setValues({ ...values, status: val })} />
                        <SelectField optionListName={'saleTypes'} label="Sale Type" value={values.saleType} onChange={(val) => setValues({ ...values, saleType: val })} />
                        <SelectField optionListName={'listingTypes'} label="Listing Type" value={values.listingType} onChange={(val) => setValues({ ...values, listingType: val })} />
                    </div>
                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Property Features (Optional but recommended)</div>

                    <SelectField optionListName={'propertyTypes'} label="Property Type" value={values.propertyType} onChange={(val) => setValues({ ...values, propertyType: val })} />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <TextField label="Bedrooms" value={values.bedrooms} onChange={(val) => setValues({ ...values, bedrooms: val })} />
                        <TextField label="Bathrooms" value={values.bathrooms} onChange={(val) => setValues({ ...values, bathrooms: val })} />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <TextField label="SqFt" value={values.sqFt} onChange={(val) => setValues({ ...values, sqFt: val })} />
                        <TextField label="Lot Size SqFt" value={values.lotSizeSqFt} onChange={(val) => setValues({ ...values, lotSizeSqFt: val })} />
                    </div>
                    <TextAreaField label="Property Description" value={values.description} onChange={(val) => setValues({ ...values, description: val })} />


                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Showing Instructions</div>

                    <TextAreaField label="Showing Instructions" value={values.showingInstructions} onChange={(val) => setValues({ ...values, showingInstructions: val })} />
                    <TextAreaField label="Showing Remarks" value={values.showingRemarks} onChange={(val) => setValues({ ...values, showingRemarks: val })} />


                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                        {/* <SelectField optionListName={'privacy'} label="Privacy" value={values.privacy} onChange={(val) => setValues({ ...values, privacy: val })} /> */}
                        <SelectField options={[{ text: "Yes", value: true }, { text: "No", value: false }]} label="Lockbox/Digital Keypad" value={values.hasLockBox} onChange={(val) => setValues({ ...values, hasLockBox: val })} />
                        <TextField label="Access Code" value={values.accessCode} onChange={(val) => setValues({ ...values, accessCode: val })} />

                        <SelectField optionListName={'occupancyTypes'} label="Occupancy" value={values.occupancy} onChange={(val) => setValues({ ...values, occupancy: val })} />
                    </div>

                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Showing Availability</div>
                    <div className="flex justify-center items-center flex-col">
                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Sunday" value={values.sundayAvailable} onChange={(val) => setValues({ ...values, sundayAvailable: val })} />
                            <div>
                                {values?.sundayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, sundayAvailability: values.sundayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, sundayAvailability: values.sundayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, sundayAvailability: values.sundayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-center mt-auto mb-3" onClick={() => setValues({ ...values, sundayAvailability: values.sundayAvailability ? values.sundayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>
                        </div>

                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Monday" value={values.mondayAvailable} onChange={(val) => setValues({ ...values, mondayAvailable: val })} />
                            <div>
                                {values?.mondayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, mondayAvailability: values.mondayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, mondayAvailability: values.mondayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, mondayAvailability: values.mondayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-right mt-auto mb-3" onClick={() => setValues({ ...values, mondayAvailability: values.mondayAvailability ? values.mondayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>
                        </div>

                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Tuesday" value={values.tuesdayAvailable} onChange={(val) => setValues({ ...values, tuesdayAvailable: val })} />
                            <div>
                                {values?.tuesdayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, tuesdayAvailability: values.tuesdayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, tuesdayAvailability: values.tuesdayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, tuesdayAvailability: values.tuesdayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-right mt-auto mb-3" onClick={() => setValues({ ...values, tuesdayAvailability: values.tuesdayAvailability ? values.tuesdayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>
                        </div>

                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Wednesday" value={values.wednesdayAvailable} onChange={(val) => setValues({ ...values, wednesdayAvailable: val })} />
                            <div>
                                {values?.wednesdayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, wednesdayAvailability: values.wednesdayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, wednesdayAvailability: values.wednesdayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, wednesdayAvailability: values.wednesdayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-right mt-auto mb-3" onClick={() => setValues({ ...values, wednesdayAvailability: values.wednesdayAvailability ? values.wednesdayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>
                        </div>

                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Thursday" value={values.thursdayAvailable} onChange={(val) => setValues({ ...values, thursdayAvailable: val })} />
                            <div>
                                {values?.thursdayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, thursdayAvailability: values.thursdayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, thursdayAvailability: values.thursdayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, thursdayAvailability: values.thursdayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-right mt-auto mb-3" onClick={() => setValues({ ...values, thursdayAvailability: values.thursdayAvailability ? values.thursdayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>
                        </div>

                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Friday" value={values.fridayAvailable} onChange={(val) => setValues({ ...values, fridayAvailable: val })} />
                            <div>
                                {values?.fridayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, fridayAvailability: values.fridayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, fridayAvailability: values.fridayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, fridayAvailability: values.fridayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-right mt-auto mb-3" onClick={() => setValues({ ...values, fridayAvailability: values.fridayAvailability ? values.fridayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>
                        </div>

                        <div className="flex border-b space-x-8 min-h-10 py-1">
                            <CheckboxField className="w-36" inline label="Saturday" value={values.saturdayAvailable} onChange={(val) => setValues({ ...values, saturdayAvailable: val })} />
                            <div>
                                {values?.saturdayAvailability?.map((x, i) => <div className="flex items-center space-x-8">
                                    <TimeField className="w-36" value={x.start} onChange={(val) => setValues({ ...values, saturdayAvailability: values.saturdayAvailability.map((y, j) => j == i ? { ...y, start: val } : y) })} />
                                    <div>-</div>
                                    <TimeField className="w-36" value={x.end} onChange={(val) => setValues({ ...values, saturdayAvailability: values.saturdayAvailability.map((y, j) => j == i ? { ...y, end: val } : y) })} />
                                    <TrashIcon className="w-6 text-neutral-600" onClick={() => setValues({ ...values, saturdayAvailability: values.saturdayAvailability.filter((y, j) => j != i) })}></TrashIcon>
                                </div>)}
                            </div>
                            <div className="text-neutral-600 text-sm underline cursor-pointer text-right mt-auto mb-3" onClick={() => setValues({ ...values, saturdayAvailability: values.saturdayAvailability ? values.saturdayAvailability.concat([{}]) : [{}] })}><PlusIcon className="ml-auto w-6"></PlusIcon></div>

                        </div>
                    </div>


                    <div className="mt-10 mb-5 text-slate-600 font-medium text-xl uppercase leading-0">Photos</div>


                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2">
                        {values.photos?.map((x, i) => <div>
                            <ImageUpload label={'Photo ' + (i + 1)} value={x} onChange={(val) => setValues({ ...values, photos: values.photos.map((y, j) => j === i ? val : y) })}></ImageUpload>
                        </div>)}
                        <div className="flex">
                            {values.photos?.length > 0 && <Button className="m-auto" text="Add Photo" onClick={() => setValues({ ...values, photos: values.photos ? values.photos.concat([""]) : [""] })}></Button>}
                        </div>
                    </div>

                    {!values.photos?.length && <div className="text-center mt-10">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                vectorEffect="non-scaling-stroke"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            />
                        </svg>
                        <h3 className="mt-2 text-sm font-semibold text-neutral-700">Add Listing Photos</h3>
                        <p className="mt-1 text-sm text-gray-500">This is optional but highly recommended.</p>
                        <div className="mt-6">
                            <button onClick={() => setValues({ ...values, photos: values.photos ? values.photos.concat([""]) : [""] })}
                                type="button"
                                className="inline-flex items-center rounded bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                Add Listings Photos
                            </button>
                        </div>
                    </div>}


                    <div></div>

                </>
            }
        </FormWrapper>
    </div>
}