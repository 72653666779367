import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Popover } from '@headlessui/react'
import ReactImageGallery from 'react-image-gallery'
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export default function Listings() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [deleteId, setDeleteId] = useState();
  const [filters, setFilters] = useState({})

  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/agent/listing/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/agent/listing`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/listing`).then(x => { setData(x); if (width > 1024) setSelected(x[0]) }) }, [])

  const [selected, setSelected] = useState()

  if (!data) return <Loading></Loading>
  return (<>
    <ToastContainer />
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
    <PageHeader breadcrumbs={[{ name: 'Listings', url: 'listing' }]} actions={<Button text="Add Listing" onClick={() => navigate('/app/manage-listing/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto my-5">
        {!data.length && <div className="text-center mt-10">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-neutral-700">No Listings Yet</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by creating a new listing.</p>
          <div className="mt-6">
            <button onClick={() => navigate('/app/manage-listing/new')}
              type="button"
              className="inline-flex items-center rounded bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              New Listing
            </button>
          </div>
        </div>}
        {data.length > 0 && <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
          <div className={`${selected ? 'hidden lg:block' : ''}`}>
            <div className="bg-white rounded shadow p-2">
              <div className="mb-5">
                <input value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="outline-none rounded border p-1 px-2 w-full placeholder:text-neutral-300" placeholder="Search Client or Listing Address..."></input>
              </div>
              {data.filter(x => (!filters.search || Object.values(flattenObject({ ...x })).findIndex(y => ('' + y).toLowerCase().includes(filters.search.toLowerCase())) != -1)).map(x => <div onClick={() => setSelected(x)} className={`${selected?._id == x._id ? 'bg-blue-300' : 'hover:bg-blue-200'} flex space-x-2 rounded cursor-pointer p-2`}>
                <ImageViewer className="w-16 h-16 object-cover rounded-md shadow" image={x.photos[0]} small></ImageViewer>
                <div className="my-auto">
                  <div className="font-medium text-neutral-600 text-sm">{x.address}</div>
                  <div className="font-light text-neutral-500 text-xs">{x.city}, {x.state} {x.zip}</div>
                </div>
              </div>)}
            </div>
          </div>
          <div className="col-span-2">
            {selected && <div onClick={() => setSelected(null)} className="block lg:hidden  mb-2 text-neutral-500 font-medium hover:text-neutral-600 text-sm"><ChevronLeftIcon className="w-6 inline" /> Listings List</div>}

            {selected && <div className="bg-white rounded shadow overflow-hidden">
              <div className="flex">

                <div className="flex-1"></div>

                <div className="relative">
                  <CopyToClipboard text={`https://realtyview.verticode.co.uk/listing/${selected._id}`}
                    onCopy={() => toast.info("Copied To Clipboard!")}>
                    <div className=" w-32 text-center p-2 rounded absolute top-5 right-14 z-40 text-medium cursor-pointer bg-neutral-800/50 text-white hover:bg-neutral-800/70 text-xs">Share Listing Link</div>
                  </CopyToClipboard>
                  <Popover className="absolute top-5 right-5 z-40">
                    <Popover.Button><EllipsisHorizontalIcon className="p-2 rounded bg-neutral-800/50 w-8 text-white hover:bg-neutral-800/70 cursor-pointer"></EllipsisHorizontalIcon></Popover.Button>
                    <Popover.Panel className="absolute right-0 z-10 bg-white w-28 shadow rounded">
                      <div className="flex flex-col">
                        <div className="hover:bg-neutral-100 py-2 px-4 text-medium cursor-pointer text-neutral-700 text-sm" onClick={() => navigate(`/app/listing/${selected._id}`)}>View</div>
                        <CopyToClipboard text={`https://realtyview.verticode.co.uk/listing/${selected._id}`}
                          onCopy={() => toast.info("Copied To Clipboard!")}>
                          <div className="hover:bg-neutral-100 py-2 px-4 text-medium cursor-pointer text-neutral-700 text-sm">Copy URL</div>
                        </CopyToClipboard>
                        <div className="hover:bg-neutral-100 py-2 px-4 text-medium cursor-pointer text-neutral-700 text-sm" onClick={() => navigate(`/app/manage-listing/${selected._id}`)}>Edit</div>
                        <div className="hover:bg-neutral-100 py-2 px-4 text-medium cursor-pointer text-neutral-700 text-sm" onClick={() => setDeleteId(selected._id)}>Delete</div>
                      </div>
                    </Popover.Panel>
                  </Popover>
                </div>
              </div>
              <div className="flex flex-col">
                <div>
                  <ReactImageGallery renderLeftNav={(onClick, disabled) => (<div className={`pointer-events-none absolute h-full flex z-10 left-5 ${disabled ? 'hidden' : ''}`}><div onClick={onClick} className="pointer-events-auto my-auto bg-neutral-800/50 p-2 rounded hover:bg-neutral-800/70 cursor-pointer"><ChevronLeftIcon className="text-white w-8" /></div></div>)}
                    renderRightNav={(onClick, disabled) => (<div className={`pointer-events-none absolute h-full flex z-10 right-5 ${disabled ? 'hidden' : ''}`}><div onClick={onClick} className="pointer-events-auto my-auto bg-neutral-800/50 p-2 rounded hover:bg-neutral-800/70 cursor-pointer"><ChevronRightIcon className="text-white w-8" /></div></div>)}
                    showPlayButton={false} showFullscreenButton={false} items={selected.photos?.map(x => { return { original: `https://ik.imagekit.io/verticode/${x}?tr=w-1200,h-800`, thumbnail: `https://ik.imagekit.io/verticode/${x}?tr=w-200,h-130` } })}></ReactImageGallery>
                </div>

                <div className="p-5">
                  <div className="font-bold text-neutral-800 border-b my-5 text-2xl">Address</div>

                  <div className="my-5 grid grid-cols-1 lg:grid-cols-3 gap-2">
                    <div >
                      <div className="text-neutral-500 font-light mb-1 text-sm">Address</div>
                      <div className="text-neutral-700 font-medium">{selected.address}</div>
                    </div>
                    <div >
                      <div className="text-neutral-500 font-light mb-1 text-sm">Address Line 2</div>
                      <div className="text-neutral-700 font-medium">{selected.unitNumber}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">City</div>
                      <div className="text-neutral-700 font-medium">{selected.city}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Neighborhood</div>
                      <div className="text-neutral-700 font-medium">{selected.area}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">State</div>
                      <div className="text-neutral-700 font-medium">{selected.state}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Zip</div>
                      <div className="text-neutral-700 font-medium">{selected.zip}</div>
                    </div>
                  </div>


                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Property Description</div>
                    <div className="text-neutral-700 font-medium">{selected.description}</div>
                  </div>
                  <div className="grid grid-cols-2 gap-2 my-5">

                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">MLS Number</div>
                      <div className="text-neutral-700 font-medium">{selected.mlsNumber}</div>
                    </div>
                    <div >
                      <div className="text-neutral-500 font-light mb-1 text-sm">Listing Status</div>
                      <div className="text-neutral-700 font-medium">{selected.status}</div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2 my-5">

                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Listing Price</div>
                      <div className="text-neutral-700 font-medium">${selected.listingPrice}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Annual Taxes</div>
                      <div className="text-neutral-700 font-medium">${selected.annualTaxes}</div>
                    </div>
                  </div>



                  <div className="grid grid-cols-2 gap-2 my-5">
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Listing Date</div>
                      <div className="text-neutral-700 font-medium">{moment(selected.date).format('MM/DD/YYYY')}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Expiration Date</div>
                      <div className="text-neutral-700 font-medium">{moment(selected.expiration).format('MM/DD/YYYY')}</div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2 my-5">
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Sale Type</div>
                      <div className="text-neutral-700 font-medium">{selected.saleType}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Listing Type</div>
                      <div className="text-neutral-700 font-medium">{selected.listingType}</div>
                    </div>
                  </div>

                </div>

                <div className="p-5">
                  <div className="font-bold text-neutral-800 border-b my-5 text-2xl">Property Details</div>

                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Property Type</div>
                    <div className="text-neutral-700 font-medium">{selected.propertyType}</div>
                  </div>
                  <div className="grid grid-cols-2 gap-2 my-5">
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Bedrooms</div>
                      <div className="text-neutral-700 font-medium">{selected.bedrooms}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Bathrooms</div>
                      <div className="text-neutral-700 font-medium">{selected.bathrooms}</div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2 my-5">
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">SqFt</div>
                      <div className="text-neutral-700 font-medium">{selected.sqFt}</div>
                    </div>
                    <div>
                      <div className="text-neutral-500 font-light mb-1 text-sm">Lot Size SqFt</div>
                      <div className="text-neutral-700 font-medium">{selected.lotSizeSqFt}</div>
                    </div>
                  </div>


                </div>

                <div className="p-5">
                  <div className="font-bold text-neutral-800 border-b my-5 text-2xl">Showing Details</div>

                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Showing Instructions</div>
                    <div className="text-neutral-700 font-medium">{selected.showingInstructions}</div>
                  </div>

                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Showing Remarks</div>
                    <div className="text-neutral-700 font-medium">{selected.showingRemarks}</div>
                  </div>
                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Lockbox/Digital Keypad</div>
                    <div className="text-neutral-700 font-medium">{selected.hasLockbox ? 'Yes' : 'No'}</div>
                  </div>
                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Sunday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.sundayAvailable ? selected.sundayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Monday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.mondayAvailable ? selected.mondayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Tuesday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.tuesdayAvailable ? selected.tuesdayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Wednesday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.wednesdayAvailable ? selected.wednesdayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Thursday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.thursdayAvailable ? selected.thursdayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Friday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.fridayAvailable ? selected.fridayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                    <div className="text-neutral-500 font-light mb-1 mt-2 text-sm">Saturday Showing Availability</div>
                    <div className="text-neutral-700 font-medium">{selected.saturdayAvailable ? selected.saturdayAvailability.map(x => <div>{`${x.start} - ${x.end}`}</div>) : 'None'}</div>
                  </div>

                </div>

                <div className="p-5">
                  <div className="font-bold text-neutral-800 border-b my-5 text-2xl">Other Details</div>

                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Privacy</div>
                    <div className="text-neutral-700 font-medium">{selected.privacy}</div>
                  </div>

                  <div className="my-5">
                    <div className="text-neutral-500 font-light mb-1 text-sm">Occupancy</div>
                    <div className="text-neutral-700 font-medium">{selected.occupancy}</div>
                  </div>


                </div>

              </div>
            </div>}
          </div>
        </div>}

      </div>
    </div>
  </>
  )
}