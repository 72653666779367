import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react'
import { api } from '../../api.service';
import Loading from '../../components/loading';

export default function SelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, placeholder, required }) {

    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
        if (optionListName === "licenseTypes") {
            setSelectOptions([{ text: 'Salesperson', value: 'Salesperson' }, { text: 'Associate Broker', value: 'Associate Broker' }, { text: 'Corporate Broker or Individual Broker', value: 'Corporate Broker or Individual Broker' },])
        }
        if (optionListName === "licenseStates") {
            setSelectOptions([{ text: 'New York', value: 'New York' }])
        }
        if (optionListName === "states") {
            setSelectOptions([{ value: 'Alabama', text: 'Alabama' },
            { value: 'Alaska', text: 'Alaska' },
            { value: 'Arizona', text: 'Arizona' },
            { value: 'Arkansas', text: 'Arkansas' },
            { value: 'California', text: 'California' },
            { value: 'Colorado', text: 'Colorado' },
            { value: 'Connecticut', text: 'Connecticut' },
            { value: 'Delaware', text: 'Delaware' },
            { value: 'District of Columbia', text: 'District of Columbia' },
            { value: 'Florida', text: 'Florida' },
            { value: 'Georgia', text: 'Georgia' },
            { value: 'Hawaii', text: 'Hawaii' },
            { value: 'Idaho', text: 'Idaho' },
            { value: 'Illinois', text: 'Illinois' },
            { value: 'Indiana', text: 'Indiana' },
            { value: 'Iowa', text: 'Iowa' },
            { value: 'Kansas', text: 'Kansas' },
            { value: 'Kentucky', text: 'Kentucky' },
            { value: 'Louisiana', text: 'Louisiana' },
            { value: 'Maine', text: 'Maine' },
            { value: 'Maryland', text: 'Maryland' },
            { value: 'Massachusetts', text: 'Massachusetts' },
            { value: 'Michigan', text: 'Michigan' },
            { value: 'Minnesota', text: 'Minnesota' },
            { value: 'Mississippi', text: 'Mississippi' },
            { value: 'Missouri', text: 'Missouri' },
            { value: 'Montana', text: 'Montana' },
            { value: 'Nebraska', text: 'Nebraska' },
            { value: 'Nevada', text: 'Nevada' },
            { value: 'New Hampshire', text: 'New Hampshire' },
            { value: 'New Jersey', text: 'New Jersey' },
            { value: 'New Mexico', text: 'New Mexico' },
            { value: 'New York', text: 'New York' },
            { value: 'North Carolina', text: 'North Carolina' },
            { value: 'North Dakota', text: 'North Dakota' },
            { value: 'Ohio', text: 'Ohio' },
            { value: 'Oklahoma', text: 'Oklahoma' },
            { value: 'Oregon', text: 'Oregon' },
            { value: 'Pennsylvania', text: 'Pennsylvania' },
            { value: 'Rhode Island', text: 'Rhode Island' },
            { value: 'South Carolina', text: 'South Carolina' },
            { value: 'South Dakota', text: 'South Dakota' },
            { value: 'Tennessee', text: 'Tennessee' },
            { value: 'Texas', text: 'Texas' },
            { value: 'Utah', text: 'Utah' },
            { value: 'Vermont', text: 'Vermont' },
            { value: 'Virginia', text: 'Virginia' },
            { value: 'Washington', text: 'Washington' },
            { value: 'West Virginia', text: 'West Virginia' },
            { value: 'Wisconsin', text: 'Wisconsin' },
            { value: 'Wyoming', text: 'Wyoming' }])
        }
        if (optionListName === "statuses") {
            setSelectOptions([{ value: 'Active', text: 'Active' },
            { value: 'Coming Soon', text: 'Coming Soon' },
            { value: 'Pending', text: 'Pending' },
            { value: 'Incomplete', text: 'Incomplete' },
            { value: 'Canceled', text: 'Withdrawn' },
            { value: 'Expired', text: 'Expired' },
            { value: 'Closed', text: 'Closed' },])
        }
        if (optionListName === "saleTypes") {
            setSelectOptions([{ value: 'Auction', text: 'Auction' },
            { value: 'Bankruptcy Property', text: 'Bankruptcy Property' },
            { value: 'HUD Owned', text: 'HUD Owned' },
            { value: 'In Foreclosure', text: 'In Foreclosure' },
            { value: 'Notice Of Default', text: 'Notice Of Default' },
            { value: 'Probate Listing', text: 'Probate Listing' },
            { value: 'Real Estate Owned', text: 'Real Estate Owned' },
            { value: 'Short Sale', text: 'Short Sale' },
            { value: 'Standard', text: 'Standard' },])
        }
        if (optionListName === "listingTypes") {
            setSelectOptions([{ value: 'Exclusive Agency', text: 'Exclusive Agency' },
            { value: 'Exclusive Right To Lease', text: 'Exclusive Right To Lease' },
            { value: 'Exclusive Right To Sell', text: 'Exclusive Right To Sell' },
            { value: 'Exclusive Right With Exception', text: 'Exclusive Right With Exception' },
            { value: 'Net (ListingAgreement)', text: 'Net (ListingAgreement)' },
            { value: 'Open', text: 'Open' },
            { value: 'Probate', text: 'Probate' },])
        }
        if (optionListName === "propertyTypes") {
            setSelectOptions([{ value: 'Apartment', text: 'Apartment' },
            { value: 'Boat Slip', text: 'Boat Slip' },
            { value: 'Cabin', text: 'Cabin' },
            { value: 'Condominium', text: 'Condominium' },
            { value: 'Deeded Parking', text: 'Deeded Parking' },
            { value: 'Duplex', text: 'Duplex' },
            { value: 'Farm', text: 'Farm' },
            { value: 'Manufactured Home', text: 'Manufactured Home' },
            { value: 'Manufactured On Land', text: 'Manufactured On Land' },
            { value: 'Mobile Home', text: 'Mobile Home' },
            { value: 'Own Your Own', text: 'Own Your Own' },
            { value: 'Quadruplex', text: 'Quadruplex' },
            { value: 'Ranch', text: 'Ranch' },
            { value: 'Single Family Residence', text: 'Single Family Residence' },
            { value: 'Stock Cooperative', text: 'Stock Cooperative' },
            { value: 'Timeshare', text: 'Timeshare' },
            { value: 'Townhouse', text: 'Townhouse' },
            { value: 'Triplex', text: 'Triplex' },
            { value: 'Agriculture', text: 'Agriculture' },
            { value: 'Business', text: 'Business' },
            { value: 'Hotel-Motel', text: 'Hotel-Motel' },
            { value: 'Industrial', text: 'Industrial' },
            { value: 'Mixed Use', text: 'Mixed Use' },
            { value: 'Multi Family', text: 'Multi Family' },
            { value: 'Office', text: 'Office' },
            { value: 'Retail', text: 'Retail' },
            { value: 'Unimproved Land', text: 'Unimproved Land' },
            { value: 'Warehouse', text: 'Warehouse' },])
        }
        if (optionListName === "privacy") {
            setSelectOptions([{ value: 'Public', text: 'Public' },
            { value: 'Private', text: 'Private' }])
        }
        if (optionListName === "occupancyTypes") {
            setSelectOptions([{ value: 'Owner Occupied', text: 'Owner Occupied' },
            { value: 'Tenant Occupied', text: 'Tenant Occupied' },
            { value: 'Vacant', text: 'Vacant' }])
        }
        if (optionListName === "stateAssociations") {
            setSelectOptions([{ text: 'Alabama Association of Realtors®', value: 'Alabama Association of Realtors®' },
            { text: 'Alaska Realtors®', value: 'Alaska Realtors®' },
            { text: 'Arizona Association of Realtors®', value: 'Arizona Association of Realtors®' },
            { text: 'Arkansas Realtors® Association', value: 'Arkansas Realtors® Association' },
            { text: 'California Association of Realtors® Inc', value: 'California Association of Realtors® Inc' },
            { text: 'Colorado Association of Realtors® Inc', value: 'Colorado Association of Realtors® Inc' },
            { text: 'Connecticut Association of Realtors® Inc', value: 'Connecticut Association of Realtors® Inc' },
            { text: 'Delaware Association of Realtors®', value: 'Delaware Association of Realtors®' },
            { text: 'District Of Columbia Association of Realtors®', value: 'District Of Columbia Association of Realtors®' },
            { text: 'Florida Association of Realtors®', value: 'Florida Association of Realtors®' },
            { text: 'Georgia Association of Realtors®', value: 'Georgia Association of Realtors®' },
            { text: 'Guam Association of Realtors®', value: 'Guam Association of Realtors®' },
            { text: 'Hawaii Association of Realtors®', value: 'Hawaii Association of Realtors®' },
            { text: 'Idaho Association of Realtors® Inc', value: 'Idaho Association of Realtors® Inc' },
            { text: 'Illinois Association of Realtors®', value: 'Illinois Association of Realtors®' },
            { text: 'Indiana Association of Realtors® Inc', value: 'Indiana Association of Realtors® Inc' },
            { text: 'Iowa Association of Realtors®', value: 'Iowa Association of Realtors®' },
            { text: 'Kansas Association of Realtors® Inc', value: 'Kansas Association of Realtors® Inc' },
            { text: 'Kentucky Realtors®', value: 'Kentucky Realtors®' },
            { text: 'Louisiana Realtors®', value: 'Louisiana Realtors®' },
            { text: 'Maine Association of Realtors®', value: 'Maine Association of Realtors®' },
            { text: 'Maryland Realtors®', value: 'Maryland Realtors®' },
            { text: 'Massachusetts Association of Realtors®', value: 'Massachusetts Association of Realtors®' },
            { text: 'Michigan Association of Realtors®', value: 'Michigan Association of Realtors®' },
            { text: 'Minnesota Association of Realtors®', value: 'Minnesota Association of Realtors®' },
            { text: 'Mississippi Association of Realtors® Inc', value: 'Mississippi Association of Realtors® Inc' },
            { text: 'Missouri Association of Realtors®', value: 'Missouri Association of Realtors®' },
            { text: 'Montana Association of Realtors®', value: 'Montana Association of Realtors®' },
            { text: 'Nebraska Realtors® Association', value: 'Nebraska Realtors® Association' },
            { text: 'Nevada Realtors®', value: 'Nevada Realtors®' },
            { text: 'New Hampshire Association of Realtors® Inc', value: 'New Hampshire Association of Realtors® Inc' },
            { text: 'New Jersey Association of Realtors®', value: 'New Jersey Association of Realtors®' },
            { text: 'New Mexico Association of Realtors®', value: 'New Mexico Association of Realtors®' },
            { text: 'New York State Association of Realtors® Inc', value: 'New York State Association of Realtors® Inc' },
            { text: 'North Carolina Association of Realtors® Inc', value: 'North Carolina Association of Realtors® Inc' },
            { text: 'North Dakota Association of Realtors®', value: 'North Dakota Association of Realtors®' },
            { text: 'Ohio Association of Realtors®', value: 'Ohio Association of Realtors®' },
            { text: 'Oklahoma Association of Realtors®', value: 'Oklahoma Association of Realtors®' },
            { text: 'Oregon Realtors®', value: 'Oregon Realtors®' },
            { text: 'Pennsylvania Association of Realtors®', value: 'Pennsylvania Association of Realtors®' },
            { text: 'Puerto Rico Association of Realtors®', value: 'Puerto Rico Association of Realtors®' },
            { text: 'Rhode Island Association of Realtors® Inc', value: 'Rhode Island Association of Realtors® Inc' },
            { text: 'South Carolina Association of Realtors®', value: 'South Carolina Association of Realtors®' },
            { text: 'South Dakota Association of Realtors®', value: 'South Dakota Association of Realtors®' },
            { text: 'Tennessee Association of Realtors®', value: 'Tennessee Association of Realtors®' },
            { text: 'Texas Association of Realtors®', value: 'Texas Association of Realtors®' },
            { text: 'Utah Association of Realtors®', value: 'Utah Association of Realtors®' },
            { text: 'Vermont Association of Realtors® Inc', value: 'Vermont Association of Realtors® Inc' },
            { text: 'Virgin Islands Territorial Association of Realtors® Inc', value: 'Virgin Islands Territorial Association of Realtors® Inc' },
            { text: 'Virginia Association of Realtors®', value: 'Virginia Association of Realtors®' },
            { text: 'Washington Association of Realtors®', value: 'Washington Association of Realtors®' },
            { text: 'West Virginia Association of Realtors®', value: 'West Virginia Association of Realtors®' },
            { text: 'Wisconsin Realtors® Association', value: 'Wisconsin Realtors® Association' },
            { text: 'Wyoming Realtors®', value: 'Wyoming Realtors®' },])
        }
        if (optionListName === "localAssociations") {
            setSelectOptions([{ text: '400 North Association Of Realtors®', value: '400 North Association Of Realtors®' },
            { text: 'Aberdeen Area Association Of Realtors®', value: 'Aberdeen Area Association Of Realtors®' },
            { text: 'Abilene Association Of Realtors® Inc', value: 'Abilene Association Of Realtors® Inc' },
            { text: 'Ada Board Of Realtors®', value: 'Ada Board Of Realtors®' },
            { text: 'Addison County Board Of Realtors®', value: 'Addison County Board Of Realtors®' },
            { text: 'Aiken Association Of Realtors®', value: 'Aiken Association Of Realtors®' },
            { text: 'Akron Cleveland Association Of Realtors®', value: 'Akron Cleveland Association Of Realtors®' },
            { text: 'Albany Board Of Realtors®', value: 'Albany Board Of Realtors®' },
            { text: 'Albemarle Area Association Of Realtors®', value: 'Albemarle Area Association Of Realtors®' },
            { text: 'Allegheny Highland Association Of Realtors® Inc', value: 'Allegheny Highland Association Of Realtors® Inc' },
            { text: 'Allegheny Valley Board Of Realtors®', value: 'Allegheny Valley Board Of Realtors®' },
            { text: 'Altamaha Basin Board Of Realtors® Inc', value: 'Altamaha Basin Board Of Realtors® Inc' },
            { text: 'Altus Association Of Realtors® Inc', value: 'Altus Association Of Realtors® Inc' },
            { text: 'Amador County Association Of Realtors®', value: 'Amador County Association Of Realtors®' },
            { text: 'Amarillo Association Of Realtors® Inc', value: 'Amarillo Association Of Realtors® Inc' },
            { text: 'Amelia Island-nassau County Assoc Of Realtors® Inc', value: 'Amelia Island-nassau County Assoc Of Realtors® Inc' },
            { text: 'Americus Board Of Realtors®', value: 'Americus Board Of Realtors®' },
            { text: 'Anchorage Board Of Realtors® Inc', value: 'Anchorage Board Of Realtors® Inc' },
            { text: 'Ann Arbor Area Board Of Realtors®', value: 'Ann Arbor Area Board Of Realtors®' },
            { text: 'Anne Arundel County Association Of Realtors® Inc', value: 'Anne Arundel County Association Of Realtors® Inc' },
            { text: 'Antrim Charlevoix Kalkaska Association Of Realtors®', value: 'Antrim Charlevoix Kalkaska Association Of Realtors®' },
            { text: 'Arcadia Association Of Realtors®', value: 'Arcadia Association Of Realtors®' },
            { text: 'Arlington Board Of Realtors®', value: 'Arlington Board Of Realtors®' },
            { text: 'Ashland Area Board Of Realtors®', value: 'Ashland Area Board Of Realtors®' },
            { text: 'Ashland Board Of Realtors®', value: 'Ashland Board Of Realtors®' },
            { text: 'Ashtabula County Board Of Realtors®', value: 'Ashtabula County Board Of Realtors®' },
            { text: 'Aspen Board Of Realtors®', value: 'Aspen Board Of Realtors®' },
            { text: 'Athens-limestone Association Of Realtors® Inc', value: 'Athens-limestone Association Of Realtors® Inc' },
            { text: 'Athens Area Association Of Realtors, Inc.', value: 'Athens Area Association Of Realtors, Inc.' },
            { text: 'Athens County Board Of Realtors®', value: 'Athens County Board Of Realtors®' },
            { text: 'Atlanta Commercial Board Of Realtors®', value: 'Atlanta Commercial Board Of Realtors®' },
            { text: 'Atlanta Realtors® Association', value: 'Atlanta Realtors® Association' },
            { text: 'Atlantic City And County Board Of Realtors®', value: 'Atlantic City And County Board Of Realtors®' },
            { text: 'Austin Board Of Realtors®', value: 'Austin Board Of Realtors®' },
            { text: 'Badlands Board Of Realtors® Inc', value: 'Badlands Board Of Realtors® Inc' },
            { text: 'Bagnell Dam Association Of Realtors®', value: 'Bagnell Dam Association Of Realtors®' },
            { text: 'Bakersfield Association Of Realtors® Inc', value: 'Bakersfield Association Of Realtors® Inc' },
            { text: 'Baldwin Realtors®', value: 'Baldwin Realtors®' },
            { text: 'Bandera County Board Of Realtors®', value: 'Bandera County Board Of Realtors®' },
            { text: 'Bartow Board Of Realtors® Inc', value: 'Bartow Board Of Realtors® Inc' },
            { text: 'Bastrop County Board Of Realtors® Inc', value: 'Bastrop County Board Of Realtors® Inc' },
            { text: 'Batesville Board Of Realtors®', value: 'Batesville Board Of Realtors®' },
            { text: 'Battle Creek Area Association Of Realtors®', value: 'Battle Creek Area Association Of Realtors®' },
            { text: 'Bay Area Association Of Realtors® Inc', value: 'Bay Area Association Of Realtors® Inc' },
            { text: 'Bay County Realtor® Association', value: 'Bay County Realtor® Association' },
            { text: 'Bay East Association Of Realtors®', value: 'Bay East Association Of Realtors®' },
            { text: 'Bayamon Board Of Realtors®', value: 'Bayamon Board Of Realtors®' },
            { text: 'Bayou Board Of Realtors®', value: 'Bayou Board Of Realtors®' },
            { text: 'Beaufort- Jasper County Realtors®', value: 'Beaufort- Jasper County Realtors®' },
            { text: 'Beaumont Board Of Realtors® Inc', value: 'Beaumont Board Of Realtors® Inc' },
            { text: 'Beaver County Association Of Realtors®', value: 'Beaver County Association Of Realtors®' },
            { text: 'Beaver Creek Area Association Of Realtors®', value: 'Beaver Creek Area Association Of Realtors®' },
            { text: 'Beckley Board Of Realtors®', value: 'Beckley Board Of Realtors®' },
            { text: 'Bedford Board Of Realtors®', value: 'Bedford Board Of Realtors®' },
            { text: 'Bee County Board Of Realtors®', value: 'Bee County Board Of Realtors®' },
            { text: 'Belvidere Board Of Realtors®', value: 'Belvidere Board Of Realtors®' },
            { text: 'Berkshire County Board Of Realtors®', value: 'Berkshire County Board Of Realtors®' },
            { text: 'Big Bear Association Of Realtors®', value: 'Big Bear Association Of Realtors®' },
            { text: 'Billings Association Of Realtors®', value: 'Billings Association Of Realtors®' },
            { text: 'Biloxi Ocean Springs Association Of Realtors® Inc', value: 'Biloxi Ocean Springs Association Of Realtors® Inc' },
            { text: 'Birmingham Area Association Of Realtors® Inc', value: 'Birmingham Area Association Of Realtors® Inc' },
            { text: 'Bismarck Mandan Board Of Realtors®', value: 'Bismarck Mandan Board Of Realtors®' },
            { text: 'Bitterroot Valley Board Of Realtors®', value: 'Bitterroot Valley Board Of Realtors®' },
            { text: 'Black Hills Association Of Realtors®', value: 'Black Hills Association Of Realtors®' },
            { text: 'Bloomington Board Of Realtors®', value: 'Bloomington Board Of Realtors®' },
            { text: 'Blue Ridge Association Of Realtors® Inc', value: 'Blue Ridge Association Of Realtors® Inc' },
            { text: 'Blue River Area Board Of Realtors®', value: 'Blue River Area Board Of Realtors®' },
            { text: 'Boise Regional Realtors®', value: 'Boise Regional Realtors®' },
            { text: 'Bonita Springs-estero Realtors®', value: 'Bonita Springs-estero Realtors®' },
            { text: 'Bootheel Regional Board Of Realtors®', value: 'Bootheel Regional Board Of Realtors®' },
            { text: 'Boulder Area Realtor® Association Inc', value: 'Boulder Area Realtor® Association Inc' },
            { text: 'Bradford Sullivan Association Of Realtors® Inc', value: 'Bradford Sullivan Association Of Realtors® Inc' },
            { text: 'Branch County Association Of Realtors®', value: 'Branch County Association Of Realtors®' },
            { text: 'Brazoria County Board Of Realtors®', value: 'Brazoria County Board Of Realtors®' },
            { text: 'Bridge Association Of Realtors®', value: 'Bridge Association Of Realtors®' },
            { text: 'Brigham Tremonton Board Of Realtors®', value: 'Brigham Tremonton Board Of Realtors®' },
            { text: 'Bristol Tennessee Virginia Association Of Realtors® Inc', value: 'Bristol Tennessee Virginia Association Of Realtors® Inc' },
            { text: 'Brooklyn Board Of Realtors® Inc', value: 'Brooklyn Board Of Realtors® Inc' },
            { text: 'Broward Palm Beaches & St Lucie Realtors®', value: 'Broward Palm Beaches & St Lucie Realtors®' },
            { text: 'Brownsville South Padre Islands Board Of Realtors® Inc', value: 'Brownsville South Padre Islands Board Of Realtors® Inc' },
            { text: 'Brunswick County Association Of Realtors®', value: 'Brunswick County Association Of Realtors®' },
            { text: 'Bryan College Station Regional Association Of Realtors®', value: 'Bryan College Station Regional Association Of Realtors®' },
            { text: 'Bucks County Association Of Realtors® Inc', value: 'Bucks County Association Of Realtors® Inc' },
            { text: 'Buffalo Board Of Realtors®', value: 'Buffalo Board Of Realtors®' },
            { text: 'Buffalo Niagara Association Of Realtors® Inc', value: 'Buffalo Niagara Association Of Realtors® Inc' },
            { text: 'Bullhead City Mohave Valley Association Of Realtors®', value: 'Bullhead City Mohave Valley Association Of Realtors®' },
            { text: 'Burbank Association Of Realtors®', value: 'Burbank Association Of Realtors®' },
            { text: 'Burke County Board Of Realtors® Inc', value: 'Burke County Board Of Realtors® Inc' },
            { text: 'Burlington Alamance County Association Of Realtors® Inc', value: 'Burlington Alamance County Association Of Realtors® Inc' },
            { text: 'Butler-warren Association Of Realtors® Inc', value: 'Butler-warren Association Of Realtors® Inc' },
            { text: 'Butler County Association Of Realtors®', value: 'Butler County Association Of Realtors®' },
            { text: 'Cache Valley Association Of Realtors® Inc', value: 'Cache Valley Association Of Realtors® Inc' },
            { text: 'Caguas Board Of Realtors®', value: 'Caguas Board Of Realtors®' },
            { text: 'Calaveras County Association Of Realtors®', value: 'Calaveras County Association Of Realtors®' },
            { text: 'Caldwell Board Of Realtors®', value: 'Caldwell Board Of Realtors®' },
            { text: 'Calhoun County Area Board Of Realtors® Inc', value: 'Calhoun County Area Board Of Realtors® Inc' },
            { text: 'California Desert Association Of Realtors®', value: 'California Desert Association Of Realtors®' },
            { text: 'Cambria Somerset Association Of Realtors® Inc', value: 'Cambria Somerset Association Of Realtors® Inc' },
            { text: 'Camden Charlton County Board Of Realtors®', value: 'Camden Charlton County Board Of Realtors®' },
            { text: 'Cape Cod & Islands Association Of Realtors® Inc', value: 'Cape Cod & Islands Association Of Realtors® Inc' },
            { text: 'Cape Fear Realtors®', value: 'Cape Fear Realtors®' },
            { text: 'Cape May County Association Of Realtors®', value: 'Cape May County Association Of Realtors®' },
            { text: 'Capital Area Association Of Realtors®', value: 'Capital Area Association Of Realtors®' },
            { text: 'Capital Region Board Of Realtors® Inc', value: 'Capital Region Board Of Realtors® Inc' },
            { text: 'Carbon County Board Of Realtors®', value: 'Carbon County Board Of Realtors®' },
            { text: 'Carlsbad Board Of Realtors® Inc', value: 'Carlsbad Board Of Realtors® Inc' },
            { text: 'Carolina Smokies Association Of Realtors® Inc', value: 'Carolina Smokies Association Of Realtors® Inc' },
            { text: 'Carpet Capital Association Of Realtors®', value: 'Carpet Capital Association Of Realtors®' },
            { text: 'Carroll County Association Of Realtors® Inc', value: 'Carroll County Association Of Realtors® Inc' },
            { text: 'Carteret County Association Of Realtors® Inc', value: 'Carteret County Association Of Realtors® Inc' },
            { text: 'Casper Board Of Realtors®', value: 'Casper Board Of Realtors®' },
            { text: 'Catawba Valley Association Of Realtors® Inc', value: 'Catawba Valley Association Of Realtors® Inc' },
            { text: 'Cecil County Board Of Realtors®', value: 'Cecil County Board Of Realtors®' },
            { text: 'Cedar Rapids Area Association Of Realtors®', value: 'Cedar Rapids Area Association Of Realtors®' },
            { text: 'Central Arizona Board Of Realtors® Inc', value: 'Central Arizona Board Of Realtors® Inc' },
            { text: 'Central Carolina Association Of Realtors® Inc', value: 'Central Carolina Association Of Realtors® Inc' },
            { text: 'Central Carolina Realtors® Association', value: 'Central Carolina Realtors® Association' },
            { text: 'Central Florida Commercial Association Of Realtors®', value: 'Central Florida Commercial Association Of Realtors®' },
            { text: 'Central Georgia Board Of Realtors®', value: 'Central Georgia Board Of Realtors®' },
            { text: 'Central Hill Country Board Of Realtors® Inc', value: 'Central Hill Country Board Of Realtors® Inc' },
            { text: 'Central Illinois Board Of Realtors®', value: 'Central Illinois Board Of Realtors®' },
            { text: 'Central Iowa Board Of Realtors® Inc', value: 'Central Iowa Board Of Realtors® Inc' },
            { text: 'Central Kentucky Association Of Realtors® Inc', value: 'Central Kentucky Association Of Realtors® Inc' },
            { text: 'Central Michigan Association Of Realtors®', value: 'Central Michigan Association Of Realtors®' },
            { text: 'Central Mississippi Realtors® Inc', value: 'Central Mississippi Realtors® Inc' },
            { text: 'Central Missouri Board Of Realtors® Inc', value: 'Central Missouri Board Of Realtors® Inc' },
            { text: 'Central Oklahoma Commercial Association Of Realtors®', value: 'Central Oklahoma Commercial Association Of Realtors®' },
            { text: 'Central Oregon Association Of Realtors®', value: 'Central Oregon Association Of Realtors®' },
            { text: 'Central Oregon Coast Board Of Realtors®', value: 'Central Oregon Coast Board Of Realtors®' },
            { text: 'Central Panhandle Association Of Realtors®', value: 'Central Panhandle Association Of Realtors®' },
            { text: 'Central South Dakota Board Of Realtors®', value: 'Central South Dakota Board Of Realtors®' },
            { text: 'Central Susquehanna Valley Board Of Realtors®', value: 'Central Susquehanna Valley Board Of Realtors®' },
            { text: 'Central Texas Commercial Association Of Realtors®', value: 'Central Texas Commercial Association Of Realtors®' },
            { text: 'Central Valley Association Of Realtors® Inc', value: 'Central Valley Association Of Realtors® Inc' },
            { text: 'Central West Tennessee Association Of Realtors®', value: 'Central West Tennessee Association Of Realtors®' },
            { text: 'Central Wisconsin Board Of Realtors® Inc', value: 'Central Wisconsin Board Of Realtors® Inc' },
            { text: 'Centre County Association Of Realtors® Inc', value: 'Centre County Association Of Realtors® Inc' },
            { text: 'Champaign County Association Of Realtors®', value: 'Champaign County Association Of Realtors®' },
            { text: 'Charleston Trident Association Of Realtors® Inc', value: 'Charleston Trident Association Of Realtors® Inc' },
            { text: 'Charlotte Region Commercial Board Of Realtors®', value: 'Charlotte Region Commercial Board Of Realtors®' },
            { text: 'Charlotte Regional Realtor® Association Inc', value: 'Charlotte Regional Realtor® Association Inc' },
            { text: 'Charlottesville Area Association Of Realtors®', value: 'Charlottesville Area Association Of Realtors®' },
            { text: 'Chautauqua-cattaraugus Board Of Realtors® Inc', value: 'Chautauqua-cattaraugus Board Of Realtors® Inc' },
            { text: 'Cherokee Association Of Realtors® Inc', value: 'Cherokee Association Of Realtors® Inc' },
            { text: 'Cherokee County Board Of Realtors®', value: 'Cherokee County Board Of Realtors®' },
            { text: 'Chesapeake Bay & Rivers Association Of Realtors®', value: 'Chesapeake Bay & Rivers Association Of Realtors®' },
            { text: 'Cheyenne Board Of Realtors®', value: 'Cheyenne Board Of Realtors®' },
            { text: 'Chicago Association Of Realtors® Inc', value: 'Chicago Association Of Realtors® Inc' },
            { text: 'Cincinnati Area Board Of Realtors® Inc', value: 'Cincinnati Area Board Of Realtors® Inc' },
            { text: 'Citrus Valley Association Of Realtors®', value: 'Citrus Valley Association Of Realtors®' },
            { text: 'Clare Gladwin Board Of Realtors®', value: 'Clare Gladwin Board Of Realtors®' },
            { text: 'Clark County Board Of Realtors® Inc', value: 'Clark County Board Of Realtors® Inc' },
            { text: 'Clarksdale Board Of Realtors®', value: 'Clarksdale Board Of Realtors®' },
            { text: 'Clarksville Association Of Realtors®, Inc.', value: 'Clarksville Association Of Realtors®, Inc.' },
            { text: 'Clatsop Association Of Realtors®', value: 'Clatsop Association Of Realtors®' },
            { text: 'Clearfield Jefferson Association Of Realtors®', value: 'Clearfield Jefferson Association Of Realtors®' },
            { text: 'Cleburne County Board Of Realtors® Inc', value: 'Cleburne County Board Of Realtors® Inc' },
            { text: 'Cleveland Board Of Realtors®', value: 'Cleveland Board Of Realtors®' },
            { text: 'Cleveland County Association Of Realtors®', value: 'Cleveland County Association Of Realtors®' },
            { text: 'Clinton County Board Of Realtors®', value: 'Clinton County Board Of Realtors®' },
            { text: 'Clinton County Board Of Realtors®', value: 'Clinton County Board Of Realtors®' },
            { text: 'Clovis Board Of Realtors® Inc', value: 'Clovis Board Of Realtors® Inc' },
            { text: 'Coastal Association Of Realtors®', value: 'Coastal Association Of Realtors®' },
            { text: 'Coastal Bend Association Of Realtors®', value: 'Coastal Bend Association Of Realtors®' },
            { text: 'Coastal Carolinas Association Of Realtors®', value: 'Coastal Carolinas Association Of Realtors®' },
            { text: 'Coastal Mendocino Association Of Realtors® Inc', value: 'Coastal Mendocino Association Of Realtors® Inc' },
            { text: 'Coastal Plains Association Of Realtors®', value: 'Coastal Plains Association Of Realtors®' },
            { text: 'Cobb Association Of Realtors®', value: 'Cobb Association Of Realtors®' },
            { text: 'Coeur D Alene Association Of Realtors® Inc', value: 'Coeur D Alene Association Of Realtors® Inc' },
            { text: 'Collin County Association Of Realtors® Inc', value: 'Collin County Association Of Realtors® Inc' },
            { text: 'Columbia-greene Board Of Realtors® Inc', value: 'Columbia-greene Board Of Realtors® Inc' },
            { text: 'Columbia Basin Board Of Realtors® Inc', value: 'Columbia Basin Board Of Realtors® Inc' },
            { text: 'Columbia Board Of Realtors®', value: 'Columbia Board Of Realtors®' },
            { text: 'Columbia County Board Of Realtors®', value: 'Columbia County Board Of Realtors®' },
            { text: 'Columbus Association Of Realtors®', value: 'Columbus Association Of Realtors®' },
            { text: 'Columbus Board Of Realtors®', value: 'Columbus Board Of Realtors®' },
            { text: 'Columbus Board Of Realtors® Inc', value: 'Columbus Board Of Realtors® Inc' },
            { text: 'Commercial Alliance Of Realtors®', value: 'Commercial Alliance Of Realtors®' },
            { text: 'Commercial Association Of Realtors® New Mexico', value: 'Commercial Association Of Realtors® New Mexico' },
            { text: 'Commercial Association Of Realtors® Wisconsin', value: 'Commercial Association Of Realtors® Wisconsin' },
            { text: 'Commercial Board Of Realtors® Inc', value: 'Commercial Board Of Realtors® Inc' },
            { text: 'Conejo Simi Moorpark Association Of Realtors®', value: 'Conejo Simi Moorpark Association Of Realtors®' },
            { text: 'Contoocook Valley Board Of Realtors® Inc', value: 'Contoocook Valley Board Of Realtors® Inc' },
            { text: 'Contra Costa Association Of Realtors®', value: 'Contra Costa Association Of Realtors®' },
            { text: 'Conway & Perry Counties Realtors® Association', value: 'Conway & Perry Counties Realtors® Association' },
            { text: 'Coos County Board Of Realtors®', value: 'Coos County Board Of Realtors®' },
            { text: 'Core Association Of Realtors®', value: 'Core Association Of Realtors®' },
            { text: 'Corpus Christi Association Of Realtors®', value: 'Corpus Christi Association Of Realtors®' },
            { text: 'Cortland County Board Of Realtors® Inc', value: 'Cortland County Board Of Realtors® Inc' },
            { text: 'Coshocton County Board Of Realtors®', value: 'Coshocton County Board Of Realtors®' },
            { text: 'Covington Association Of Realtors® Inc', value: 'Covington Association Of Realtors® Inc' },
            { text: 'Craig Association Of Realtors', value: 'Craig Association Of Realtors' },
            { text: 'Crawford County Board Of Realtors®', value: 'Crawford County Board Of Realtors®' },
            { text: 'Crisp Area Board Of Realtors® Inc', value: 'Crisp Area Board Of Realtors® Inc' },
            { text: 'Crossroads Association Of Realtors® Inc', value: 'Crossroads Association Of Realtors® Inc' },
            { text: 'Crown Point Board Of Realtors® Inc', value: 'Crown Point Board Of Realtors® Inc' },
            { text: 'Cullman Association Of Realtors® Inc', value: 'Cullman Association Of Realtors® Inc' },
            { text: 'Cumberland County Board Of Realtors®', value: 'Cumberland County Board Of Realtors®' },
            { text: 'Curry County Board Of Realtors® Inc', value: 'Curry County Board Of Realtors® Inc' },
            { text: 'Dalhart Board Of Realtors®', value: 'Dalhart Board Of Realtors®' },
            { text: 'Dan River Region Association Of Realtors® Inc', value: 'Dan River Region Association Of Realtors® Inc' },
            { text: 'Darien Board Of Realtors® Inc', value: 'Darien Board Of Realtors® Inc' },
            { text: 'Dayton Realtors®', value: 'Dayton Realtors®' },
            { text: 'Daytona Beach Area Association Of Realtors® Inc', value: 'Daytona Beach Area Association Of Realtors® Inc' },
            { text: 'Dearborn Area Board Of Realtors®', value: 'Dearborn Area Board Of Realtors®' },
            { text: 'Dekalb Association Of Realtors® Inc', value: 'Dekalb Association Of Realtors® Inc' },
            { text: 'Dekalb County Association Of Realtors® Inc', value: 'Dekalb County Association Of Realtors® Inc' },
            { text: 'Del Norte Association Of Realtors®', value: 'Del Norte Association Of Realtors®' },
            { text: 'Del Rio Board Of Realtors®', value: 'Del Rio Board Of Realtors®' },
            { text: 'Delaware County Board Of Realtors®', value: 'Delaware County Board Of Realtors®' },
            { text: 'Delta Association Of Realtors®', value: 'Delta Association Of Realtors®' },
            { text: 'Delta County Board Of Realtors®', value: 'Delta County Board Of Realtors®' },
            { text: 'Deming Luna County Board Of Realtors®', value: 'Deming Luna County Board Of Realtors®' },
            { text: 'Denver Metro Association Of Realtors®', value: 'Denver Metro Association Of Realtors®' },
            { text: 'Denver Metro Commercial Assoc Of Realtors® Inc', value: 'Denver Metro Commercial Assoc Of Realtors® Inc' },
            { text: 'Des Moines Area Association Of Realtors®', value: 'Des Moines Area Association Of Realtors®' },
            { text: 'Detroit Association Of Realtors®', value: 'Detroit Association Of Realtors®' },
            { text: 'Dixie Gilchrist Levy Counties Board Of Realtors® Inc', value: 'Dixie Gilchrist Levy Counties Board Of Realtors® Inc' },
            { text: 'Dodge City Board Of Realtors®', value: 'Dodge City Board Of Realtors®' },
            { text: 'Door County Board Of Realtors®', value: 'Door County Board Of Realtors®' },
            { text: 'Douglas Board Of Realtors®', value: 'Douglas Board Of Realtors®' },
            { text: 'Douglas Coffee County Board Of Realtors® Inc', value: 'Douglas Coffee County Board Of Realtors® Inc' },
            { text: 'Douglas County Association Of Realtors®', value: 'Douglas County Association Of Realtors®' },
            { text: 'Dublin Board Of Realtors®', value: 'Dublin Board Of Realtors®' },
            { text: 'Dulles Area Association Of Realtors® Inc', value: 'Dulles Area Association Of Realtors® Inc' },
            { text: 'Dumas Board Of Realtors®', value: 'Dumas Board Of Realtors®' },
            { text: 'Duncan Association Of Realtors® Inc', value: 'Duncan Association Of Realtors® Inc' },
            { text: 'Durango Area Assocation Of Realtors® Inc', value: 'Durango Area Assocation Of Realtors® Inc' },
            { text: 'Durham Regional Association Of Realtors®', value: 'Durham Regional Association Of Realtors®' },
            { text: 'Dutchess County Association Of Realtors® Inc', value: 'Dutchess County Association Of Realtors® Inc' },
            { text: 'Eagle Pass Board Of Realtors®', value: 'Eagle Pass Board Of Realtors®' },
            { text: 'East Alabama Board Of Realtors®', value: 'East Alabama Board Of Realtors®' },
            { text: 'East Central Association Of Realtors®', value: 'East Central Association Of Realtors®' },
            { text: 'East Central Association Of Realtors® Inc', value: 'East Central Association Of Realtors® Inc' },
            { text: 'East Central Board Of Realtors®', value: 'East Central Board Of Realtors®' },
            { text: 'East Central Indiana Board Of Realtors® Inc', value: 'East Central Indiana Board Of Realtors® Inc' },
            { text: 'East Central Iowa Association Of Realtors® Inc', value: 'East Central Iowa Association Of Realtors® Inc' },
            { text: 'East Central South Dakota Realtors®', value: 'East Central South Dakota Realtors®' },
            { text: 'East Metro Association Of Realtors®', value: 'East Metro Association Of Realtors®' },
            { text: 'East Metro Board Of Realtors® Inc', value: 'East Metro Board Of Realtors® Inc' },
            { text: 'East Mississippi Realtors®', value: 'East Mississippi Realtors®' },
            { text: 'East Polk County Association Of Realtors® Inc', value: 'East Polk County Association Of Realtors® Inc' },
            { text: 'East Valley Association Of Realtors®', value: 'East Valley Association Of Realtors®' },
            { text: 'Eastern Arkansas Realtors® Association', value: 'Eastern Arkansas Realtors® Association' },
            { text: 'Eastern Connecticut Association Of Realtors® Inc', value: 'Eastern Connecticut Association Of Realtors® Inc' },
            { text: 'Eastern Kentucky Association Of Realtors®', value: 'Eastern Kentucky Association Of Realtors®' },
            { text: 'Eastern Middle Tennessee Association Of Realtors® Inc', value: 'Eastern Middle Tennessee Association Of Realtors® Inc' },
            { text: 'Eastern Panhandle Board Of Realtors® Inc', value: 'Eastern Panhandle Board Of Realtors® Inc' },
            { text: 'Eastern Shore Association Of Realtors® Inc', value: 'Eastern Shore Association Of Realtors® Inc' },
            { text: 'Eastern Thumb Association Of Realtors®', value: 'Eastern Thumb Association Of Realtors®' },
            { text: 'Eastern Upper Peninsula Board Of Realtors®', value: 'Eastern Upper Peninsula Board Of Realtors®' },
            { text: 'Edmond Board Of Realtors®', value: 'Edmond Board Of Realtors®' },
            { text: 'Egyptian Board Of Realtors® Inc', value: 'Egyptian Board Of Realtors® Inc' },
            { text: 'El Dorado Board Of Realtors®', value: 'El Dorado Board Of Realtors®' },
            { text: 'El Dorado County Association Of Realtors® Inc', value: 'El Dorado County Association Of Realtors® Inc' },
            { text: 'Elk Cameron Board Of Realtors®', value: 'Elk Cameron Board Of Realtors®' },
            { text: 'Elkhart County Board Of Realtors® Inc', value: 'Elkhart County Board Of Realtors® Inc' },
            { text: 'Elkins Randolph Board Of Realtors Inc', value: 'Elkins Randolph Board Of Realtors Inc' },
            { text: 'Elko County Board Of Realtors® Inc', value: 'Elko County Board Of Realtors® Inc' },
            { text: 'Elmira Corning Regional Board Of Realtors® Inc', value: 'Elmira Corning Regional Board Of Realtors® Inc' },
            { text: 'Emerald Coast Association Of Realtors® Inc', value: 'Emerald Coast Association Of Realtors® Inc' },
            { text: 'Emmet Association Of Realtors®', value: 'Emmet Association Of Realtors®' },
            { text: 'Englewood Area Board Of Realtors® Inc', value: 'Englewood Area Board Of Realtors® Inc' },
            { text: 'Estes Valley Board Of Realtors®', value: 'Estes Valley Board Of Realtors®' },
            { text: 'Etowah-cherokee County Association Of Realtors®', value: 'Etowah-cherokee County Association Of Realtors®' },
            { text: 'Eufaula Board Of Realtors®', value: 'Eufaula Board Of Realtors®' },
            { text: 'Eugene Association Of Realtors®', value: 'Eugene Association Of Realtors®' },
            { text: 'Eureka Springs Carroll County Board Of Realtors®', value: 'Eureka Springs Carroll County Board Of Realtors®' },
            { text: 'Fairmont Board Of Realtors® Inc', value: 'Fairmont Board Of Realtors® Inc' },
            { text: 'Fargo Moorhead Area Association Of Realtors® Inc', value: 'Fargo Moorhead Area Association Of Realtors® Inc' },
            { text: 'Faulkner County Board Of Realtors®', value: 'Faulkner County Board Of Realtors®' },
            { text: 'Fayette-nicholas Board Of Realtors®', value: 'Fayette-nicholas Board Of Realtors®' },
            { text: 'Fayette Board Of Realtors®', value: 'Fayette Board Of Realtors®' },
            { text: 'Fayette County Board Of Realtors® Inc', value: 'Fayette County Board Of Realtors® Inc' },
            { text: 'Firelands Association Of Realtors®', value: 'Firelands Association Of Realtors®' },
            { text: 'Flagler County Association Of Realtors® Inc', value: 'Flagler County Association Of Realtors® Inc' },
            { text: 'Flint Hills Association Of Realtors®', value: 'Flint Hills Association Of Realtors®' },
            { text: 'Florida Gulfcoast Commercial Association Of Realtors® Inc', value: 'Florida Gulfcoast Commercial Association Of Realtors® Inc' },
            { text: 'Florida Keys Board Of Realtors®', value: 'Florida Keys Board Of Realtors®' },
            { text: 'Fort Collins Board Of Realtors®', value: 'Fort Collins Board Of Realtors®' },
            { text: 'Fort Hood Area Association Of Realtors®', value: 'Fort Hood Area Association Of Realtors®' },
            { text: 'Fort Smith Board Of Realtors®', value: 'Fort Smith Board Of Realtors®' },
            { text: 'Four Corners Board Of Realtors®', value: 'Four Corners Board Of Realtors®' },
            { text: 'Four County Board Of Realtors® Inc', value: 'Four County Board Of Realtors® Inc' },
            { text: 'Four Rivers Association Of Realtors®', value: 'Four Rivers Association Of Realtors®' },
            { text: 'Franklin County Board Of Realtors®', value: 'Franklin County Board Of Realtors®' },
            { text: 'Frederick County Association Of Realtors® Inc', value: 'Frederick County Association Of Realtors® Inc' },
            { text: 'Fredericksburg Area Association Of Realtors® Inc', value: 'Fredericksburg Area Association Of Realtors® Inc' },
            { text: 'Fremont Board Of Realtors®', value: 'Fremont Board Of Realtors®' },
            { text: 'Fremont County Board Of Realtors®', value: 'Fremont County Board Of Realtors®' },
            { text: 'Fresno Association Of Realtors®', value: 'Fresno Association Of Realtors®' },
            { text: 'Gainesville Alachua County Association Of Realtors® Inc', value: 'Gainesville Alachua County Association Of Realtors® Inc' },
            { text: 'Gallatin Association Of Realtors®', value: 'Gallatin Association Of Realtors®' },
            { text: 'Gallup Board Of Realtors®', value: 'Gallup Board Of Realtors®' },
            { text: 'Galveston Association Of Realtors® Inc', value: 'Galveston Association Of Realtors® Inc' },
            { text: 'Garden City Board Of Realtors® Inc', value: 'Garden City Board Of Realtors® Inc' },
            { text: 'Garrett County Board Of Realtors® Inc', value: 'Garrett County Board Of Realtors® Inc' },
            { text: 'Gaston Association Of Realtors® Inc', value: 'Gaston Association Of Realtors® Inc' },
            { text: 'Georgia Mountains & Lakes Realtors® Association', value: 'Georgia Mountains & Lakes Realtors® Association' },
            { text: 'Georgia Upstate Lakes Board Of Realtors®', value: 'Georgia Upstate Lakes Board Of Realtors®' },
            { text: 'Glendale Association Of Realtors', value: 'Glendale Association Of Realtors' },
            { text: 'Glenwood Springs Association Of Realtors® Inc', value: 'Glenwood Springs Association Of Realtors® Inc' },
            { text: 'Gloucester Salem Counties Board Of Realtors®', value: 'Gloucester Salem Counties Board Of Realtors®' },
            { text: 'Golden Isles Association Of Realtors® Inc', value: 'Golden Isles Association Of Realtors® Inc' },
            { text: 'Golden Triangle Association Of Realtors® Inc', value: 'Golden Triangle Association Of Realtors® Inc' },
            { text: 'Goldsboro Wayne County Association Of Realtors® Inc', value: 'Goldsboro Wayne County Association Of Realtors® Inc' },
            { text: 'Granbury Association Of Realtors® Inc', value: 'Granbury Association Of Realtors® Inc' },
            { text: 'Grand County Board Of Realtors® Inc', value: 'Grand County Board Of Realtors® Inc' },
            { text: 'Grand Forks Area Association Of Realtors® Inc', value: 'Grand Forks Area Association Of Realtors® Inc' },
            { text: 'Grand Island Board Of Realtors®', value: 'Grand Island Board Of Realtors®' },
            { text: 'Grand Junction Area Realtor® Association', value: 'Grand Junction Area Realtor® Association' },
            { text: 'Granite State South Board Of Realtors® Inc', value: 'Granite State South Board Of Realtors® Inc' },
            { text: 'Grant County Association Of Realtors®', value: 'Grant County Association Of Realtors®' },
            { text: 'Great Falls Association Of Realtors® Inc', value: 'Great Falls Association Of Realtors® Inc' },
            { text: 'Great Plains Association Of Realtors® Inc', value: 'Great Plains Association Of Realtors® Inc' },
            { text: 'Great Smoky Mountains Association Of Realtors® Inc', value: 'Great Smoky Mountains Association Of Realtors® Inc' },
            { text: 'Greater Albuquerque Association Of Realtors®', value: 'Greater Albuquerque Association Of Realtors®' },
            { text: 'Greater Alexandria Area Association Of Realtors® Inc', value: 'Greater Alexandria Area Association Of Realtors® Inc' },
            { text: 'Greater Allegheny Kiski Area Board Of Realtors®', value: 'Greater Allegheny Kiski Area Board Of Realtors®' },
            { text: 'Greater Antelope Valley Association Of Realtors® Inc', value: 'Greater Antelope Valley Association Of Realtors® Inc' },
            { text: 'Greater Augusta Association Of Realtors®', value: 'Greater Augusta Association Of Realtors®' },
            { text: 'Greater Augusta Association Of Realtors®', value: 'Greater Augusta Association Of Realtors®' },
            { text: 'Greater Baltimore Board Of Realtors® Inc', value: 'Greater Baltimore Board Of Realtors® Inc' },
            { text: 'Greater Bangor Association Of Realtors®', value: 'Greater Bangor Association Of Realtors®' },
            { text: 'Greater Baton Rouge Association Of Realtors®', value: 'Greater Baton Rouge Association Of Realtors®' },
            { text: 'Greater Bergen Association Of Realtors®', value: 'Greater Bergen Association Of Realtors®' },
            { text: 'Greater Binghamton Association Of Realtors® Inc', value: 'Greater Binghamton Association Of Realtors® Inc' },
            { text: 'Greater Blackfoot Association Of Realtors®', value: 'Greater Blackfoot Association Of Realtors®' },
            { text: 'Greater Boston Real Estate Board', value: 'Greater Boston Real Estate Board' },
            { text: 'Greater Bridgeport Board Of Realtors® Inc', value: 'Greater Bridgeport Board Of Realtors® Inc' },
            { text: 'Greater Capital Area Association Of Realtors® Inc', value: 'Greater Capital Area Association Of Realtors® Inc' },
            { text: 'Greater Capital Association Of Realtors® Inc', value: 'Greater Capital Association Of Realtors® Inc' },
            { text: 'Greater Central Louisiana Realtor® Association Inc', value: 'Greater Central Louisiana Realtor® Association Inc' },
            { text: 'Greater Chattanooga Association Of Realtors® Inc', value: 'Greater Chattanooga Association Of Realtors® Inc' },
            { text: 'Greater Claremont Board Of Realtors® Inc', value: 'Greater Claremont Board Of Realtors® Inc' },
            { text: 'Greater Denton Wise County Association Of Realtors® Inc', value: 'Greater Denton Wise County Association Of Realtors® Inc' },
            { text: 'Greater Downey Association Of Realtors® Inc', value: 'Greater Downey Association Of Realtors® Inc' },
            { text: 'Greater El Paso Association Of Realtors®', value: 'Greater El Paso Association Of Realtors®' },
            { text: 'Greater Erie Board Of Realtors® Inc', value: 'Greater Erie Board Of Realtors® Inc' },
            { text: 'Greater Fairbanks Board Of Realtors®', value: 'Greater Fairbanks Board Of Realtors®' },
            { text: 'Greater Fairfield Board Of Realtors® Inc', value: 'Greater Fairfield Board Of Realtors® Inc' },
            { text: 'Greater Fort Polk Area Realtors®', value: 'Greater Fort Polk Area Realtors®' },
            { text: 'Greater Fort Worth Association Of Realtors® Inc', value: 'Greater Fort Worth Association Of Realtors® Inc' },
            { text: 'Greater Gateway Association Of Realtors® Inc', value: 'Greater Gateway Association Of Realtors® Inc' },
            { text: 'Greater Greenville Association Of Realtors® Inc', value: 'Greater Greenville Association Of Realtors® Inc' },
            { text: 'Greater Harrisburg Association Of Realtors® Inc', value: 'Greater Harrisburg Association Of Realtors® Inc' },
            { text: 'Greater Hartford Association Of Realtors® Inc', value: 'Greater Hartford Association Of Realtors® Inc' },
            { text: 'Greater Idaho Falls Association Of Realtors® Inc', value: 'Greater Idaho Falls Association Of Realtors® Inc' },
            { text: 'Greater Kalamazoo Association Of Realtors® Inc', value: 'Greater Kalamazoo Association Of Realtors® Inc' },
            { text: 'Greater Lakes Association Of Realtors® Inc', value: 'Greater Lakes Association Of Realtors® Inc' },
            { text: 'Greater Lansing Association Of Realtors®', value: 'Greater Lansing Association Of Realtors®' },
            { text: 'Greater Las Vegas Association Of Realtors® Inc', value: 'Greater Las Vegas Association Of Realtors® Inc' },
            { text: 'Greater Lehigh Valley Realtors® Inc', value: 'Greater Lehigh Valley Realtors® Inc' },
            { text: 'Greater Lewisville Association Of Realtors® Inc', value: 'Greater Lewisville Association Of Realtors® Inc' },
            { text: 'Greater Los Angeles Realtors®', value: 'Greater Los Angeles Realtors®' },
            { text: 'Greater Louisville Association Of Realtors® Inc', value: 'Greater Louisville Association Of Realtors® Inc' },
            { text: 'Greater Manchester/nashua Board Of Realtors® Inc', value: 'Greater Manchester/nashua Board Of Realtors® Inc' },
            { text: 'Greater Mason City Board Of Realtors®', value: 'Greater Mason City Board Of Realtors®' },
            { text: 'Greater Mc Allen Association Of Realtors® Inc', value: 'Greater Mc Allen Association Of Realtors® Inc' },
            { text: 'Greater Mercer County Association Of Realtors® Inc', value: 'Greater Mercer County Association Of Realtors® Inc' },
            { text: 'Greater Metro West Association Realtors® Inc', value: 'Greater Metro West Association Realtors® Inc' },
            { text: 'Greater Metropolitan Association Of Realtors®', value: 'Greater Metropolitan Association Of Realtors®' },
            { text: 'Greater Milwaukee Association Of Realtors®, Inc.', value: 'Greater Milwaukee Association Of Realtors®, Inc.' },
            { text: 'Greater Nashville Association Of Realtors® Inc', value: 'Greater Nashville Association Of Realtors® Inc' },
            { text: 'Greater New Milford Board Of Realtors® Inc', value: 'Greater New Milford Board Of Realtors® Inc' },
            { text: 'Greater Newburyport Realtors®', value: 'Greater Newburyport Realtors®' },
            { text: 'Greater Northwest Indiana Association Of Realtors® Inc', value: 'Greater Northwest Indiana Association Of Realtors® Inc' },
            { text: 'Greater Owensboro Realtor® Association', value: 'Greater Owensboro Realtor® Association' },
            { text: 'Greater Philadelphia Association Of Realtors®', value: 'Greater Philadelphia Association Of Realtors®' },
            { text: 'Greater Piedmont Realtors®', value: 'Greater Piedmont Realtors®' },
            { text: 'Greater Pocatello Association Of Realtors® Inc', value: 'Greater Pocatello Association Of Realtors® Inc' },
            { text: 'Greater Portland Board Of Realtors®', value: 'Greater Portland Board Of Realtors®' },
            { text: 'Greater Portsmouth Area Board Of Realtors® Inc', value: 'Greater Portsmouth Area Board Of Realtors® Inc' },
            { text: 'Greater Providence Board Of Realtors®', value: 'Greater Providence Board Of Realtors®' },
            { text: 'Greater Regional Alliance Of Realtors®', value: 'Greater Regional Alliance Of Realtors®' },
            { text: 'Greater Rochester Association Of Realtors® Inc', value: 'Greater Rochester Association Of Realtors® Inc' },
            { text: 'Greater Rome Board Of Realtors® Inc', value: 'Greater Rome Board Of Realtors® Inc' },
            { text: 'Greater San Diego Association Of Realtors®', value: 'Greater San Diego Association Of Realtors®' },
            { text: 'Greater Scranton Board Of Realtors® Inc', value: 'Greater Scranton Board Of Realtors® Inc' },
            { text: 'Greater Shiawassee Association Of Realtors®', value: 'Greater Shiawassee Association Of Realtors®' },
            { text: 'Greater Springfield Board Of Realtors® Inc', value: 'Greater Springfield Board Of Realtors® Inc' },
            { text: 'Greater Syracuse Association Of Realtors® Inc', value: 'Greater Syracuse Association Of Realtors® Inc' },
            { text: 'Greater Tampa Association Of Realtors® Inc', value: 'Greater Tampa Association Of Realtors® Inc' },
            { text: 'Greater Texoma Association Of Realtors® Inc', value: 'Greater Texoma Association Of Realtors® Inc' },
            { text: 'Greater Tulsa Association Of Realtors® Inc', value: 'Greater Tulsa Association Of Realtors® Inc' },
            { text: 'Greater Twin Falls Association Of Realtors® Inc', value: 'Greater Twin Falls Association Of Realtors® Inc' },
            { text: 'Greater Tyler Association Of Realtors® Inc', value: 'Greater Tyler Association Of Realtors® Inc' },
            { text: 'Greeley Area Realtor® Association', value: 'Greeley Area Realtor® Association' },
            { text: 'Green Mountain Association Of Realtors®', value: 'Green Mountain Association Of Realtors®' },
            { text: 'Green Valley/sahuarita Association Of Realtors®', value: 'Green Valley/sahuarita Association Of Realtors®' },
            { text: 'Greenbrier Valley Board Of Realtors® Inc', value: 'Greenbrier Valley Board Of Realtors® Inc' },
            { text: 'Greensboro Regional Realtors® Association Inc', value: 'Greensboro Regional Realtors® Association Inc' },
            { text: 'Greenville Area Board Of Realtors® Inc', value: 'Greenville Area Board Of Realtors® Inc' },
            { text: 'Greenwich Association Of Realtors® Inc', value: 'Greenwich Association Of Realtors® Inc' },
            { text: 'Greenwood Association Of Realtors® Inc', value: 'Greenwood Association Of Realtors® Inc' },
            { text: 'Greenwood Board Of Realtors® Inc', value: 'Greenwood Board Of Realtors® Inc' },
            { text: 'Greers Ferry Lake Area Board Of Realtors®', value: 'Greers Ferry Lake Area Board Of Realtors®' },
            { text: 'Grenada Board Of Realtors®', value: 'Grenada Board Of Realtors®' },
            { text: 'Grosse Pointe Board Of Realtors®', value: 'Grosse Pointe Board Of Realtors®' },
            { text: 'Guernsey-muskingum Valley Association Of Realtors®', value: 'Guernsey-muskingum Valley Association Of Realtors®' },
            { text: 'Gulf Coast Association Of Realtors® Inc', value: 'Gulf Coast Association Of Realtors® Inc' },
            { text: 'Gunnison-crested Butte Association Of Realtors® Inc', value: 'Gunnison-crested Butte Association Of Realtors® Inc' },
            { text: 'Hall County Board Of Realtors®', value: 'Hall County Board Of Realtors®' },
            { text: 'Hampton Roads Realtors® Association Inc', value: 'Hampton Roads Realtors® Association Inc' },
            { text: 'Hamptons North Fork Realtors® Association', value: 'Hamptons North Fork Realtors® Association' },
            { text: 'Harford County Association Of Realtors® Inc', value: 'Harford County Association Of Realtors® Inc' },
            { text: 'Harlingen Board Of Realtors®', value: 'Harlingen Board Of Realtors®' },
            { text: 'Harrison County Association Of Realtors® Inc', value: 'Harrison County Association Of Realtors® Inc' },
            { text: 'Harrison District Board Of Realtors®', value: 'Harrison District Board Of Realtors®' },
            { text: 'Harrisonburg Rockingham Association Of Realtors®', value: 'Harrisonburg Rockingham Association Of Realtors®' },
            { text: 'Hattiesburg Area Association Of Realtors® Inc', value: 'Hattiesburg Area Association Of Realtors® Inc' },
            { text: 'Hawaii Island Realtors®', value: 'Hawaii Island Realtors®' },
            { text: 'Hays Board Of Realtors®', value: 'Hays Board Of Realtors®' },
            { text: 'Heart Of Georgia Board Of Realtors®', value: 'Heart Of Georgia Board Of Realtors®' },
            { text: 'Heart Of Iowa Regional Board Of Realtors®', value: 'Heart Of Iowa Regional Board Of Realtors®' },
            { text: 'Heart Of Missouri Board Of Realtors®', value: 'Heart Of Missouri Board Of Realtors®' },
            { text: 'Heartland Association Of Realtors®', value: 'Heartland Association Of Realtors®' },
            { text: 'Heartland Association Of Realtors®, Inc', value: 'Heartland Association Of Realtors®, Inc' },
            { text: 'Heartland Realtor® Organization', value: 'Heartland Realtor® Organization' },
            { text: 'Helena Association Of Realtors® Inc', value: 'Helena Association Of Realtors® Inc' },
            { text: 'Henderson Audubon Board Of Realtors®', value: 'Henderson Audubon Board Of Realtors®' },
            { text: 'Henderson County Board Of Realtors®', value: 'Henderson County Board Of Realtors®' },
            { text: 'Hendersonville Board Of Realtors®', value: 'Hendersonville Board Of Realtors®' },
            { text: 'Hernando County Association Of Realtors® Inc', value: 'Hernando County Association Of Realtors® Inc' },
            { text: 'High Country Association Of Realtors® Inc.', value: 'High Country Association Of Realtors® Inc.' },
            { text: 'High Desert Association Of Realtors®', value: 'High Desert Association Of Realtors®' },
            { text: 'High Point Regional Association Of Realtors® Inc', value: 'High Point Regional Association Of Realtors® Inc' },
            { text: 'Highland Lakes Association Of Realtors® Inc', value: 'Highland Lakes Association Of Realtors® Inc' },
            { text: 'Highlands Cashiers Board Of Realtors®', value: 'Highlands Cashiers Board Of Realtors®' },
            { text: 'Hillsdale County Board Of Realtors®', value: 'Hillsdale County Board Of Realtors®' },
            { text: 'Hilton Head Area Realtors®', value: 'Hilton Head Area Realtors®' },
            { text: 'Hinesville Area Board Of Realtors® Inc', value: 'Hinesville Area Board Of Realtors® Inc' },
            { text: 'Historic Highlands Association Of Realtors®', value: 'Historic Highlands Association Of Realtors®' },
            { text: 'Hometown Association Of Realtors®', value: 'Hometown Association Of Realtors®' },
            { text: 'Honolulu Board Of Realtors® Inc', value: 'Honolulu Board Of Realtors® Inc' },
            { text: 'Hopkinsville-christian & Todd County Asn Of Realtors® Inc', value: 'Hopkinsville-christian & Todd County Asn Of Realtors® Inc' },
            { text: 'Hot Springs Board Of Realtors®', value: 'Hot Springs Board Of Realtors®' },
            { text: 'Hot Springs Village Board Of Realtors®', value: 'Hot Springs Village Board Of Realtors®' },
            { text: 'Houston Association Of Realtors® Inc', value: 'Houston Association Of Realtors® Inc' },
            { text: 'Howard County Association Of Realtors®', value: 'Howard County Association Of Realtors®' },
            { text: 'Hudson Gateway Association Of Realtors® Inc', value: 'Hudson Gateway Association Of Realtors® Inc' },
            { text: 'Humboldt Association Of Realtors® Inc', value: 'Humboldt Association Of Realtors® Inc' },
            { text: 'Huntingdon County Board Of Realtors®', value: 'Huntingdon County Board Of Realtors®' },
            { text: 'Huntington Board Of Realtors®', value: 'Huntington Board Of Realtors®' },
            { text: 'Huntsville Area Association Of Realtors® Inc', value: 'Huntsville Area Association Of Realtors® Inc' },
            { text: 'Huron Board Of Realtors®', value: 'Huron Board Of Realtors®' },
            { text: 'I-85 North Board Of Realtors® Inc', value: 'I-85 North Board Of Realtors® Inc' },
            { text: 'Idyllwild Association Of Realtors®', value: 'Idyllwild Association Of Realtors®' },
            { text: 'Illini Valley Association Of Realtors® Inc', value: 'Illini Valley Association Of Realtors® Inc' },
            { text: 'Imperial County Association Of Realtors®', value: 'Imperial County Association Of Realtors®' },
            { text: 'Incline Village Realtors®', value: 'Incline Village Realtors®' },
            { text: 'Indiana Commercial Board Of Realtors® Inc', value: 'Indiana Commercial Board Of Realtors® Inc' },
            { text: 'Inglewood Board Of Realtors® Inc', value: 'Inglewood Board Of Realtors® Inc' },
            { text: 'Inland Valleys Association Of Realtors® Inc', value: 'Inland Valleys Association Of Realtors® Inc' },
            { text: 'Iowa City Area Association Of Realtors® Inc', value: 'Iowa City Area Association Of Realtors® Inc' },
            { text: 'Iowa Great Lakes Board Of Realtors®', value: 'Iowa Great Lakes Board Of Realtors®' },
            { text: 'Iron County Board Of Realtors®', value: 'Iron County Board Of Realtors®' },
            { text: 'Itasca County Board Of Realtors®', value: 'Itasca County Board Of Realtors®' },
            { text: 'Ithaca Board Of Realtors® Inc', value: 'Ithaca Board Of Realtors® Inc' },
            { text: 'Jackson Area Association Of Realtors®', value: 'Jackson Area Association Of Realtors®' },
            { text: 'Jacksonville Board Of Realtors® Inc', value: 'Jacksonville Board Of Realtors® Inc' },
            { text: 'Jamestown Board Of Realtors®', value: 'Jamestown Board Of Realtors®' },
            { text: 'Jasper Area Board Of Realtors®', value: 'Jasper Area Board Of Realtors®' },
            { text: 'Jefferson City Area Board Of Realtors®', value: 'Jefferson City Area Board Of Realtors®' },
            { text: 'Jefferson County Association Of Realtors®', value: 'Jefferson County Association Of Realtors®' },
            { text: 'Jefferson County Association Of Realtors®', value: 'Jefferson County Association Of Realtors®' },
            { text: 'Jefferson County Board Of Realtors®', value: 'Jefferson County Board Of Realtors®' },
            { text: 'Jefferson County Board Of Realtors® Inc', value: 'Jefferson County Board Of Realtors® Inc' },
            { text: 'Jefferson Lewis Board Of Realtors® Inc', value: 'Jefferson Lewis Board Of Realtors® Inc' },
            { text: 'Johnson County Association Of Realtors®', value: 'Johnson County Association Of Realtors®' },
            { text: 'Johnson County Board Of Realtors® Inc', value: 'Johnson County Board Of Realtors® Inc' },
            { text: 'Johnston County Association Of Realtors®', value: 'Johnston County Association Of Realtors®' },
            { text: 'Joshua Tree Gateway Association Of Realtors®', value: 'Joshua Tree Gateway Association Of Realtors®' },
            { text: 'Kachemak Board Of Realtors® Inc', value: 'Kachemak Board Of Realtors® Inc' },
            { text: 'Kanawha Valley Board Of Realtors®', value: 'Kanawha Valley Board Of Realtors®' },
            { text: 'Kankakee-iroquois-ford Association Of Realtors® Inc', value: 'Kankakee-iroquois-ford Association Of Realtors® Inc' },
            { text: 'Kansas City Regional Association Of Realtors® Inc', value: 'Kansas City Regional Association Of Realtors® Inc' },
            { text: 'Kauai Board Of Realtors®', value: 'Kauai Board Of Realtors®' },
            { text: 'Kenai Peninsula Association Of Realtors®', value: 'Kenai Peninsula Association Of Realtors®' },
            { text: 'Kennebec Valley Board Of Realtors®', value: 'Kennebec Valley Board Of Realtors®' },
            { text: 'Kent County Association Of Realtors®', value: 'Kent County Association Of Realtors®' },
            { text: 'Kent Washington Association Of Realtors®', value: 'Kent Washington Association Of Realtors®' },
            { text: 'Kentucky Barkley Lakes Board Of Realtors®', value: 'Kentucky Barkley Lakes Board Of Realtors®' },
            { text: 'Kern River Lake Isabella Board Of Realtors®', value: 'Kern River Lake Isabella Board Of Realtors®' },
            { text: 'Kerrville Board Of Realtors®', value: 'Kerrville Board Of Realtors®' },
            { text: 'Kershaw County Board Of Realtors®', value: 'Kershaw County Board Of Realtors®' },
            { text: 'Key West Association Of Realtors® Inc', value: 'Key West Association Of Realtors® Inc' },
            { text: 'Kingman Golden Valley Association Of Realtors® Inc', value: 'Kingman Golden Valley Association Of Realtors® Inc' },
            { text: 'Kings County Board Of Realtors® Inc', value: 'Kings County Board Of Realtors® Inc' },
            { text: 'Kitsap County Association Of Realtors® Inc', value: 'Kitsap County Association Of Realtors® Inc' },
            { text: 'Kittitas County Association Of Realtors®', value: 'Kittitas County Association Of Realtors®' },
            { text: 'Klamath County Association Of Realtors®', value: 'Klamath County Association Of Realtors®' },
            { text: 'Knox County Board Of Realtors®', value: 'Knox County Board Of Realtors®' },
            { text: 'Knoxville Area Association Of Realtors® Inc', value: 'Knoxville Area Association Of Realtors® Inc' },
            { text: 'Kosciusko Board Of Realtors®', value: 'Kosciusko Board Of Realtors®' },
            { text: 'La Crosse Area Realtors® Association', value: 'La Crosse Area Realtors® Association' },
            { text: 'Lafayette Regional Association Of Realtors® Inc', value: 'Lafayette Regional Association Of Realtors® Inc' },
            { text: 'Laguna Board Of Realtors®', value: 'Laguna Board Of Realtors®' },
            { text: 'Lake City Board Of Realtors® Inc', value: 'Lake City Board Of Realtors® Inc' },
            { text: 'Lake Country Board Of Realtors® Inc', value: 'Lake Country Board Of Realtors® Inc' },
            { text: 'Lake County Association Of Realtors®', value: 'Lake County Association Of Realtors®' },
            { text: 'Lake Havasu Association Of Realtors', value: 'Lake Havasu Association Of Realtors' },
            { text: 'Lake Martin Area Association Of Realtors®', value: 'Lake Martin Area Association Of Realtors®' },
            { text: 'Lake Of The Ozarks Board Of Realtors®', value: 'Lake Of The Ozarks Board Of Realtors®' },
            { text: 'Lake Region Association Of Realtors®', value: 'Lake Region Association Of Realtors®' },
            { text: 'Lake Superior Area Realtors® Inc', value: 'Lake Superior Area Realtors® Inc' },
            { text: 'Lake Wales Association Of Realtors® Inc', value: 'Lake Wales Association Of Realtors® Inc' },
            { text: 'Lake & Geauga Area Association Of Realtors®', value: 'Lake & Geauga Area Association Of Realtors®' },
            { text: 'Lakeland Association Of Realtors® Inc', value: 'Lakeland Association Of Realtors® Inc' },
            { text: 'Lakes Area Realtors® Association Inc', value: 'Lakes Area Realtors® Association Inc' },
            { text: 'Lakes Country Association Of Realtors®', value: 'Lakes Country Association Of Realtors®' },
            { text: 'Lakes Region Board Of Realtors® Inc', value: 'Lakes Region Board Of Realtors® Inc' },
            { text: 'Lakeway Area Association Of Realtors® Inc', value: 'Lakeway Area Association Of Realtors® Inc' },
            { text: 'Lamoille Area Board Of Realtors® Inc', value: 'Lamoille Area Board Of Realtors® Inc' },
            { text: 'Lancaster Board Of Realtors® Inc', value: 'Lancaster Board Of Realtors® Inc' },
            { text: 'Lancaster County Association Of Realtors®', value: 'Lancaster County Association Of Realtors®' },
            { text: 'Land Of The Sky Association Of Realtors®', value: 'Land Of The Sky Association Of Realtors®' },
            { text: 'Lapeer & Upper Thumb Association Of Realtors® Inc', value: 'Lapeer & Upper Thumb Association Of Realtors® Inc' },
            { text: 'Laporte County Association Of Realtors®', value: 'Laporte County Association Of Realtors®' },
            { text: 'Laramie Board Of Realtors®', value: 'Laramie Board Of Realtors®' },
            { text: 'Laredo Association Of Realtors®', value: 'Laredo Association Of Realtors®' },
            { text: 'Las Cruces Association Of Realtors® Inc', value: 'Las Cruces Association Of Realtors® Inc' },
            { text: 'Las Vegas Board Of Realtors®', value: 'Las Vegas Board Of Realtors®' },
            { text: 'Lassen Association Of Realtors®', value: 'Lassen Association Of Realtors®' },
            { text: 'Latah County Board Of Realtors®', value: 'Latah County Board Of Realtors®' },
            { text: 'Laurel Board Of Realtors®', value: 'Laurel Board Of Realtors®' },
            { text: 'Lawrence Board Of Realtors®', value: 'Lawrence Board Of Realtors®' },
            { text: 'Lawrence County Board Of Realtors®', value: 'Lawrence County Board Of Realtors®' },
            { text: 'Lawton Board Of Realtors®', value: 'Lawton Board Of Realtors®' },
            { text: 'Lebanon Board Of Realtors®', value: 'Lebanon Board Of Realtors®' },
            { text: 'Lebanon County Association Of Realtors® Inc', value: 'Lebanon County Association Of Realtors® Inc' },
            { text: 'Lee County Association Of Realtors® Inc', value: 'Lee County Association Of Realtors® Inc' },
            { text: 'Lenawee County Association Of Realtors®', value: 'Lenawee County Association Of Realtors®' },
            { text: 'Lewis And Clark Board Of Realtors®', value: 'Lewis And Clark Board Of Realtors®' },
            { text: 'Lewis Clark Association Of Realtors®', value: 'Lewis Clark Association Of Realtors®' },
            { text: 'Lexington-bluegrass Association Of Realtors®', value: 'Lexington-bluegrass Association Of Realtors®' },
            { text: 'Liberty Board Of Realtors® Inc', value: 'Liberty Board Of Realtors® Inc' },
            { text: 'Licking County Board Of Realtors®', value: 'Licking County Board Of Realtors®' },
            { text: 'Lincoln County Board Of Realtors®', value: 'Lincoln County Board Of Realtors®' },
            { text: 'Lincoln County Board Of Realtors®', value: 'Lincoln County Board Of Realtors®' },
            { text: 'Lincoln County Board Of Realtors®', value: 'Lincoln County Board Of Realtors®' },
            { text: 'Little Rock Realtors® Association Inc', value: 'Little Rock Realtors® Association Inc' },
            { text: 'Livingston County Association Of Realtors® Inc', value: 'Livingston County Association Of Realtors® Inc' },
            { text: 'Lodi Association Of Realtors® Inc', value: 'Lodi Association Of Realtors® Inc' },
            { text: 'Logan County Board Of Realtors®', value: 'Logan County Board Of Realtors®' },
            { text: 'Logan County Board Of Realtors®', value: 'Logan County Board Of Realtors®' },
            { text: 'Lompoc Valley Association Of Realtors®', value: 'Lompoc Valley Association Of Realtors®' },
            { text: 'Long Island Board Of Realtors® Inc', value: 'Long Island Board Of Realtors® Inc' },
            { text: 'Longleaf Pine Realtors® Inc', value: 'Longleaf Pine Realtors® Inc' },
            { text: 'Longmont Association Of Realtors®', value: 'Longmont Association Of Realtors®' },
            { text: 'Longview Area Association Of Realtors®', value: 'Longview Area Association Of Realtors®' },
            { text: 'Lorain County Association Of Realtors®', value: 'Lorain County Association Of Realtors®' },
            { text: 'Loveland Berthoud Association Of Realtors® Inc', value: 'Loveland Berthoud Association Of Realtors® Inc' },
            { text: 'Lower Columbia Association Of Realtors® Inc', value: 'Lower Columbia Association Of Realtors® Inc' },
            { text: 'Lower Yakima Valley Association Of Realtors®', value: 'Lower Yakima Valley Association Of Realtors®' },
            { text: 'Lubbock Association Of Realtors® Inc', value: 'Lubbock Association Of Realtors® Inc' },
            { text: 'Lufkin Association Of Realtors® Inc', value: 'Lufkin Association Of Realtors® Inc' },
            { text: 'Luzerne County Association Of Realtors®', value: 'Luzerne County Association Of Realtors®' },
            { text: 'Lynchburg Association Of Realtors® Inc', value: 'Lynchburg Association Of Realtors® Inc' },
            { text: 'Madera County Association Of Realtors®', value: 'Madera County Association Of Realtors®' },
            { text: 'Madisonville Hopkins County Board Of Realtors®', value: 'Madisonville Hopkins County Board Of Realtors®' },
            { text: 'Maine Commercial Association Of Realtors® Inc', value: 'Maine Commercial Association Of Realtors® Inc' },
            { text: 'Mainstreet Organization Of Realtors®', value: 'Mainstreet Organization Of Realtors®' },
            { text: 'Malibu Association Of Realtors® Inc', value: 'Malibu Association Of Realtors® Inc' },
            { text: 'Mammoth Lakes Board Of Realtors® Inc', value: 'Mammoth Lakes Board Of Realtors® Inc' },
            { text: 'Manitowoc County Board Of Realtors® Inc', value: 'Manitowoc County Board Of Realtors® Inc' },
            { text: 'Mansfield Association Of Realtors® Inc', value: 'Mansfield Association Of Realtors® Inc' },
            { text: 'Marathon And Lower Keys Association Of Realtors® Inc', value: 'Marathon And Lower Keys Association Of Realtors® Inc' },
            { text: 'Marco Island Area Association Of Realtors® Inc', value: 'Marco Island Area Association Of Realtors® Inc' },
            { text: 'Marietta Board Of Realtors® Inc', value: 'Marietta Board Of Realtors® Inc' },
            { text: 'Marin Association Of Realtors® Inc', value: 'Marin Association Of Realtors® Inc' },
            { text: 'Marinette County Board Of Realtors® Inc', value: 'Marinette County Board Of Realtors® Inc' },
            { text: 'Marion Board Of Realtors® Inc', value: 'Marion Board Of Realtors® Inc' },
            { text: 'Mariposa County Board Of Realtors®', value: 'Mariposa County Board Of Realtors®' },
            { text: 'Mark Twain Association Of Realtors®', value: 'Mark Twain Association Of Realtors®' },
            { text: 'Marshall County Board Of Realtors® Inc', value: 'Marshall County Board Of Realtors® Inc' },
            { text: 'Martin County Realtors® Of The Treasure Coast', value: 'Martin County Realtors® Of The Treasure Coast' },
            { text: 'Martinsville, Henry & Patrick Counties Assn Of Realtors® Inc', value: 'Martinsville, Henry & Patrick Counties Assn Of Realtors® Inc' },
            { text: 'Mason County Assoc Of Realtors®', value: 'Mason County Assoc Of Realtors®' },
            { text: 'Mason Oceana Manistee Board Of Realtors® Inc', value: 'Mason Oceana Manistee Board Of Realtors® Inc' },
            { text: 'Massanutten Association Of Realtors®', value: 'Massanutten Association Of Realtors®' },
            { text: 'Matagorda County Board Of Realtors®', value: 'Matagorda County Board Of Realtors®' },
            { text: 'Mayfield Graves County Board Of Realtors®', value: 'Mayfield Graves County Board Of Realtors®' },
            { text: 'Mcdowell Board Of Realtors®', value: 'Mcdowell Board Of Realtors®' },
            { text: 'Mckean County Association Of Realtors® Inc', value: 'Mckean County Association Of Realtors® Inc' },
            { text: 'Medina County Board Of Realtors® Inc', value: 'Medina County Board Of Realtors® Inc' },
            { text: 'Memphis Area Association Of Realtors® Inc', value: 'Memphis Area Association Of Realtors® Inc' },
            { text: 'Mena Area Board Of Realtors®', value: 'Mena Area Board Of Realtors®' },
            { text: 'Merced County Association Of Realtors® Inc', value: 'Merced County Association Of Realtors® Inc' },
            { text: 'Mercer-tazewell County Board Of Realtors®', value: 'Mercer-tazewell County Board Of Realtors®' },
            { text: 'Metro Centre Association Of Realtors®', value: 'Metro Centre Association Of Realtors®' },
            { text: 'Metro South Association Of Realtors® Inc', value: 'Metro South Association Of Realtors® Inc' },
            { text: 'Metrotex Association Of Realtors® Inc', value: 'Metrotex Association Of Realtors® Inc' },
            { text: 'Miami Association Of Realtors® Inc', value: 'Miami Association Of Realtors® Inc' },
            { text: 'Mibor Realtor® Association', value: 'Mibor Realtor® Association' },
            { text: 'Mid-eastern Indiana Association Of Realtors® Inc', value: 'Mid-eastern Indiana Association Of Realtors® Inc' },
            { text: 'Mid-fairfield County Association Of Realtors® Inc', value: 'Mid-fairfield County Association Of Realtors® Inc' },
            { text: 'Mid-illinois Realtors® Association', value: 'Mid-illinois Realtors® Association' },
            { text: 'Mid-iowa Regional Board Of Realtors®', value: 'Mid-iowa Regional Board Of Realtors®' },
            { text: 'Mid Carolina Regional Association Of Realtors®', value: 'Mid Carolina Regional Association Of Realtors®' },
            { text: 'Mid Coast Board Of Realtors®', value: 'Mid Coast Board Of Realtors®' },
            { text: 'Mid Columbia Association Of Realtors®', value: 'Mid Columbia Association Of Realtors®' },
            { text: 'Mid Island Association Of Realtors®', value: 'Mid Island Association Of Realtors®' },
            { text: 'Mid Shore Board Of Realtors®', value: 'Mid Shore Board Of Realtors®' },
            { text: 'Mid State Association Of Realtors® Inc', value: 'Mid State Association Of Realtors® Inc' },
            { text: 'Mid Valley Association Of Realtors®', value: 'Mid Valley Association Of Realtors®' },
            { text: 'Mid Valley Association Of Realtors®', value: 'Mid Valley Association Of Realtors®' },
            { text: 'Middle Georgia Association Of Realtors®', value: 'Middle Georgia Association Of Realtors®' },
            { text: 'Middle Tennessee Association Of Realtors® Inc', value: 'Middle Tennessee Association Of Realtors® Inc' },
            { text: 'Midland Board Of Realtors® Inc', value: 'Midland Board Of Realtors® Inc' },
            { text: 'Midwest City Del City Moore Association Of Realtors®', value: 'Midwest City Del City Moore Association Of Realtors®' },
            { text: 'Midwestern Ohio Association Of Realtors® Inc', value: 'Midwestern Ohio Association Of Realtors® Inc' },
            { text: 'Mineral Area Board Of Realtors® Inc', value: 'Mineral Area Board Of Realtors® Inc' },
            { text: 'Mini Cassia Association Of Realtors®', value: 'Mini Cassia Association Of Realtors®' },
            { text: 'Minneapolis Area Association Of Realtors® Inc', value: 'Minneapolis Area Association Of Realtors® Inc' },
            { text: 'Minnesota Commercial Association Of Realtors®', value: 'Minnesota Commercial Association Of Realtors®' },
            { text: 'Minot Board Of Realtors®', value: 'Minot Board Of Realtors®' },
            { text: 'Mississippi Commercial Association Of Realtors®', value: 'Mississippi Commercial Association Of Realtors®' },
            { text: 'Missoula Organization Of Realtors® Inc', value: 'Missoula Organization Of Realtors® Inc' },
            { text: 'Mitchell Board Of Realtors®', value: 'Mitchell Board Of Realtors®' },
            { text: 'Mobile Area Association Of Realtors® Inc', value: 'Mobile Area Association Of Realtors® Inc' },
            { text: 'Mohawk Valley Association Of Realtors®', value: 'Mohawk Valley Association Of Realtors®' },
            { text: 'Monadnock Region Board Of Realtors®', value: 'Monadnock Region Board Of Realtors®' },
            { text: 'Monmouth/ocean Regional Realtors® Inc', value: 'Monmouth/ocean Regional Realtors® Inc' },
            { text: 'Monroe County Board Of Realtors®', value: 'Monroe County Board Of Realtors®' },
            { text: 'Montcalm County Association Of Realtors®', value: 'Montcalm County Association Of Realtors®' },
            { text: 'Montebello District Association Of Realtors® Inc', value: 'Montebello District Association Of Realtors® Inc' },
            { text: 'Monterey County Association Of Realtors®', value: 'Monterey County Association Of Realtors®' },
            { text: 'Montgomery Area Association Of Realtors® Inc', value: 'Montgomery Area Association Of Realtors® Inc' },
            { text: 'Montgomery County Association Of Realtors® Inc', value: 'Montgomery County Association Of Realtors® Inc' },
            { text: 'Montrose Association Of Realtors® Inc', value: 'Montrose Association Of Realtors® Inc' },
            { text: 'Morgan County Association Of Realtors®', value: 'Morgan County Association Of Realtors®' },
            { text: 'Morgantown Board Of Realtors®', value: 'Morgantown Board Of Realtors®' },
            { text: 'Moses Lake Othello Board Of Realtors®', value: 'Moses Lake Othello Board Of Realtors®' },
            { text: 'Moultrie Board Of Realtors®', value: 'Moultrie Board Of Realtors®' },
            { text: 'Mount Rushmore Area Association Of Realtors®', value: 'Mount Rushmore Area Association Of Realtors®' },
            { text: 'Mountain Central Association Of Realtors®', value: 'Mountain Central Association Of Realtors®' },
            { text: 'Mountain Lakes Board Of Realtors®', value: 'Mountain Lakes Board Of Realtors®' },
            { text: 'Mountain Metro Association Of Realtors®', value: 'Mountain Metro Association Of Realtors®' },
            { text: 'Mountains To Shore Board Of Realtors®', value: 'Mountains To Shore Board Of Realtors®' },
            { text: 'Murray Calloway County Board Of Realtors® Inc', value: 'Murray Calloway County Board Of Realtors® Inc' },
            { text: 'Muscatine Board Of Realtors®', value: 'Muscatine Board Of Realtors®' },
            { text: 'Nacogdoches County Board Of Realtors®', value: 'Nacogdoches County Board Of Realtors®' },
            { text: 'Nampa Association Of Realtors®', value: 'Nampa Association Of Realtors®' },
            { text: 'Naples Area Board Of Realtors®', value: 'Naples Area Board Of Realtors®' },
            { text: 'Natchez Board Of Realtors® Inc', value: 'Natchez Board Of Realtors® Inc' },
            { text: 'Navarre Area Board Of Realtors® Inc', value: 'Navarre Area Board Of Realtors® Inc' },
            { text: 'Navarro County Board Of Realtors® Inc', value: 'Navarro County Board Of Realtors® Inc' },
            { text: 'Nemaha Valley Board Of Realtors®', value: 'Nemaha Valley Board Of Realtors®' },
            { text: 'Neuse River Region Association Of Realtors®', value: 'Neuse River Region Association Of Realtors®' },
            { text: 'Nevada County Association Of Realtors®', value: 'Nevada County Association Of Realtors®' },
            { text: 'New Canaan Board Of Realtors® Inc', value: 'New Canaan Board Of Realtors® Inc' },
            { text: 'New Castle County Board Of Realtors®', value: 'New Castle County Board Of Realtors®' },
            { text: 'New Hampshire Commercial Investment Bd Of Realtors® Inc', value: 'New Hampshire Commercial Investment Bd Of Realtors® Inc' },
            { text: 'New Haven Middlesex Association Of Realtors® Inc', value: 'New Haven Middlesex Association Of Realtors® Inc' },
            { text: 'New Orleans Metropolitan Association Of Realtors® Inc', value: 'New Orleans Metropolitan Association Of Realtors® Inc' },
            { text: 'New River Valley Association Of Realtors® Inc', value: 'New River Valley Association Of Realtors® Inc' },
            { text: 'New Smyrna Beach Board Of Realtors® Inc', value: 'New Smyrna Beach Board Of Realtors® Inc' },
            { text: 'New York State Commercial Association Of Realtors® Inc', value: 'New York State Commercial Association Of Realtors® Inc' },
            { text: 'Newnan Coweta Board Of Realtors® Inc', value: 'Newnan Coweta Board Of Realtors® Inc' },
            { text: 'Newport Beach Association Of Realtors® Inc', value: 'Newport Beach Association Of Realtors® Inc' },
            { text: 'Newport County Board Of Realtors®', value: 'Newport County Board Of Realtors®' },
            { text: 'Newton Mc Donald Counties Board Of Realtors® Inc', value: 'Newton Mc Donald Counties Board Of Realtors® Inc' },
            { text: 'Newtown Board Of Realtors® Inc', value: 'Newtown Board Of Realtors® Inc' },
            { text: 'Nexus Association Of Realtors®', value: 'Nexus Association Of Realtors®' },
            { text: 'Nolan County Board Of Realtors® Inc', value: 'Nolan County Board Of Realtors® Inc' },
            { text: 'Norfolk Board Of Realtors®', value: 'Norfolk Board Of Realtors®' },
            { text: 'Norman Board Of Realtors®', value: 'Norman Board Of Realtors®' },
            { text: 'North Bay Association Of Realtors®', value: 'North Bay Association Of Realtors®' },
            { text: 'North Central Board Of Realtors®', value: 'North Central Board Of Realtors®' },
            { text: 'North Central Board Of Realtors®', value: 'North Central Board Of Realtors®' },
            { text: 'North Central Indiana Association Of Realtors® Inc', value: 'North Central Indiana Association Of Realtors® Inc' },
            { text: 'North Central Iowa Regional Board Of Realtors®', value: 'North Central Iowa Regional Board Of Realtors®' },
            { text: 'North Central Jersey Association Of Realtors® Inc', value: 'North Central Jersey Association Of Realtors® Inc' },
            { text: 'North Central Massachusetts Association Of Realtors® Inc', value: 'North Central Massachusetts Association Of Realtors® Inc' },
            { text: 'North Central Mississippi Realtors®', value: 'North Central Mississippi Realtors®' },
            { text: 'North Central Penn Board Of Realtors®', value: 'North Central Penn Board Of Realtors®' },
            { text: 'North Central Washington Association Of Realtors®', value: 'North Central Washington Association Of Realtors®' },
            { text: 'North Country Board Of Realtors®', value: 'North Country Board Of Realtors®' },
            { text: 'North Oakland County Board Of Realtors® Inc', value: 'North Oakland County Board Of Realtors® Inc' },
            { text: 'North Platte Valley Board Of Realtors®', value: 'North Platte Valley Board Of Realtors®' },
            { text: 'North Puget Sound Association Of Realtors® Inc', value: 'North Puget Sound Association Of Realtors® Inc' },
            { text: 'North Pulaski Board Of Realtors®', value: 'North Pulaski Board Of Realtors®' },
            { text: 'North San Diego County Association Of Realtors®', value: 'North San Diego County Association Of Realtors®' },
            { text: 'North San Luis Obispo County Association Of Realtors®', value: 'North San Luis Obispo County Association Of Realtors®' },
            { text: 'North Shore-barrington Association Of Realtors®', value: 'North Shore-barrington Association Of Realtors®' },
            { text: 'North Shore Association Of Realtors® Inc', value: 'North Shore Association Of Realtors® Inc' },
            { text: 'North Texas Commercial Association Of Realtors®', value: 'North Texas Commercial Association Of Realtors®' },
            { text: 'Northeast Arkansas Board Of Realtors®', value: 'Northeast Arkansas Board Of Realtors®' },
            { text: 'Northeast Atlanta Metro Association Of Realtors Inc', value: 'Northeast Atlanta Metro Association Of Realtors Inc' },
            { text: 'Northeast Central Association Of Realtors®', value: 'Northeast Central Association Of Realtors®' },
            { text: 'Northeast Florida Association Of Realtors® Inc', value: 'Northeast Florida Association Of Realtors® Inc' },
            { text: 'Northeast Georgia Board Of Realtors® Inc', value: 'Northeast Georgia Board Of Realtors® Inc' },
            { text: 'Northeast Iowa Regional Board Of Realtors®', value: 'Northeast Iowa Regional Board Of Realtors®' },
            { text: 'Northeast Louisiana Association Of Realtors®', value: 'Northeast Louisiana Association Of Realtors®' },
            { text: 'Northeast Mississippi Board Of Realtors®', value: 'Northeast Mississippi Board Of Realtors®' },
            { text: 'Northeast Oklahoma Board Of Realtors®', value: 'Northeast Oklahoma Board Of Realtors®' },
            { text: 'Northeast Oregon Board Of Realtors®', value: 'Northeast Oregon Board Of Realtors®' },
            { text: 'Northeast South Dakota Association Of Realtors®', value: 'Northeast South Dakota Association Of Realtors®' },
            { text: 'Northeast Tennessee Association Of Realtors® Inc', value: 'Northeast Tennessee Association Of Realtors® Inc' },
            { text: 'Northeast Washington Association Of Realtors®', value: 'Northeast Washington Association Of Realtors®' },
            { text: 'Northeast Wyoming Realtor® Alliance', value: 'Northeast Wyoming Realtor® Alliance' },
            { text: 'Northeastern Indiana Association Of Realtors® Inc', value: 'Northeastern Indiana Association Of Realtors® Inc' },
            { text: 'Northeastern Michigan Board Of Realtors® Inc', value: 'Northeastern Michigan Board Of Realtors® Inc' },
            { text: 'Northern Adirondack Board Of Realtors® Inc', value: 'Northern Adirondack Board Of Realtors® Inc' },
            { text: 'Northern Arizona Association Of Realtors®', value: 'Northern Arizona Association Of Realtors®' },
            { text: 'Northern Colorado Commercial Association Of Realtors®', value: 'Northern Colorado Commercial Association Of Realtors®' },
            { text: 'Northern Fairfield County Association Of Realtors® Inc', value: 'Northern Fairfield County Association Of Realtors® Inc' },
            { text: 'Northern Illinois Commercial Assoc Of Realtors', value: 'Northern Illinois Commercial Assoc Of Realtors' },
            { text: 'Northern Kentucky Association Of Realtors®', value: 'Northern Kentucky Association Of Realtors®' },
            { text: 'Northern Neck Association Of Realtors®', value: 'Northern Neck Association Of Realtors®' },
            { text: 'Northern Rhode Island Board Of Realtors', value: 'Northern Rhode Island Board Of Realtors' },
            { text: 'Northern Solano County Association Of Realtors®', value: 'Northern Solano County Association Of Realtors®' },
            { text: 'Northern Virginia Association Of Realtors®', value: 'Northern Virginia Association Of Realtors®' },
            { text: 'Northern Wasatch Association Of Realtors®', value: 'Northern Wasatch Association Of Realtors®' },
            { text: 'Northshore Area Board Of Realtors®', value: 'Northshore Area Board Of Realtors®' },
            { text: 'Northwest Arkansas Board Of Realtors®', value: 'Northwest Arkansas Board Of Realtors®' },
            { text: 'Northwest Iowa Regional Board Of Realtors®', value: 'Northwest Iowa Regional Board Of Realtors®' },
            { text: 'Northwest Louisiana Association Of Realtors® Inc', value: 'Northwest Louisiana Association Of Realtors® Inc' },
            { text: 'Northwest Metro Association Of Realtors®', value: 'Northwest Metro Association Of Realtors®' },
            { text: 'Northwest Minnesota Association Of Realtors® Inc', value: 'Northwest Minnesota Association Of Realtors® Inc' },
            { text: 'Northwest Mississippi Association Of Realtors®', value: 'Northwest Mississippi Association Of Realtors®' },
            { text: 'Northwest Montana Association Of Realtors®', value: 'Northwest Montana Association Of Realtors®' },
            { text: 'Northwest Ohio Realtors®', value: 'Northwest Ohio Realtors®' },
            { text: 'Northwest Oklahoma Association Of Realtors® Inc', value: 'Northwest Oklahoma Association Of Realtors® Inc' },
            { text: 'Northwest Wyoming Board Of Realtors®', value: 'Northwest Wyoming Board Of Realtors®' },
            { text: 'Northwestern Vermont Board Of Realtors® Inc', value: 'Northwestern Vermont Board Of Realtors® Inc' },
            { text: 'Northwoods Assoc Of Realtors® Inc', value: 'Northwoods Assoc Of Realtors® Inc' },
            { text: 'Oak Park Area Association Of Realtors®', value: 'Oak Park Area Association Of Realtors®' },
            { text: 'Ocala Marion County Association Of Realtors® Inc', value: 'Ocala Marion County Association Of Realtors® Inc' },
            { text: 'Ocean City Board Of Realtors®', value: 'Ocean City Board Of Realtors®' },
            { text: 'Odessa Board Of Realtors® Inc', value: 'Odessa Board Of Realtors® Inc' },
            { text: 'Ojai Valley Board Of Realtors®', value: 'Ojai Valley Board Of Realtors®' },
            { text: 'Okeechobee County Board Of Realtors® Inc', value: 'Okeechobee County Board Of Realtors® Inc' },
            { text: 'Oklahoma City Metropolitan Association Of Realtors® Inc', value: 'Oklahoma City Metropolitan Association Of Realtors® Inc' },
            { text: 'Old Kentucky Home Board Of Realtors®', value: 'Old Kentucky Home Board Of Realtors®' },
            { text: 'Omaha Area Board Of Realtors®', value: 'Omaha Area Board Of Realtors®' },
            { text: 'Orange Chatham Association Of Realtors® Inc', value: 'Orange Chatham Association Of Realtors® Inc' },
            { text: 'Orange County Realtors®', value: 'Orange County Realtors®' },
            { text: 'Orlando Regional Realtor® Association', value: 'Orlando Regional Realtor® Association' },
            { text: 'Orleans County Board Of Realtors®', value: 'Orleans County Board Of Realtors®' },
            { text: 'Oroville Association Of Realtors®', value: 'Oroville Association Of Realtors®' },
            { text: 'Osceola County Association Of Realtors® Inc', value: 'Osceola County Association Of Realtors® Inc' },
            { text: 'Otero County Association Of Realtors®', value: 'Otero County Association Of Realtors®' },
            { text: 'Otsego Delaware Board Of Realtors® Inc', value: 'Otsego Delaware Board Of Realtors® Inc' },
            { text: 'Outer Banks Association Of Realtors® Inc', value: 'Outer Banks Association Of Realtors® Inc' },
            { text: 'Ozark Gateway Association Of Realtors®', value: 'Ozark Gateway Association Of Realtors®' },
            { text: 'Ozark Trail Board Of Realtors®', value: 'Ozark Trail Board Of Realtors®' },
            { text: 'Ozarks Board Of Realtors®', value: 'Ozarks Board Of Realtors®' },
            { text: 'Ozaukee Realtors® Association', value: 'Ozaukee Realtors® Association' },
            { text: 'Pacific Southwest Association Of Realtors® Inc', value: 'Pacific Southwest Association Of Realtors® Inc' },
            { text: 'Pacific West Association Of Realtors®', value: 'Pacific West Association Of Realtors®' },
            { text: 'Paducah Board Of Realtors® Inc', value: 'Paducah Board Of Realtors® Inc' },
            { text: 'Pagosa Springs Area Association Of Realtors®', value: 'Pagosa Springs Area Association Of Realtors®' },
            { text: 'Palestine Association Of Realtors® Inc', value: 'Palestine Association Of Realtors® Inc' },
            { text: 'Palm Beach Board Of Realtors®', value: 'Palm Beach Board Of Realtors®' },
            { text: 'Palm Springs Regional Association Of Realtors® Inc', value: 'Palm Springs Regional Association Of Realtors® Inc' },
            { text: 'Palos Verdes Peninsula Association Of Realtors®', value: 'Palos Verdes Peninsula Association Of Realtors®' },
            { text: 'Pampa Board Of Realtors® Inc', value: 'Pampa Board Of Realtors® Inc' },
            { text: 'Paradise Association Of Realtors®', value: 'Paradise Association Of Realtors®' },
            { text: 'Paragould Board Of Realtors®', value: 'Paragould Board Of Realtors®' },
            { text: 'Paris Board Of Realtors®', value: 'Paris Board Of Realtors®' },
            { text: 'Park City Board Of Realtors® Inc', value: 'Park City Board Of Realtors® Inc' },
            { text: 'Park County Board Of Realtors®', value: 'Park County Board Of Realtors®' },
            { text: 'Parkersburg Area Association Of Realtors®', value: 'Parkersburg Area Association Of Realtors®' },
            { text: 'Pasadena-foothills Association Of Realtors®', value: 'Pasadena-foothills Association Of Realtors®' },
            { text: 'Paul Bunyan Board Of Realtors®', value: 'Paul Bunyan Board Of Realtors®' },
            { text: 'Paulding Board Of Realtors®', value: 'Paulding Board Of Realtors®' },
            { text: 'Payette Washington County Board Of Realtors®', value: 'Payette Washington County Board Of Realtors®' },
            { text: 'Pearl River County Board Of Realtors® Inc', value: 'Pearl River County Board Of Realtors® Inc' },
            { text: 'Pen-mar Realtors®', value: 'Pen-mar Realtors®' },
            { text: 'Pennyrile Board Of Realtors®', value: 'Pennyrile Board Of Realtors®' },
            { text: 'Pensacola Association Of Realtors® Inc', value: 'Pensacola Association Of Realtors® Inc' },
            { text: 'Peoria Area Association Of Realtors®', value: 'Peoria Area Association Of Realtors®' },
            { text: 'Permian Basin Board Of Realtors® Inc', value: 'Permian Basin Board Of Realtors® Inc' },
            { text: 'Phoenix Realtors®', value: 'Phoenix Realtors®' },
            { text: 'Pickens County Board Of Realtors® Inc', value: 'Pickens County Board Of Realtors® Inc' },
            { text: 'Piedmont Regional Association Of Realtors® Inc', value: 'Piedmont Regional Association Of Realtors® Inc' },
            { text: 'Pike Wayne Association Of Realtors®', value: 'Pike Wayne Association Of Realtors®' },
            { text: 'Pikes Peak Association Of Realtors® Inc', value: 'Pikes Peak Association Of Realtors® Inc' },
            { text: 'Pinellas Suncoast Association Of Realtors® Inc', value: 'Pinellas Suncoast Association Of Realtors® Inc' },
            { text: 'Pismo Coast Association Of Realtors® Inc', value: 'Pismo Coast Association Of Realtors® Inc' },
            { text: 'Pittsburg Board Of Realtors®', value: 'Pittsburg Board Of Realtors®' },
            { text: 'Placer County Association Of Realtors®', value: 'Placer County Association Of Realtors®' },
            { text: 'Plainview Association Of Realtors®', value: 'Plainview Association Of Realtors®' },
            { text: 'Plumas Association Of Realtors®', value: 'Plumas Association Of Realtors®' },
            { text: 'Pocono Mountains Association Of Realtors® Inc', value: 'Pocono Mountains Association Of Realtors® Inc' },
            { text: 'Polk County Board Of Realtors®', value: 'Polk County Board Of Realtors®' },
            { text: 'Port Angeles Association Of Realtors®', value: 'Port Angeles Association Of Realtors®' },
            { text: 'Port Neches Port Arthur And Nederland Board Of Realtors®', value: 'Port Neches Port Arthur And Nederland Board Of Realtors®' },
            { text: 'Portland Metropolitan Association Of Realtors®', value: 'Portland Metropolitan Association Of Realtors®' },
            { text: 'Potomac Highlands Board Of Realtors® Inc', value: 'Potomac Highlands Board Of Realtors® Inc' },
            { text: 'Prairie Land Realtors®', value: 'Prairie Land Realtors®' },
            { text: 'Prescott Area Association Of Realtors® Inc', value: 'Prescott Area Association Of Realtors® Inc' },
            { text: 'Prince Georges County Association Of Realtors® Inc', value: 'Prince Georges County Association Of Realtors® Inc' },
            { text: 'Prince William Association Of Realtors® Inc', value: 'Prince William Association Of Realtors® Inc' },
            { text: 'Pueblo Association Of Realtors® Inc', value: 'Pueblo Association Of Realtors® Inc' },
            { text: 'Pulaski County Board Of Realtors®', value: 'Pulaski County Board Of Realtors®' },
            { text: 'Putnam County Board Of Realtors®', value: 'Putnam County Board Of Realtors®' },
            { text: 'Quad City Area Realtors®', value: 'Quad City Area Realtors®' },
            { text: 'Quincy Association Of Realtors® Inc', value: 'Quincy Association Of Realtors® Inc' },
            { text: 'Raleigh Regional Association Of Realtors®', value: 'Raleigh Regional Association Of Realtors®' },
            { text: 'Rancho Southeast Association Of Realtors®', value: 'Rancho Southeast Association Of Realtors®' },
            { text: 'Randolph County Board Of Realtors®', value: 'Randolph County Board Of Realtors®' },
            { text: 'Range Association Of Realtors®', value: 'Range Association Of Realtors®' },
            { text: 'Reading-berks Association Of Realtors® Inc', value: 'Reading-berks Association Of Realtors® Inc' },
            { text: 'Realtors® Association Of Central Indiana', value: 'Realtors® Association Of Central Indiana' },
            { text: 'Realtors® Association Of Citrus County Inc', value: 'Realtors® Association Of Citrus County Inc' },
            { text: 'Realtors® Association Of Franklin & Gulf Counties', value: 'Realtors® Association Of Franklin & Gulf Counties' },
            { text: 'Realtors® Association Of Indian River County', value: 'Realtors® Association Of Indian River County' },
            { text: 'Realtors® Association Of Lake & Sumter Counties Inc', value: 'Realtors® Association Of Lake & Sumter Counties Inc' },
            { text: 'Realtors® Association Of Lincoln', value: 'Realtors® Association Of Lincoln' },
            { text: 'Realtors® Association Of Maui, Inc.', value: 'Realtors® Association Of Maui, Inc.' },
            { text: 'Realtors® Association Of Metropolitan Pittsburgh', value: 'Realtors® Association Of Metropolitan Pittsburgh' },
            { text: 'Realtors® Association Of Northeast Wisconsin Inc', value: 'Realtors® Association Of Northeast Wisconsin Inc' },
            { text: 'Realtors® Association Of Northwestern Wisconsin Inc', value: 'Realtors® Association Of Northwestern Wisconsin Inc' },
            { text: 'Realtors® Association Of South Central Wisconsin', value: 'Realtors® Association Of South Central Wisconsin' },
            { text: 'Realtors® Association Of Southeastern Massachusetts', value: 'Realtors® Association Of Southeastern Massachusetts' },
            { text: 'Realtors® Association Of Westmoreland Indiana & Mon Valley', value: 'Realtors® Association Of Westmoreland Indiana & Mon Valley' },
            { text: 'Realtors® Association Of York & Adams Counties', value: 'Realtors® Association Of York & Adams Counties' },
            { text: 'Realtors® Commercial Alliance Of Massachusetts Inc', value: 'Realtors® Commercial Alliance Of Massachusetts Inc' },
            { text: 'Realtors® Commercial Alliance Of Orange County', value: 'Realtors® Commercial Alliance Of Orange County' },
            { text: 'Realtors® Of Central Colorado Inc', value: 'Realtors® Of Central Colorado Inc' },
            { text: 'Realtors® Of Greater Mid-nebraska', value: 'Realtors® Of Greater Mid-nebraska' },
            { text: 'Realtors® Of Punta Gorda Port Charlotte North Port Desoto', value: 'Realtors® Of Punta Gorda Port Charlotte North Port Desoto' },
            { text: 'Realtors® Of South Central Kansas Inc', value: 'Realtors® Of South Central Kansas Inc' },
            { text: 'Realtor® Association Of Acadiana', value: 'Realtor® Association Of Acadiana' },
            { text: 'Realtor® Association Of Central Massachusetts Inc', value: 'Realtor® Association Of Central Massachusetts Inc' },
            { text: 'Realtor® Association Of Northwestern Illinois', value: 'Realtor® Association Of Northwestern Illinois' },
            { text: 'Realtor® Association Of Pioneer Valley, Inc', value: 'Realtor® Association Of Pioneer Valley, Inc' },
            { text: 'Realtor® Association Of Sarasota & Manatee', value: 'Realtor® Association Of Sarasota & Manatee' },
            { text: 'Realtor® Association Of Southern Kentucky Inc', value: 'Realtor® Association Of Southern Kentucky Inc' },
            { text: 'Realtor® Association Of Southern Minnesota', value: 'Realtor® Association Of Southern Minnesota' },
            { text: 'Realtor® Association Of Southwestern Illinois Inc', value: 'Realtor® Association Of Southwestern Illinois Inc' },
            { text: 'Realtor® Association Of The Fox Valley, Inc', value: 'Realtor® Association Of The Fox Valley, Inc' },
            { text: 'Realtor® Association Of The Greater Pee Dee, Inc.', value: 'Realtor® Association Of The Greater Pee Dee, Inc.' },
            { text: 'Realtor® Association Of The Sioux Empire, Inc', value: 'Realtor® Association Of The Sioux Empire, Inc' },
            { text: 'Reelfoot Regional Association Of Realtors® Inc', value: 'Reelfoot Regional Association Of Realtors® Inc' },
            { text: 'Reno Sparks Association Of Realtors® Inc', value: 'Reno Sparks Association Of Realtors® Inc' },
            { text: 'Rhode Island Commercial And Appraisal Board Of Realtors®', value: 'Rhode Island Commercial And Appraisal Board Of Realtors®' },
            { text: 'Richmond Association Of Realtors®', value: 'Richmond Association Of Realtors®' },
            { text: 'Richmond Association Of Realtors® Inc', value: 'Richmond Association Of Realtors® Inc' },
            { text: 'Ridgecrest Area Association Of Realtors® Inc', value: 'Ridgecrest Area Association Of Realtors® Inc' },
            { text: 'Rim O The World Association Of Realtors®', value: 'Rim O The World Association Of Realtors®' },
            { text: 'River Counties Association Of Realtors® Inc', value: 'River Counties Association Of Realtors® Inc' },
            { text: 'Roanoke Valley Association Of Realtors® Inc', value: 'Roanoke Valley Association Of Realtors® Inc' },
            { text: 'Roanoke Valley Lake Gaston Board Of Realtors®', value: 'Roanoke Valley Lake Gaston Board Of Realtors®' },
            { text: 'Robertson County Association Of Realtors® Inc', value: 'Robertson County Association Of Realtors® Inc' },
            { text: 'Rock Green Realtors® Association Inc', value: 'Rock Green Realtors® Association Inc' },
            { text: 'Rockbridge Highlands Realtors®', value: 'Rockbridge Highlands Realtors®' },
            { text: 'Rockford Area Association Of Realtors®', value: 'Rockford Area Association Of Realtors®' },
            { text: 'Rockport Area Association Of Realtors®', value: 'Rockport Area Association Of Realtors®' },
            { text: 'Rocky Mount Area Association Of Realtors® Inc', value: 'Rocky Mount Area Association Of Realtors® Inc' },
            { text: 'Rocky Mountain Association Of Realtors® Inc', value: 'Rocky Mountain Association Of Realtors® Inc' },
            { text: 'Rogue Valley Association Of Realtors®', value: 'Rogue Valley Association Of Realtors®' },
            { text: 'Roswell Board Of Realtors®', value: 'Roswell Board Of Realtors®' },
            { text: 'Royal Gorge Association Of Realtors® Inc', value: 'Royal Gorge Association Of Realtors® Inc' },
            { text: 'Royal Palm Coast Realtor® Association', value: 'Royal Palm Coast Realtor® Association' },
            { text: 'Ruidoso/lincoln County Association Of Realtors®', value: 'Ruidoso/lincoln County Association Of Realtors®' },
            { text: 'Russellville Board Of Realtors®', value: 'Russellville Board Of Realtors®' },
            { text: 'Rutherford County Board Of Realtors®', value: 'Rutherford County Board Of Realtors®' },
            { text: 'Sacramento Association Of Realtors® Inc', value: 'Sacramento Association Of Realtors® Inc' },
            { text: 'Saginaw Board Of Realtors®', value: 'Saginaw Board Of Realtors®' },
            { text: 'Salisbury/rowan Association Of Realtors® Inc', value: 'Salisbury/rowan Association Of Realtors® Inc' },
            { text: 'Salt Lake Board Of Realtors®', value: 'Salt Lake Board Of Realtors®' },
            { text: 'San Angelo Association Of Realtors®', value: 'San Angelo Association Of Realtors®' },
            { text: 'San Antonio Board Of Realtors® Inc', value: 'San Antonio Board Of Realtors® Inc' },
            { text: 'San Benito County Association Of Realtors®', value: 'San Benito County Association Of Realtors®' },
            { text: 'San Francisco Association Of Realtors®', value: 'San Francisco Association Of Realtors®' },
            { text: 'San Juan Board Of Realtors® Inc', value: 'San Juan Board Of Realtors® Inc' },
            { text: 'San Juan County Board Of Realtors® Inc', value: 'San Juan County Board Of Realtors® Inc' },
            { text: 'San Luis Obispo Association Of Realtors® Inc', value: 'San Luis Obispo Association Of Realtors® Inc' },
            { text: 'San Mateo County Association Of Realtors®', value: 'San Mateo County Association Of Realtors®' },
            { text: 'San Patricio County Association Of Realtors® Inc', value: 'San Patricio County Association Of Realtors® Inc' },
            { text: 'Sanibel And Captiva Islands Association Of Realtors® Inc', value: 'Sanibel And Captiva Islands Association Of Realtors® Inc' },
            { text: 'Santa Barbara Association Of Realtors®', value: 'Santa Barbara Association Of Realtors®' },
            { text: 'Santa Clara County Association Of Realtors®', value: 'Santa Clara County Association Of Realtors®' },
            { text: 'Santa Cruz County Association Of Realtors® Inc', value: 'Santa Cruz County Association Of Realtors® Inc' },
            { text: 'Santa Cruz County Board Of Realtors®', value: 'Santa Cruz County Board Of Realtors®' },
            { text: 'Santa Fe Association Of Realtors® Inc', value: 'Santa Fe Association Of Realtors® Inc' },
            { text: 'Santa Maria Association Of Realtors®', value: 'Santa Maria Association Of Realtors®' },
            { text: 'Santa Ynez Valley Association Of Realtors®', value: 'Santa Ynez Valley Association Of Realtors®' },
            { text: 'Savannah Board Of Realtors®', value: 'Savannah Board Of Realtors®' },
            { text: 'Scenic Coast Association Of Realtors® Inc', value: 'Scenic Coast Association Of Realtors® Inc' },
            { text: 'Schuylkill County Board Of Realtors® Inc', value: 'Schuylkill County Board Of Realtors® Inc' },
            { text: 'Scioto Valley Realtors®', value: 'Scioto Valley Realtors®' },
            { text: 'Scottsdale Area Association Of Realtors® Inc', value: 'Scottsdale Area Association Of Realtors® Inc' },
            { text: 'Seacoast Board Of Realtors® Inc', value: 'Seacoast Board Of Realtors® Inc' },
            { text: 'Searcy Board Of Realtors® Inc', value: 'Searcy Board Of Realtors® Inc' },
            { text: 'Seattle King County Association Of Realtors® Inc', value: 'Seattle King County Association Of Realtors® Inc' },
            { text: 'Sedona Verde Valley Association Of Realtors® Inc', value: 'Sedona Verde Valley Association Of Realtors® Inc' },
            { text: 'Selkirk Association Of Realtors® Inc', value: 'Selkirk Association Of Realtors® Inc' },
            { text: 'Sequim Association Of Realtors® Inc', value: 'Sequim Association Of Realtors® Inc' },
            { text: 'Shasta Association Of Realtors®', value: 'Shasta Association Of Realtors®' },
            { text: 'Shawnee Board Of Realtors®', value: 'Shawnee Board Of Realtors®' },
            { text: 'Sheboygan County Board Of Realtors® Inc', value: 'Sheboygan County Board Of Realtors® Inc' },
            { text: 'Sheridan County Board Of Realtors®', value: 'Sheridan County Board Of Realtors®' },
            { text: 'Shoals Area Association Of Realtors®', value: 'Shoals Area Association Of Realtors®' },
            { text: 'Sierra County Board Of Realtors® Inc', value: 'Sierra County Board Of Realtors® Inc' },
            { text: 'Sierra Nevada Realtors®', value: 'Sierra Nevada Realtors®' },
            { text: 'Sierra North Valley Realtors®', value: 'Sierra North Valley Realtors®' },
            { text: 'Silicon Valley Association Of Realtors®', value: 'Silicon Valley Association Of Realtors®' },
            { text: 'Silver City Regional Association Of Realtors® Inc', value: 'Silver City Regional Association Of Realtors® Inc' },
            { text: 'Siskiyou Association Of Realtors® Inc', value: 'Siskiyou Association Of Realtors® Inc' },
            { text: 'Snohomish County Camano Association Of Realtors® Inc', value: 'Snohomish County Camano Association Of Realtors® Inc' },
            { text: 'Solano Association Of Realtors® Inc', value: 'Solano Association Of Realtors® Inc' },
            { text: 'Somerset-lake Cumberland Association Of Realtors®', value: 'Somerset-lake Cumberland Association Of Realtors®' },
            { text: 'South Bay Association Of Realtors®', value: 'South Bay Association Of Realtors®' },
            { text: 'South Bend Area Realtors®', value: 'South Bend Area Realtors®' },
            { text: 'South Central Arkansas Realtors® Association', value: 'South Central Arkansas Realtors® Association' },
            { text: 'South Central Association Of Realtors®', value: 'South Central Association Of Realtors®' },
            { text: 'South Central Board Of Realtors® Inc', value: 'South Central Board Of Realtors® Inc' },
            { text: 'South Central Board Of Realtors®, Inc.', value: 'South Central Board Of Realtors®, Inc.' },
            { text: 'South Central Kentucky Association Of Realtors® Inc', value: 'South Central Kentucky Association Of Realtors® Inc' },
            { text: 'South Central Vermont Board Of Realtors®', value: 'South Central Vermont Board Of Realtors®' },
            { text: 'South Metro Denver Realtor® Association Inc', value: 'South Metro Denver Realtor® Association Inc' },
            { text: 'South Padre Island Board Of Realtors®', value: 'South Padre Island Board Of Realtors®' },
            { text: 'South Shore Realtors®', value: 'South Shore Realtors®' },
            { text: 'South Tahoe Association Of Realtors® Inc', value: 'South Tahoe Association Of Realtors® Inc' },
            { text: 'South Texas Commercial Association Of Realtors®', value: 'South Texas Commercial Association Of Realtors®' },
            { text: 'Southeast Alabama Association Of Realtors® Inc', value: 'Southeast Alabama Association Of Realtors® Inc' },
            { text: 'Southeast Alaska Board Of Realtors®', value: 'Southeast Alaska Board Of Realtors®' },
            { text: 'Southeast Arkansas Board Of Realtors® Inc', value: 'Southeast Arkansas Board Of Realtors® Inc' },
            { text: 'Southeast Iowa Regional Board Of Realtors® Inc', value: 'Southeast Iowa Regional Board Of Realtors® Inc' },
            { text: 'Southeast Kansas Association Of Realtors® Inc', value: 'Southeast Kansas Association Of Realtors® Inc' },
            { text: 'Southeast Minnesota Realtors®', value: 'Southeast Minnesota Realtors®' },
            { text: 'Southeast Missouri Realtors®', value: 'Southeast Missouri Realtors®' },
            { text: 'Southeastern Border Association Of Realtors®', value: 'Southeastern Border Association Of Realtors®' },
            { text: 'Southeastern Indiana Board Of Realtors® Inc', value: 'Southeastern Indiana Board Of Realtors® Inc' },
            { text: 'Southeastern Oklahoma Association Of Realtors®', value: 'Southeastern Oklahoma Association Of Realtors®' },
            { text: 'Southern Adirondack Realtors® Inc', value: 'Southern Adirondack Realtors® Inc' },
            { text: 'Southern Gateway Association Of Realtors® Inc', value: 'Southern Gateway Association Of Realtors® Inc' },
            { text: 'Southern Indiana Realtors® Association', value: 'Southern Indiana Realtors® Association' },
            { text: 'Southern Maryland Association Of Realtors® Inc', value: 'Southern Maryland Association Of Realtors® Inc' },
            { text: 'Southern Middle Tennessee Association Of Realtors® Inc', value: 'Southern Middle Tennessee Association Of Realtors® Inc' },
            { text: 'Southern Ohio Association Of Realtors®', value: 'Southern Ohio Association Of Realtors®' },
            { text: 'Southern Oklahoma Board Of Realtors® Inc', value: 'Southern Oklahoma Board Of Realtors® Inc' },
            { text: 'Southern Piedmont Land & Lake Association Of Realtors®', value: 'Southern Piedmont Land & Lake Association Of Realtors®' },
            { text: 'Southern Vermont Association Of Realtors®', value: 'Southern Vermont Association Of Realtors®' },
            { text: 'Southland Regional Association Of Realtors® Inc', value: 'Southland Regional Association Of Realtors® Inc' },
            { text: 'Southshore Realtors® Association Inc', value: 'Southshore Realtors® Association Inc' },
            { text: 'Southside Virginia Association Of Realtors® Inc', value: 'Southside Virginia Association Of Realtors® Inc' },
            { text: 'Southwest Georgia Board Of Realtors® Inc', value: 'Southwest Georgia Board Of Realtors® Inc' },
            { text: 'Southwest Indiana Association Of Realtors®', value: 'Southwest Indiana Association Of Realtors®' },
            { text: 'Southwest Iowa Association Of Realtors®', value: 'Southwest Iowa Association Of Realtors®' },
            { text: 'Southwest Kansas Board Of Realtors®', value: 'Southwest Kansas Board Of Realtors®' },
            { text: 'Southwest Los Angeles Association Of Realtors®', value: 'Southwest Los Angeles Association Of Realtors®' },
            { text: 'Southwest Louisiana Association Of Realtors® Inc', value: 'Southwest Louisiana Association Of Realtors® Inc' },
            { text: 'Southwest Mississippi Board Of Realtors®', value: 'Southwest Mississippi Board Of Realtors®' },
            { text: 'Southwest Missouri Board Of Realtors® Inc', value: 'Southwest Missouri Board Of Realtors® Inc' },
            { text: 'Southwest Riverside County Association Of Realtors®', value: 'Southwest Riverside County Association Of Realtors®' },
            { text: 'Southwest Virginia Association Of Realtors®', value: 'Southwest Virginia Association Of Realtors®' },
            { text: 'Southwestern Michigan Association Of Realtors® Inc', value: 'Southwestern Michigan Association Of Realtors® Inc' },
            { text: 'Space Coast Association Of Realtors® , Inc', value: 'Space Coast Association Of Realtors® , Inc' },
            { text: 'Spartanburg Association Of Realtors® Inc', value: 'Spartanburg Association Of Realtors® Inc' },
            { text: 'Spokane Association Of Realtors®', value: 'Spokane Association Of Realtors®' },
            { text: 'Springfield Board Of Realtors®', value: 'Springfield Board Of Realtors®' },
            { text: 'Springfield Board Of Realtors®', value: 'Springfield Board Of Realtors®' },
            { text: 'St Augustine And St Johns County Board Of Realtors® Inc', value: 'St Augustine And St Johns County Board Of Realtors® Inc' },
            { text: 'St Charles County Association Of Realtors®', value: 'St Charles County Association Of Realtors®' },
            { text: 'St Clair Association Of Realtors®', value: 'St Clair Association Of Realtors®' },
            { text: 'St Cloud Area Association Of Realtors®', value: 'St Cloud Area Association Of Realtors®' },
            { text: 'St Croix Board Of Realtors®', value: 'St Croix Board Of Realtors®' },
            { text: 'St John Board Of Realtors®', value: 'St John Board Of Realtors®' },
            { text: 'St Joseph County Association Of Realtors®', value: 'St Joseph County Association Of Realtors®' },
            { text: 'St Lawrence County Board Of Realtors®', value: 'St Lawrence County Board Of Realtors®' },
            { text: 'St Louis Association Of Realtors®', value: 'St Louis Association Of Realtors®' },
            { text: 'St Paul Area Association Of Realtors® Inc', value: 'St Paul Area Association Of Realtors® Inc' },
            { text: 'St Thomas Board Of Realtors®', value: 'St Thomas Board Of Realtors®' },
            { text: 'Stamford Board Of Realtors® Inc', value: 'Stamford Board Of Realtors® Inc' },
            { text: 'Stark County Association Of Realtors® Inc', value: 'Stark County Association Of Realtors® Inc' },
            { text: 'Staten Island Board Of Realtors®', value: 'Staten Island Board Of Realtors®' },
            { text: 'Statesboro Board Of Realtors® Inc', value: 'Statesboro Board Of Realtors® Inc' },
            { text: 'Steamboat Springs Board Of Realtors®', value: 'Steamboat Springs Board Of Realtors®' },
            { text: 'Stephenville Association Of Realtors®', value: 'Stephenville Association Of Realtors®' },
            { text: 'Stillwater Board Of Realtors®', value: 'Stillwater Board Of Realtors®' },
            { text: 'Strafford County Board Of Realtors® Inc', value: 'Strafford County Board Of Realtors® Inc' },
            { text: 'Suburban West Realtors® Association', value: 'Suburban West Realtors® Association' },
            { text: 'Sullivan County Board Of Realtors®', value: 'Sullivan County Board Of Realtors®' },
            { text: 'Summit Realtors®', value: 'Summit Realtors®' },
            { text: 'Sumner Association Of Realtors® Inc', value: 'Sumner Association Of Realtors® Inc' },
            { text: 'Sumter Board Of Realtors®', value: 'Sumter Board Of Realtors®' },
            { text: 'Sun Valley Board Of Realtors®', value: 'Sun Valley Board Of Realtors®' },
            { text: 'Sunapee Region Board Of Realtors® Inc', value: 'Sunapee Region Board Of Realtors® Inc' },
            { text: 'Sunflower Association Of Realtors® Inc', value: 'Sunflower Association Of Realtors® Inc' },
            { text: 'Surry Regional Association Of Realtors®', value: 'Surry Regional Association Of Realtors®' },
            { text: 'Sussex County Association Of Realtors®', value: 'Sussex County Association Of Realtors®' },
            { text: 'Sussex County Association Of Realtors®', value: 'Sussex County Association Of Realtors®' },
            { text: 'Sutter Yuba Association Of Realtors® Inc', value: 'Sutter Yuba Association Of Realtors® Inc' },
            { text: 'Sweetwater County Board Of Realtors', value: 'Sweetwater County Board Of Realtors' },
            { text: 'Tacoma Pierce County Association Of Realtors® Inc', value: 'Tacoma Pierce County Association Of Realtors® Inc' },
            { text: 'Tahoe Sierra Board Of Realtors®', value: 'Tahoe Sierra Board Of Realtors®' },
            { text: 'Tall Pines Association Of Realtors® Inc', value: 'Tall Pines Association Of Realtors® Inc' },
            { text: 'Talladega County Board Of Realtors®', value: 'Talladega County Board Of Realtors®' },
            { text: 'Tallahassee Board Of Realtors® Inc', value: 'Tallahassee Board Of Realtors® Inc' },
            { text: 'Taos County Association Of Realtors® Inc', value: 'Taos County Association Of Realtors® Inc' },
            { text: 'Tehachapi Area Association Of Realtors® Inc', value: 'Tehachapi Area Association Of Realtors® Inc' },
            { text: 'Tehama County Association Of Realtors®', value: 'Tehama County Association Of Realtors®' },
            { text: 'Telluride Association Of Realtors® Inc', value: 'Telluride Association Of Realtors® Inc' },
            { text: 'Temple Belton Board Of Realtors® Inc', value: 'Temple Belton Board Of Realtors® Inc' },
            { text: 'Tennessee Valley Association Of Realtors® Inc', value: 'Tennessee Valley Association Of Realtors® Inc' },
            { text: 'Terre Haute Area Association Of Realtors®', value: 'Terre Haute Area Association Of Realtors®' },
            { text: 'Teton Board Of Realtors®', value: 'Teton Board Of Realtors®' },
            { text: 'Texarkana Board Of Realtors®', value: 'Texarkana Board Of Realtors®' },
            { text: 'Texoma Board Of Realtors®', value: 'Texoma Board Of Realtors®' },
            { text: 'The Heart Of Kentucky Association Of Realtors®', value: 'The Heart Of Kentucky Association Of Realtors®' },
            { text: 'The Inland Gateway Association Of Realtors®', value: 'The Inland Gateway Association Of Realtors®' },
            { text: 'The Northeast Association Realtors®', value: 'The Northeast Association Realtors®' },
            { text: 'The Realtors® Commercial Alliance Savannah/hilton Head Inc', value: 'The Realtors® Commercial Alliance Savannah/hilton Head Inc' },
            { text: 'Thomasville Area Board Of Realtors®', value: 'Thomasville Area Board Of Realtors®' },
            { text: 'Three Rivers Association Of Realtors® Inc', value: 'Three Rivers Association Of Realtors® Inc' },
            { text: 'Three Rivers Board Of Realtors® Inc', value: 'Three Rivers Board Of Realtors® Inc' },
            { text: 'Thurston County Realtors® Association', value: 'Thurston County Realtors® Association' },
            { text: 'Tiftarea Board Of Realtors® Inc', value: 'Tiftarea Board Of Realtors® Inc' },
            { text: 'Tillamook County Board Of Realtors®', value: 'Tillamook County Board Of Realtors®' },
            { text: 'Titus Camp Morris Upshur Board Of Realtors®', value: 'Titus Camp Morris Upshur Board Of Realtors®' },
            { text: 'Tooele County Association Of Realtors®', value: 'Tooele County Association Of Realtors®' },
            { text: 'Topsail Island Association Of Realtors® Inc', value: 'Topsail Island Association Of Realtors® Inc' },
            { text: 'Traverse Area Association Of Realtors®', value: 'Traverse Area Association Of Realtors®' },
            { text: 'Tri-counties Association Of Realtors', value: 'Tri-counties Association Of Realtors' },
            { text: 'Tri-county Alliance Of Realtors® Inc', value: 'Tri-county Alliance Of Realtors® Inc' },
            { text: 'Tri City Association Of Realtors® Inc', value: 'Tri City Association Of Realtors® Inc' },
            { text: 'Tri County Board Of Realtors®', value: 'Tri County Board Of Realtors®' },
            { text: 'Tri Lakes Board Of Realtors®', value: 'Tri Lakes Board Of Realtors®' },
            { text: 'Triangle Commercial Assoc Of Realtors', value: 'Triangle Commercial Assoc Of Realtors' },
            { text: 'Trinity County Association Of Realtors® Inc', value: 'Trinity County Association Of Realtors® Inc' },
            { text: 'Troup County Board Of Realtors®', value: 'Troup County Board Of Realtors®' },
            { text: 'Tucson Association Of Realtors® Inc', value: 'Tucson Association Of Realtors® Inc' },
            { text: 'Tulare County Association Of Realtors®', value: 'Tulare County Association Of Realtors®' },
            { text: 'Tuolumne County Association Of Realtors® Inc', value: 'Tuolumne County Association Of Realtors® Inc' },
            { text: 'Tuscaloosa Association Of Realtors®', value: 'Tuscaloosa Association Of Realtors®' },
            { text: 'Ulster County Board Of Realtors® Inc', value: 'Ulster County Board Of Realtors® Inc' },
            { text: 'Umatilla County Board Of Realtors®', value: 'Umatilla County Board Of Realtors®' },
            { text: 'Union County Association Of Realtors® Inc', value: 'Union County Association Of Realtors® Inc' },
            { text: 'Upper Cumberland Association Of Realtors® Inc', value: 'Upper Cumberland Association Of Realtors® Inc' },
            { text: 'Upper Peninsula Association Of Realtors® Inc', value: 'Upper Peninsula Association Of Realtors® Inc' },
            { text: 'Upper Valley Association Of Realtors®', value: 'Upper Valley Association Of Realtors®' },
            { text: 'Upper Valley Board Of Realtors® Inc', value: 'Upper Valley Board Of Realtors® Inc' },
            { text: 'Upstate Alliance Of Realtors®', value: 'Upstate Alliance Of Realtors®' },
            { text: 'Utah Central Association Of Realtors®', value: 'Utah Central Association Of Realtors®' },
            { text: 'Uvalde Board Of Realtors®', value: 'Uvalde Board Of Realtors®' },
            { text: 'Vail Board Of Realtors®', value: 'Vail Board Of Realtors®' },
            { text: 'Valdosta Board Of Realtors®', value: 'Valdosta Board Of Realtors®' },
            { text: 'Valley Association Of Realtors® Inc', value: 'Valley Association Of Realtors® Inc' },
            { text: 'Valley Board Of Realtors®', value: 'Valley Board Of Realtors®' },
            { text: 'Venice Area Board Of Realtors® Inc', value: 'Venice Area Board Of Realtors® Inc' },
            { text: 'Ventura County Coastal Association Of Realtors® Inc', value: 'Ventura County Coastal Association Of Realtors® Inc' },
            { text: 'Vicksburg Warren County Board Of Realtors®', value: 'Vicksburg Warren County Board Of Realtors®' },
            { text: 'Victoria Area Association Of Realtors® Inc', value: 'Victoria Area Association Of Realtors® Inc' },
            { text: 'Virginia Peninsula Association Of Realtors® Inc', value: 'Virginia Peninsula Association Of Realtors® Inc' },
            { text: 'Waco Association Of Realtors® Inc', value: 'Waco Association Of Realtors® Inc' },
            { text: 'Walker Area Association Of Realtors®', value: 'Walker Area Association Of Realtors®' },
            { text: 'Walla Walla Association Of Realtors®', value: 'Walla Walla Association Of Realtors®' },
            { text: 'Walton-barrow Board Of Realtors® Inc', value: 'Walton-barrow Board Of Realtors® Inc' },
            { text: 'Warren County Board Of Realtors®', value: 'Warren County Board Of Realtors®' },
            { text: 'Warren County Board Of Realtors®', value: 'Warren County Board Of Realtors®' },
            { text: 'Washington Beaufort County Board Of Realtors® Inc', value: 'Washington Beaufort County Board Of Realtors® Inc' },
            { text: 'Washington County Board Of Realtors®', value: 'Washington County Board Of Realtors®' },
            { text: 'Washington County Board Of Realtors®', value: 'Washington County Board Of Realtors®' },
            { text: 'Washington Greene Association Of Realtors® Inc', value: 'Washington Greene Association Of Realtors® Inc' },
            { text: 'Washington State Commercial Association Of Realtors® Inc', value: 'Washington State Commercial Association Of Realtors® Inc' },
            { text: 'Water Wonderland Board Of Realtors®', value: 'Water Wonderland Board Of Realtors®' },
            { text: 'Wayne-holmes Association Of Realtors®', value: 'Wayne-holmes Association Of Realtors®' },
            { text: 'Weirton Board Of Realtors®', value: 'Weirton Board Of Realtors®' },
            { text: 'West Branch Valley Board Of Realtors®', value: 'West Branch Valley Board Of Realtors®' },
            { text: 'West Central Association Of Realtors®', value: 'West Central Association Of Realtors®' },
            { text: 'West Central Association Of Realtors®', value: 'West Central Association Of Realtors®' },
            { text: 'West Central Association Of Realtors®', value: 'West Central Association Of Realtors®' },
            { text: 'West Central Association Of Realtors® Inc', value: 'West Central Association Of Realtors® Inc' },
            { text: 'West Central Iowa Regional Board Of Realtors®', value: 'West Central Iowa Regional Board Of Realtors®' },
            { text: 'West Georgia Board Of Realtors®', value: 'West Georgia Board Of Realtors®' },
            { text: 'West Hawaii Association Of Realtors®', value: 'West Hawaii Association Of Realtors®' },
            { text: 'West Metro Board Of Realtors® Inc', value: 'West Metro Board Of Realtors® Inc' },
            { text: 'West Michigan Lakeshore Association Of Realtors®', value: 'West Michigan Lakeshore Association Of Realtors®' },
            { text: 'West Pasco Board Of Realtors®', value: 'West Pasco Board Of Realtors®' },
            { text: 'West Plains Board Of Realtors®', value: 'West Plains Board Of Realtors®' },
            { text: 'West San Gabriel Valley Realtors®', value: 'West San Gabriel Valley Realtors®' },
            { text: 'West Volusia Association Of Realtors® Inc', value: 'West Volusia Association Of Realtors® Inc' },
            { text: 'West & Southeast Realtors® Of The Valley', value: 'West & Southeast Realtors® Of The Valley' },
            { text: 'Western Nebraska Board Of Realtors®', value: 'Western Nebraska Board Of Realtors®' },
            { text: 'Western Upstate Association Of Realtors® Inc', value: 'Western Upstate Association Of Realtors® Inc' },
            { text: 'Western Wisconsin Realtors® Association', value: 'Western Wisconsin Realtors® Association' },
            { text: 'Western Wyoming Board Of Realtors®', value: 'Western Wyoming Board Of Realtors®' },
            { text: 'Westmoreland West Association Of Realtors®', value: 'Westmoreland West Association Of Realtors®' },
            { text: 'Weston Buckhannon Board Of Realtors® Inc', value: 'Weston Buckhannon Board Of Realtors® Inc' },
            { text: 'Whatcom County Association Of Realtors® Inc', value: 'Whatcom County Association Of Realtors® Inc' },
            { text: 'Wheeling Board Of Realtors®', value: 'Wheeling Board Of Realtors®' },
            { text: 'Whidbey Island Association Of Realtors® Inc', value: 'Whidbey Island Association Of Realtors® Inc' },
            { text: 'White County Association Of Realtors®, Inc.', value: 'White County Association Of Realtors®, Inc.' },
            { text: 'White Mountain Association Of Realtors® Inc', value: 'White Mountain Association Of Realtors® Inc' },
            { text: 'White Mountain Board Of Realtors®', value: 'White Mountain Board Of Realtors®' },
            { text: 'Whitman County Association Of Realtors®', value: 'Whitman County Association Of Realtors®' },
            { text: 'Wichita Falls Association Of Realtors®', value: 'Wichita Falls Association Of Realtors®' },
            { text: 'Willamette Association Of Realtors®', value: 'Willamette Association Of Realtors®' },
            { text: 'Williamsburg Area Association Of Realtors® Inc', value: 'Williamsburg Area Association Of Realtors® Inc' },
            { text: 'Williamson County Association Of Realtors® Inc', value: 'Williamson County Association Of Realtors® Inc' },
            { text: 'Williamson County Association Of Realtors® Inc', value: 'Williamson County Association Of Realtors® Inc' },
            { text: 'Williston Board Of Realtors®', value: 'Williston Board Of Realtors®' },
            { text: 'Wilson Board Of Realtors®', value: 'Wilson Board Of Realtors®' },
            { text: 'Windsor County Board Of Realtors®', value: 'Windsor County Board Of Realtors®' },
            { text: 'Winston-salem Regional Association Of Realtors®', value: 'Winston-salem Regional Association Of Realtors®' },
            { text: 'Wiregrass Board Of Realtors® Inc', value: 'Wiregrass Board Of Realtors® Inc' },
            { text: 'Yakima Association Of Realtors® Inc', value: 'Yakima Association Of Realtors® Inc' },
            { text: 'Yamhill County Association Of Realtors®', value: 'Yamhill County Association Of Realtors®' },
            { text: 'Yancey Mitchell Board Of Realtors®', value: 'Yancey Mitchell Board Of Realtors®' },
            { text: 'Yolo County Association Of Realtors®', value: 'Yolo County Association Of Realtors®' },
            { text: 'Youngstown Columbiana Association Of Realtors® Inc', value: 'Youngstown Columbiana Association Of Realtors® Inc' },
            { text: 'Yuma Association Of Realtors® Inc', value: 'Yuma Association Of Realtors® Inc' },])
        }
        if (optionListName === "mlsAssociations") {
            setSelectOptions([{ text: 'Adirondack-Champlain Valley MLS', value: 'Adirondack-Champlain Valley MLS' },
            { text: 'Arizona Regional Multiple Listing Service, Inc.', value: 'Arizona Regional Multiple Listing Service, Inc.' },
            { text: 'Aspen/Glenwood Springs MLS Inc', value: 'Aspen/Glenwood Springs MLS Inc' },
            { text: 'Bridge MLS', value: 'Bridge MLS' },
            { text: 'Bright MLS', value: 'Bright MLS' },
            { text: 'California Regional MLS - CRMLS', value: 'California Regional MLS - CRMLS' },
            { text: 'CARETS MLS', value: 'CARETS MLS' },
            { text: 'Carolina MLS Inc.', value: 'Carolina MLS Inc.' },
            { text: 'Central Texas MLS', value: 'Central Texas MLS' },
            { text: 'Central Virginia Regional MLS', value: 'Central Virginia Regional MLS' },
            { text: 'Colorado Real Estate Network', value: 'Colorado Real Estate Network' },
            { text: 'Columbus & Central Ohio MLS', value: 'Columbus & Central Ohio MLS' },
            { text: 'Combined LA Westside - CLAW', value: 'Combined LA Westside - CLAW' },
            { text: 'Cooperative Arkansas Realtors MLS, Inc.', value: 'Cooperative Arkansas Realtors MLS, Inc.' },
            { text: 'Great Plains Regional MLS', value: 'Great Plains Regional MLS' },
            { text: 'Gulf South Real Estate Information Network', value: 'Gulf South Real Estate Information Network' },
            { text: 'Hawaii Information Service', value: 'Hawaii Information Service' },
            { text: 'HiCentral MLS, LTD', value: 'HiCentral MLS, LTD' },
            { text: 'Indiana Regional MLS LLC', value: 'Indiana Regional MLS LLC' },
            { text: 'Intermountain Multiple Listing Service', value: 'Intermountain Multiple Listing Service' },
            { text: 'IRES,LLC', value: 'IRES,LLC' },
            { text: 'Keystone MLS Network Inc', value: 'Keystone MLS Network Inc' },
            { text: 'Maine Real Estate Information System', value: 'Maine Real Estate Information System' },
            { text: 'MARIS MLS', value: 'MARIS MLS' },
            { text: 'Mississippi Gulf Coast MLS Inc.', value: 'Mississippi Gulf Coast MLS Inc.' },
            { text: 'MLS of Greater Cincinnati Inc', value: 'MLS of Greater Cincinnati Inc' },
            { text: 'MLS of the Wilmington Regional Assn of REALTORS', value: 'MLS of the Wilmington Regional Assn of REALTORS' },
            { text: 'MLSListings Inc', value: 'MLSListings Inc' },
            { text: 'Montana Regional MLS', value: 'Montana Regional MLS' },
            { text: 'Multiple Listing Service, Inc.', value: 'Multiple Listing Service, Inc.' },
            { text: 'New England Real Estate Network', value: 'New England Real Estate Network' },
            { text: 'New Jersey MLS Inc.', value: 'New Jersey MLS Inc.' },
            { text: 'New Mexico MLS', value: 'New Mexico MLS' },
            { text: 'North Carolina Regional MLS', value: 'North Carolina Regional MLS' },
            { text: 'North Central West Virginia Real Estate Information', value: 'North Central West Virginia Real Estate Information' },
            { text: 'North Santa Barbara County Regional MLS', value: 'North Santa Barbara County Regional MLS' },
            { text: 'North Texas R E Information Systems', value: 'North Texas R E Information Systems' },
            { text: 'Northern Great Lakes REALTORS® MLS', value: 'Northern Great Lakes REALTORS® MLS' },
            { text: 'Northern Michigan MLS', value: 'Northern Michigan MLS' },
            { text: 'Northern Mountains of Pennsylvania MLS', value: 'Northern Mountains of Pennsylvania MLS' },
            { text: 'Northern Nevada Regional MLS Inc.', value: 'Northern Nevada Regional MLS Inc.' },
            { text: 'OneKey MLS', value: 'OneKey MLS' },
            { text: 'Realcomp II Ltd.', value: 'Realcomp II Ltd.' },
            { text: 'RealTracs Inc', value: 'RealTracs Inc' },
            { text: 'REcolorado MLS', value: 'REcolorado MLS' },
            { text: 'Regional Multiple Listing Service of Minnesota, Inc.', value: 'Regional Multiple Listing Service of Minnesota, Inc.' },
            { text: 'RMLS', value: 'RMLS' },
            { text: 'RMLS Alliance', value: 'RMLS Alliance' },
            { text: 'Smart MLS Inc', value: 'Smart MLS Inc' },
            { text: 'Snake River Regional MLS', value: 'Snake River Regional MLS' },
            { text: 'South Central Kansas MLS', value: 'South Central Kansas MLS' },
            { text: 'South Jersey Shore Regional MLS', value: 'South Jersey Shore Regional MLS' },
            { text: 'Southern Missouri Regional MLS', value: 'Southern Missouri Regional MLS' },
            { text: 'State Wide MLS Inc.', value: 'State Wide MLS Inc.' },
            { text: 'Stellar MLS', value: 'Stellar MLS' },
            { text: 'Tennessee Virginia Regional MLS', value: 'Tennessee Virginia Regional MLS' },
            { text: 'Triad Multiple Listing Service Inc', value: 'Triad Multiple Listing Service Inc' },
            { text: 'Triangle MLS Inc.', value: 'Triangle MLS Inc.' },
            { text: 'Wasatch Front Regional MLS', value: 'Wasatch Front Regional MLS' },
            { text: 'Western Arizona REALTOR® Data Exchange', value: 'Western Arizona REALTOR® Data Exchange' },
            { text: 'Western Kentucky Regional MLS Inc', value: 'Western Kentucky Regional MLS Inc' },
            { text: 'Western Regional Info Systems & Technology Inc.', value: 'Western Regional Info Systems & Technology Inc.' },
            { text: 'Yes MLS', value: 'Yes MLS' },
            { text: 'Aiken MLS', value: 'Aiken MLS' },
            { text: 'Albemarle Area Association of REALTORS®', value: 'Albemarle Area Association of REALTORS®' },
            { text: 'Navica - Systems Engineering', value: 'Navica - Systems Engineering' },
            { text: 'Amarillo Association of REALTORS Multiple Listing Service', value: 'Amarillo Association of REALTORS Multiple Listing Service' },
            { text: 'Ashland Board of REALTORS® MLS', value: 'Ashland Board of REALTORS® MLS' },
            { text: 'Badlands Board of REALTORS® MLS', value: 'Badlands Board of REALTORS® MLS' },
            { text: 'Bagnell Dam MLS', value: 'Bagnell Dam MLS' },
            { text: 'Bay East Association of REALTORS® MLS', value: 'Bay East Association of REALTORS® MLS' },
            { text: 'Berkshire County Multiple Listing Service, Inc.', value: 'Berkshire County Multiple Listing Service, Inc.' },
            { text: 'BIG BEAR ASSOCIATION OF REALTORS®', value: 'BIG BEAR ASSOCIATION OF REALTORS®' },
            { text: 'Bismarck Mandan Board of REALTORS MLS', value: 'Bismarck Mandan Board of REALTORS MLS' },
            { text: 'Bradford Sullivan Association of REALTORS® INC MLS', value: 'Bradford Sullivan Association of REALTORS® INC MLS' },
            { text: 'FlexMLS', value: 'FlexMLS' },
            { text: 'Bryan-College Station Regional Multiple Listing Service, Inc.', value: 'Bryan-College Station Regional Multiple Listing Service, Inc.' },
            { text: 'California Desert Association of REALTORS MLS', value: 'California Desert Association of REALTORS MLS' },
            { text: 'Cambria Somerset Association of REALTORS', value: 'Cambria Somerset Association of REALTORS' },
            { text: 'Cape Cod & Islands Multiple Listing Service, Inc', value: 'Cape Cod & Islands Multiple Listing Service, Inc' },
            { text: 'Carolina Smokies Association of Realtors MLS', value: 'Carolina Smokies Association of Realtors MLS' },
            { text: 'Central Hill Country MLS', value: 'Central Hill Country MLS' },
            { text: 'Central Iowa MLS', value: 'Central Iowa MLS' },
            { text: 'Central Panhandle MLS', value: 'Central Panhandle MLS' },
            { text: 'Centre County Association of REALTORS® MLS', value: 'Centre County Association of REALTORS® MLS' },
            { text: 'Charlottesville Area Association of Realtors MLS', value: 'Charlottesville Area Association of Realtors MLS' },
            { text: 'Coastal Mendocino Association of REALTORS® Multiple Listing Service', value: 'Coastal Mendocino Association of REALTORS® Multiple Listing Service' },
            { text: 'Coeur d\'Alene Multiple Listing Service', value: 'Coeur d\'Alene Multiple Listing Service' },
            { text: 'Cullman Association of Realtors aka North Central Alabama MLS', value: 'Cullman Association of Realtors aka North Central Alabama MLS' },
            { text: 'Dayton Area Board of Realtors MLS', value: 'Dayton Area Board of Realtors MLS' },
            { text: 'Daytona Beach Area Association of REALTORS® MLS', value: 'Daytona Beach Area Association of REALTORS® MLS' },
            { text: 'Del Norte Association of Realtors Multiple Listing Service', value: 'Del Norte Association of Realtors Multiple Listing Service' },
            { text: 'Door County Board of REALTORS® Multiple Listing Service', value: 'Door County Board of REALTORS® Multiple Listing Service' },
            { text: 'East Central MLS', value: 'East Central MLS' },
            { text: 'East Central Iowa Multiple Listing Service', value: 'East Central Iowa Multiple Listing Service' },
            { text: 'Eastern Upper Peninsula Board of Realtors MLS', value: 'Eastern Upper Peninsula Board of Realtors MLS' },
            { text: 'Elmira Corning Regional Board of Realtors', value: 'Elmira Corning Regional Board of Realtors' },
            { text: 'FlexMLS', value: 'FlexMLS' },
            { text: 'Flagler County Multiple Listing Service', value: 'Flagler County Multiple Listing Service' },
            { text: 'Florida Keys MLS, Inc.', value: 'Florida Keys MLS, Inc.' },
            { text: 'Four County Board of Realtors MLS', value: 'Four County Board of Realtors MLS' },
            { text: 'Fresno Multiple Listing Service', value: 'Fresno Multiple Listing Service' },
            { text: 'Greater Augusta Association of REALTORS MLS, Inc.', value: 'Greater Augusta Association of REALTORS MLS, Inc.' },
            { text: 'Greater Chattanooga Realtors MLS', value: 'Greater Chattanooga Realtors MLS' },
            { text: 'Multiple Listing Service of Greenville SC', value: 'Multiple Listing Service of Greenville SC' },
            { text: 'MetroSearch, Inc', value: 'MetroSearch, Inc' },
            { text: 'Mason City Multiple Listing Service', value: 'Mason City Multiple Listing Service' },
            { text: 'Greater Owensboro REALTOR® Association MLS', value: 'Greater Owensboro REALTOR® Association MLS' },
            { text: 'Greater Portsmouth Area Board of Realtors MLS', value: 'Greater Portsmouth Area Board of Realtors MLS' },
            { text: 'MLS Technology', value: 'MLS Technology' },
            { text: 'Greater Tyler Association of REALTORS® MLS', value: 'Greater Tyler Association of REALTORS® MLS' },
            { text: 'Mississippi Gulf Coast Multiple Listing Service, Inc.', value: 'Mississippi Gulf Coast Multiple Listing Service, Inc.' },
            { text: 'Harrisonburg Rockingham Association of REALTORS MLS', value: 'Harrisonburg Rockingham Association of REALTORS MLS' },
            { text: 'Hinesville Area MultiList Corp', value: 'Hinesville Area MultiList Corp' },
            { text: 'Hopkinsville Christian & Todd County Association of REALTORS®', value: 'Hopkinsville Christian & Todd County Association of REALTORS®' },
            { text: 'Huntingdon County Board of REALTORS MLS', value: 'Huntingdon County Board of REALTORS MLS' },
            { text: 'ValleyMLS.com', value: 'ValleyMLS.com' },
            { text: 'IDYLLWILD ASSOCIATION OF REALTORS®', value: 'IDYLLWILD ASSOCIATION OF REALTORS®' },
            { text: 'Imperial County Association of Realtors MLS', value: 'Imperial County Association of Realtors MLS' },
            { text: 'JEFFERSON CITY AREA BOARD OF REALTORS®', value: 'JEFFERSON CITY AREA BOARD OF REALTORS®' },
            { text: 'Heartland MLS', value: 'Heartland MLS' },
            { text: 'Kerrville Board of REALTORS® MLS', value: 'Kerrville Board of REALTORS® MLS' },
            { text: 'Kings County Board of Realtors MLS', value: 'Kings County Board of Realtors MLS' },
            { text: 'LAKE HAVASU ASSOCIATION OF REALTORS MLS', value: 'LAKE HAVASU ASSOCIATION OF REALTORS MLS' },
            { text: 'LAKE OF THE OZARKS BOARD OF REALTORS®', value: 'LAKE OF THE OZARKS BOARD OF REALTORS®' },
            { text: 'Lake Region Multiple Listing Service, Inc.', value: 'Lake Region Multiple Listing Service, Inc.' },
            { text: 'Laramie Board of REALTORS® MLS', value: 'Laramie Board of REALTORS® MLS' },
            { text: 'Lawton Board of REALTORS® MLS', value: 'Lawton Board of REALTORS® MLS' },
            { text: 'Lee County Association of Realtors MLS Inc.', value: 'Lee County Association of Realtors MLS Inc.' },
            { text: 'Lincoln County MLS', value: 'Lincoln County MLS' },
            { text: 'Longview Area Association of Realtors MLS', value: 'Longview Area Association of Realtors MLS' },
            { text: 'Madisonville Hopkins County Board of Realtors MLS', value: 'Madisonville Hopkins County Board of Realtors MLS' },
            { text: 'Marion Area MLS, Inc.', value: 'Marion Area MLS, Inc.' },
            { text: 'Martin County Realtors of the Treasure Coast MLS', value: 'Martin County Realtors of the Treasure Coast MLS' },
            { text: 'Mid Carolina Regional MLS', value: 'Mid Carolina Regional MLS' },
            { text: 'Mid Valley Board of Realtors MLS', value: 'Mid Valley Board of Realtors MLS' },
            { text: 'Midland MLS', value: 'Midland MLS' },
            { text: 'Mid NY Regional MLS', value: 'Mid NY Regional MLS' },
            { text: 'Montcalm County Association of REALTORS®', value: 'Montcalm County Association of REALTORS®' },
            { text: 'Mount Rushmore MLS', value: 'Mount Rushmore MLS' },
            { text: 'Navarre Area Board of REALTORS® Inc MLS', value: 'Navarre Area Board of REALTORS® Inc MLS' },
            { text: 'Gulf South Real Estate Information Network', value: 'Gulf South Real Estate Information Network' },
            { text: 'North Central Board of Realtors', value: 'North Central Board of Realtors' },
            { text: 'Mountain Home MLS', value: 'Mountain Home MLS' },
            { text: 'Northeast Central Association of Realtors', value: 'Northeast Central Association of Realtors' },
            { text: 'NEG/CTI', value: 'NEG/CTI' },
            { text: 'Northeast Oklahoma Board of Realtors', value: 'Northeast Oklahoma Board of Realtors' },
            { text: 'Northeast Washington Association of Realtors MLS', value: 'Northeast Washington Association of Realtors MLS' },
            { text: 'NEWRA MLS (Northeast Wyoming Realtor Alliance MLS)', value: 'NEWRA MLS (Northeast Wyoming Realtor Alliance MLS)' },
            { text: 'NABOR (Northwest Arkansas BOR MLS)', value: 'NABOR (Northwest Arkansas BOR MLS)' },
            { text: 'Northwest Oklahoma Association of REALTORS', value: 'Northwest Oklahoma Association of REALTORS' },
            { text: 'Northwest Wyoming Board of REALTORS® MLS', value: 'Northwest Wyoming Board of REALTORS® MLS' },
            { text: 'Odessa Board of Realtors MLS', value: 'Odessa Board of Realtors MLS' },
            { text: 'MLSOK Inc.', value: 'MLSOK Inc.' },
            { text: 'Ozark Gateway Association of REALTORS MLS', value: 'Ozark Gateway Association of REALTORS MLS' },
            { text: 'Palm Springs Regional MLS', value: 'Palm Springs Regional MLS' },
            { text: 'Pearl River County Board of Realtors MLS', value: 'Pearl River County Board of Realtors MLS' },
            { text: 'Port Neches, Port Arthur, Nederland Board of Realtors MLS', value: 'Port Neches, Port Arthur, Nederland Board of Realtors MLS' },
            { text: 'Mid- Kansas MLS', value: 'Mid- Kansas MLS' },
            { text: 'Realtor Association of Franklin & Gulf Counties MLS', value: 'Realtor Association of Franklin & Gulf Counties MLS' },
            { text: 'Realtors Association of Indian River County MLS, Inc', value: 'Realtors Association of Indian River County MLS, Inc' },
            { text: 'Real Estate Information Services, Inc.', value: 'Real Estate Information Services, Inc.' },
            { text: 'REALTOR® Association of Southern MN MLS', value: 'REALTOR® Association of Southern MN MLS' },
            { text: 'Greater Pee Dee Multiple Listing Service', value: 'Greater Pee Dee Multiple Listing Service' },
            { text: 'REALTOR® Association of the Sioux Empire MLS, Inc.', value: 'REALTOR® Association of the Sioux Empire MLS, Inc.' },
            { text: 'Reelfoot Regional Association of REALTORS ® MLS', value: 'Reelfoot Regional Association of REALTORS ® MLS' },
            { text: 'Roanoke Valley MLS', value: 'Roanoke Valley MLS' },
            { text: 'RARMLS (Rockford Area Realtors MLS)', value: 'RARMLS (Rockford Area Realtors MLS)' },
            { text: 'Roswell Board of Realtors MLS', value: 'Roswell Board of Realtors MLS' },
            { text: 'Ruidoso / Lincoln County Association of REALTORS® MLS', value: 'Ruidoso / Lincoln County Association of REALTORS® MLS' },
            { text: 'San Angelo Association of REALTORS MLS', value: 'San Angelo Association of REALTORS MLS' },
            { text: 'SFARMLS (San Francisco Association of Realtors MLS)', value: 'SFARMLS (San Francisco Association of Realtors MLS)' },
            { text: 'Santa Fe Association of Realtors MLS', value: 'Santa Fe Association of Realtors MLS' },
            { text: 'Sedona Verde Valley Association of REALTORS MLS', value: 'Sedona Verde Valley Association of REALTORS MLS' },
            { text: 'Multiple Listing Service of the Shoals Area Association of REALTORS®', value: 'Multiple Listing Service of the Shoals Area Association of REALTORS®' },
            { text: 'Silver City Regional MLS', value: 'Silver City Regional MLS' },
            { text: 'Somerset-Lake Cumberland Realtors® MLS', value: 'Somerset-Lake Cumberland Realtors® MLS' },
            { text: 'South Central Association of Realtors MLS', value: 'South Central Association of Realtors MLS' },
            { text: 'South Padre Island Board of Realtors MLS', value: 'South Padre Island Board of Realtors MLS' },
            { text: 'South Tahoe Association of REALTORS® Multiple Listing Service', value: 'South Tahoe Association of REALTORS® Multiple Listing Service' },
            { text: 'Southern Adirondack Multiple Listing Service, Inc.', value: 'Southern Adirondack Multiple Listing Service, Inc.' },
            { text: 'Southern Oklahoma Board of Realtors MLS', value: 'Southern Oklahoma Board of Realtors MLS' },
            { text: 'Southern Piedmont Land & Lake Association of REALTORS® MLS', value: 'Southern Piedmont Land & Lake Association of REALTORS® MLS' },
            { text: 'Southwest Georgia Board of Realtors MLS', value: 'Southwest Georgia Board of Realtors MLS' },
            { text: 'Southwest Virginia Association of REALTORS®', value: 'Southwest Virginia Association of REALTORS®' },
            { text: 'Real Estate Information System of Southwestern Michigan, Inc', value: 'Real Estate Information System of Southwestern Michigan, Inc' },
            { text: 'Spokane Association of REALTORS® MLS', value: 'Spokane Association of REALTORS® MLS' },
            { text: 'Sumter Board of Realtors MLS', value: 'Sumter Board of Realtors MLS' },
            { text: 'Sun Valley MLS', value: 'Sun Valley MLS' },
            { text: 'Tahoe Sierra Multiple Listing Service, Inc.', value: 'Tahoe Sierra Multiple Listing Service, Inc.' },
            { text: 'Telluride Multiple Listing Service', value: 'Telluride Multiple Listing Service' },
            { text: 'Multiple Listing Service of The Terre Haute Area Association of Realtors®)', value: 'Multiple Listing Service of The Terre Haute Area Association of Realtors®)' },
            { text: 'Thomasville Area Board of REALTORS(R) MLS', value: 'Thomasville Area Board of REALTORS(R) MLS' },
            { text: 'TILLAMOOK COUNTY BOARD OF REALTORS®', value: 'TILLAMOOK COUNTY BOARD OF REALTORS®' },
            { text: 'West Alabama Multiple Listing Service', value: 'West Alabama Multiple Listing Service' },
            { text: 'Vail Multi-List Service Inc.', value: 'Vail Multi-List Service Inc.' },
            { text: 'Realty Server', value: 'Realty Server' },
            { text: 'Water Wonderland MLS', value: 'Water Wonderland MLS' },
            { text: 'West Central Association of REALTORS MLS', value: 'West Central Association of REALTORS MLS' },
            { text: 'West Central Association of REALTORS Multiple Listing Serv', value: 'West Central Association of REALTORS Multiple Listing Serv' },
            { text: 'West Michigan Lakeshore Association of REALTORS® Multiple Listing Service', value: 'West Michigan Lakeshore Association of REALTORS® Multiple Listing Service' },
            { text: 'Wiregrass Muliple Listing Service', value: 'Wiregrass Muliple Listing Service' },
            { text: 'New York State MLS', value: 'New York State MLS' },
            { text: 'NorthWest MLS', value: 'NorthWest MLS' },
            { text: 'MLS PIN', value: 'MLS PIN' },
            { text: 'First Multiple Listing Service', value: 'First Multiple Listing Service' },])
        }
        if (optionListName === "sellers") {
            api(`${process.env.REACT_APP_API_URL}/agent/client`).then(x => setSelectOptions(x.filter(y => y.type == "seller").map(y => { return { text: `${y.firstName} ${y.lastName}`, value: y._id } })))
        }
        if (optionListName === "buyers") {
            api(`${process.env.REACT_APP_API_URL}/agent/client`).then(x => setSelectOptions(x.filter(y => y.type == "buyer").map(y => { return { text: `${y.firstName} ${y.lastName}`, value: y._id } })))
        }
        if (optionListName === "brokers") {
            api(`${process.env.REACT_APP_API_URL}/agent/broker`).then(x => setSelectOptions(x.map(y => { return { text: y.name, value: y._id } })))
        }
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))
    if (!selectOptions) return <Loading></Loading>
console.log(selectOptions)
    return <div className={className + ' mt-2'}>
        <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
            {label}  {required && <span className="text-red-500">*</span>}
        </label>
        <div className="relative mt-1 rounded shadow-sm ">
            <Combobox value={selectOptions.find(x => x.value === value)} onChange={(e) => { onChange(e); }}>
                <div className="relative mt-1">
                    <div className=" relative w-full rounded  cursor-default overflow-hidden border border-gray-200  text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">

                        <Combobox.Input
                            placeholder={placeholder}
                            className="w-full bg-slate-100 border-none py-2 pl-3 pr-10 text-sm leading-5 text-neutral-700 focus:ring-0"
                            onChange={(e) => setQuery(e.target.value)}
                            displayValue={(x) => x?.text}
                            required={required}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered.map((x) => (
                                <Combobox.Option
                                    key={x.value}
                                    value={x.value}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-blue-600 text-white' : 'text-neutral-700'} ${x.value !== value && active ? 'bg-blue-400 text-white' : 'text-neutral-700'}`
                                    }
                                >
                                    {x.text}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>

                    </Transition>
                </div>
            </Combobox>
        </div>
    </div>
}

