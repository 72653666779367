import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";

import moment from "moment";

export default function ShowingForm(props) {

    return <div>
        <FormWrapper buttonText={'Submit Showing Request'} noStyle={true} url="agent/showing" noId {...props} callback={() => props.close()} defaultValue={{ date: props.date, listing: props.id }}>
            {(values, setValues) =>
                <div className="w-full max-w-4xl">
                    <div className="text-xl my-5 border-b text-neutral-800 font-semibold">Request a Showing ({moment(props.date).format('dddd, MMMM Do, YYYY hh:mmA')})</div>
                    <TextAreaField label="Notes" value={values.notes} onChange={(val) => setValues({ ...values, notes: val })} />
                </div>
            }
        </FormWrapper>
    </div>
}