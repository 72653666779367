export default function DateField({ label, value, onChange, className, inputClass, labelClass, onBlur }) {

    if (value?.length > 11) value = value.split("T")[0]

    return <div className={className + ' mt-2'}>
        <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
            {label}
        </label>
        <div className="mt-1">
            <input onBlur={onBlur} type="date" value={value} onChange={(e) => onChange(e.target.value)} className={`bg-slate-100 w-full block rounded border-0 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
        </div>
    </div>
}