import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete, api_file } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import ImageViewer from './components/image-viewer'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Popover } from '@headlessui/react'
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import FormModal from './forms/form-modal'
import ReplyToOfferForm from './forms/reply-to-offer-form'
import FileView from './components/file-view'
import { formatPhoneNumber } from './format-service'

const getAgentName = (agent) => {
  let name = '';
  if (agent.prefix) name += `${agent.prefix} `
  if (agent.firstName) name += `${agent.firstName} `
  if (agent.middleName) name += `${agent.middleName} `
  if (agent.lastName) name += `${agent.lastName} `
  if (agent.suffix) name += `${agent.suffix} `
  return name;
}

export default function Offers() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [myOffers, setMyOffers] = useState()
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/offer`).then(x => { setData(x); if (width > 1024) setSelected(x[0]) }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/agent/my-offer`).then(x => { setMyOffers(x); }) }, [])

  const [_selected, setSelected] = useState()
  const [replyFormData, setReplyFormData] = useState()

  const replyToOfferCallback = () => {
    setReplyFormData(null)
    api(`${process.env.REACT_APP_API_URL}/agent/offer`).then(x => setData(x))
  }

  const getColour = (status) => {
    if (status == "Approved") return 'green'
    if (status == "Countered") return 'gray'
    if (status == "Rejected") return 'red'
    return 'yellow'
  }

  if (!data) return <Loading></Loading>
  let selected = tab === 0 ? data.find(x => x._id == _selected?._id) : myOffers.find(x => x._id == _selected?._id)
  return (<>
    <FormModal open={!!replyFormData} setOpen={() => setReplyFormData(null)}><ReplyToOfferForm callback={() => replyToOfferCallback()} id={replyFormData?.id} status={replyFormData?.status}></ReplyToOfferForm></FormModal>
    <PageHeader breadcrumbs={[{ name: 'Offers', url: null }]}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto my-5">
        {!data?.length && !myOffers?.length && <div className="text-center mt-10">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-neutral-700">No Offers Yet</h3>
          <p className="mt-1 text-sm text-gray-500">You will be notified when an offer is sent to you.</p>
        </div>}
        {(data?.length > 0 || myOffers?.length > 0) && <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
          <div className={`${selected ? 'hidden lg:block' : ''}`}>
            <div className="bg-white rounded shadow ">
              <div className="flex text-center">
                <div onClick={() => setTab(0)} className={`flex-1 p-2 cursor-pointer ${tab === 0 ? 'bg-white border-b-2 border-blue-500' : 'font-light bg-neutral-50 border-b'}`}>
                  Inbound Offers
                </div>
                <div onClick={() => setTab(1)} className={`flex-1 p-2 cursor-pointer ${tab === 1 ? 'bg-white border-b-2 border-blue-500' : 'font-light bg-neutral-50 border-b'}`}>
                  My Offers
                </div>
              </div>
              {tab === 0 && <div className="mt-5 p-2">
                {data.map(x => <div onClick={() => setSelected(x)} className={`${selected?._id == x._id ? 'bg-blue-300' : 'hover:bg-blue-200'} flex space-x-2 rounded cursor-pointer p-2`}>
                  <ImageViewer className="w-16 h-16 object-cover rounded-md shadow" image={x.listing?.photos[0]} small></ImageViewer>
                  <div className="my-auto">
                    <div className="font-medium text-neutral-600 text-sm">{x.listing?.address}</div>
                    <div className={`text-${getColour(x.approvalStatus)}-500 text-sm`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 bg-${getColour(x.approvalStatus)}-500`}></span>
                      <span className="capitalize">{x.approvalStatus ?? 'Pending'}</span>
                    </div>
                  </div>
                </div>)}
              </div>}
              {tab === 1 && <div className="mt-5 p-2">
                {myOffers.map(x => <div onClick={() => { setSelected(x); console.log(x) }} className={`${selected?._id == x._id ? 'bg-blue-300' : 'hover:bg-blue-200'} flex space-x-2 rounded cursor-pointer p-2`}>
                  <ImageViewer className="w-16 h-16 object-cover rounded-md shadow" image={x.listing?.photos[0]} small></ImageViewer>
                  <div className="my-auto">
                    <div className="font-medium text-neutral-600 text-sm">{x.listing?.address}</div>
                    <div className={`text-${getColour(x.approvalStatus)}-500 text-sm`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 bg-${getColour(x.approvalStatus)}-500`}></span>
                      <span className="capitalize">{x.approvalStatus ?? 'Pending'}</span>
                    </div>
                  </div>
                </div>)}
              </div>}
            </div>
          </div>
          <div className="col-span-2">
            {selected && <div onClick={() => setSelected(null)} className="block lg:hidden  mb-2 text-neutral-500 font-medium hover:text-neutral-600 text-sm"><ChevronLeftIcon className="w-6 inline" /> Offers List</div>}

            {selected && <div>
              <div className="bg-white rounded shadow overflow-hidden p-5">
                <div className="font-semibold text-neutral-700 mb-10"><CalendarDaysIcon className="text-blue-500 w-6 inline mr-5"></CalendarDaysIcon> Sent {moment(selected.dateSent).format('MMMM Do, YYYY h:mm A')}</div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-2 mb-10">
                  <div className="flex space-x-4">
                    <ImageViewer className="w-16 h-16 rounded my-auto object-cover" image={selected.listing?.photos[0]} small></ImageViewer>
                    <div className="flex-1 my-auto">
                      <div className="font-semibold text-neutral-800">{selected.listing?.address}</div>
                      <div className="text-neutral-600 text-sm">Offer: ${selected.offerAmount}</div>
                      <div className="text-neutral-600 text-sm">Proposed Closing: {moment(selected.proposedClosingDate).format('MM/DD/YYYY')}</div>
                    </div>
                  </div>
                  <div className="flex space-x-4">

                    <ImageViewer person className="w-16 h-16 rounded-full my-auto object-cover" image={selected.owner.agent?.image} small></ImageViewer>
                    <div className="flex-1 my-auto">
                      <div className="font-semibold text-neutral-800">{getAgentName(selected.owner.agent)}</div>
                      {selected.owner.agent?.mobileNumber && <div className="text-neutral-600 text-sm">Mobile: {formatPhoneNumber(selected.owner.agent?.mobileNumber)}</div>}
                      {selected.owner.agent?.officeNumber && <div className="text-neutral-600 text-sm">Office: {formatPhoneNumber(selected.owner.agent?.officeNumber)}</div>}
                      <div className="text-neutral-600 text-sm">{selected.owner.agent?.contactEmail}</div>
                    </div>

                  </div>
                </div>
                {tab === 0 && !selected.approvalStatus && <div className="flex justify-evenly space-x-4 my-5">
                  <div className="flex-1">
                    <Button className="bg-green-500 hover:bg-green-600 text-white w-full" text="Approve" onClick={() => setReplyFormData({ id: selected._id, status: 'approve' })}></Button>
                  </div>
                  <div className="flex-1">
                    <Button className="bg-neutral-500 hover:bg-neutral-600 text-white w-full" text="Counter" onClick={() => setReplyFormData({ id: selected._id, status: 'counter' })}></Button>
                  </div>
                  <div className="flex-1">
                    <Button className="bg-red-500 hover:bg-red-600 text-white w-full" text="Reject" onClick={() => setReplyFormData({ id: selected._id, status: 'reject' })}></Button>
                  </div>
                </div>}
                {selected.approvalStatus && <div className="flex flex-col">
                  {selected.approvalStatus && <div className={`text-${getColour(selected.approvalStatus)}-500 text-sm`}>
                    <span className={`inline-block w-2 h-2 rounded-full mr-2 bg-${getColour(selected.approvalStatus)}-500`}></span>
                    <span className="capitalize">{selected.approvalStatus}</span>
                  </div>}
                  <div className="text-neutral-700 font-medium">{selected.statusMessage}</div>
                </div>}
              </div>

              <div className="bg-white rounded shadow mt-10 p-2">
                <Button text="Download Document" onClick={() => {
                  fetch(`${process.env.REACT_APP_API_URL}/agent/offer-document/${selected._id}`, {
                    method: 'GET',
                    headers: {
                      'Authorization': 'Bearer ' + localStorage.getItem('token'),
                      'Content-Type': 'application/pdf',
                    },
                  })
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', `Offer.pdf`,);
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode.removeChild(link);
                    });
                }}></Button>

                <FileView url={`agent/offer-document/${selected._id}`}></FileView>
              </div>
            </div>}
          </div>
        </div>}
      </div>
    </div>
  </>
  )
}