import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import TextAreaField from "./fields/text-area-field";
import ImageUpload from "./fields/image-upload";
import DateField from "./fields/date-field";

import { useNavigate, useParams } from "react-router-dom";
import PhoneField from "./fields/phone-field";

export default function BrokerForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader headline="Manage Broker"></PageHeader>
        <FormWrapper url="admin/broker" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) =>
                <>
                    <ImageUpload profile value={values.companyLogo} onChange={(val) => setValues({ ...values, companyLogo: val })}></ImageUpload>

                    <TextField label="Company Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                    <TextField label="Company Address" value={values.companyAddress} onChange={(val) => setValues({ ...values, companyAddress: val })}></TextField>
                    <div className="grid grid-cols-3 gap-2">
                        <PhoneField label="Company Phone" value={values.companyPhone} onChange={(val) => setValues({ ...values, companyPhone: val })}></PhoneField>
                        <TextField label="Principal Broker Extension" value={values.principalBrokerExtension} onChange={(val) => setValues({ ...values, principalBrokerExtension: val })}></TextField>
                        <TextField type="email" label="Company Email" value={values.companyEmail} onChange={(val) => setValues({ ...values, companyEmail: val })}></TextField>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <TextField label="Principal Broker" value={values.principalBroker} onChange={(val) => setValues({ ...values, principalBroker: val })}></TextField>
                        <TextField type="email" label="Principal Broker Email" value={values.principalBrokerEmail} onChange={(val) => setValues({ ...values, principalBrokerEmail: val })}></TextField>
                    </div>
                </>
            }
        </FormWrapper>
    </div>
}