import { Routes, Route } from "react-router-dom";
import AdminBrokers from "./admin-brokers";
import AdminDashboard from "./admin-dashboard";
import AdminLayout from "./admin-layout";
import AdminUsers from "./admin-users";
import Clients from "./clients";
import Dashboard from "./dashboard";
import BrokerForm from "./forms/broker-form";
import ClientForm from "./forms/client-form";
import ProfileForm from "./forms/profile-form";
import ListingForm from "./forms/listing-form";
import UserForm from "./forms/user-form";
import Layout from "./layout";
import Listings from "./listings";
import Login from "./login";
import Logout from "./logout";
import Register from "./register";
import RequestReset from "./request-reset2";
import ResetPassword from "./reset-password2";
import Listing from "./listing";
import Offers from "./offers";
import Showings from "./showings";
import ClientLayout from "./client-layout";
import ClientDashboard from "./client-dashboard";
import ClientDocument from "./client-document";
import OfferForm from "./forms/offer-form";
import AdminAgentForm from "./forms/admin-agent-form";
import CtaRegister from "./cta-register";
import LoggedOutLayout from "./logged-out-layout";
import ClientRegister from "./client-register";

export default function App() {

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/client-register" element={<ClientRegister />} />
      <Route path="/create-account" element={<CtaRegister />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />
      <Route path="/logout" element={<Logout />} />

      <Route path='/' element={<LoggedOutLayout />}>
        <Route path="/listing/:id" element={<Listing isPublic={true} />} />

      </Route>

      <Route path='/app' element={<Layout />}>
        <Route path="/app" element={<Dashboard />} />
        <Route path="/app/clients" element={<Clients />} />
        <Route path="/app/manage-client/:id" element={<ClientForm />} />
        <Route path="/app/listings" element={<Listings />} />
        <Route path="/app/offers" element={<Offers />} />
        <Route path="/app/showings" element={<Showings />} />
        <Route path="/app/listing/:id" element={<Listing />} />
        <Route path="/app/manage-listing/:id" element={<ListingForm />} />
        <Route path="/app/profile" element={<ProfileForm />} />
        <Route path="/app/make-offer/:id" element={<OfferForm />} />
      </Route>

      <Route path='/admin' element={<AdminLayout></AdminLayout>}>
        <Route path="/admin" element={<AdminDashboard></AdminDashboard>} />
        <Route path="/admin/brokers" element={<AdminBrokers></AdminBrokers>} />
        <Route path="/admin/users" element={<AdminUsers></AdminUsers>} />
        <Route path="/admin/manage-broker/:id" element={<BrokerForm></BrokerForm>} />
        <Route path="/admin/manage-user/:id" element={<UserForm></UserForm>} />
        <Route path="/admin/manage-profile/:id" element={<AdminAgentForm></AdminAgentForm>} />
      </Route>

      <Route path='/client' element={<ClientLayout></ClientLayout>}>
        <Route path="/client" element={<ClientDashboard></ClientDashboard>} />
        <Route path="/client/offer/:id" element={<ClientDocument></ClientDocument>} />
      </Route>
    </Routes>
  )
}
