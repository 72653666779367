import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PasswordField from './forms/fields/password-field';
import SelectField from './forms/fields/select-field';
import TextField from './forms/fields/text-field';
import logo from './logo.svg'
import loginPhoto from './signin_page.png'

export default function ClientRegister() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [values, setValues] = useState({});
  const [error, setError] = useState("")

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values, metaData: { ref: searchParams.get('r'), client: searchParams.get('c') } })
    }).then(res => { return res.json() }).then(x => {
      if (x.error) {
        if (x.error == "email_in_use") {
          setError("Email already in use. Please sign in or register with a different email.");
        }
      }
      else if (x.token) {
        localStorage.setItem("token", x.token);
        if (searchParams.get('rd')) {
          window.location.replace(searchParams.get('rd'))
        }
        else {
          window.location.replace(x.redirectUrl)
        }
      }
      else {
        setError("Registration failed. Please check your details and try again.")
      }
    })
  }

  return (
    <>
      <div className="flex max-h-screen flex-col bg-slate-50">
        <div className="grid grid-cols-1 lg:grid-cols-2 flex-1 max-h-screen">
          <div className="flex flex-col justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-64 h-auto mb-10"
                src={logo}
              />
            </div>
            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="bg-white rounded py-8 px-4 shadow sm:px-10">
                <div className="font-medium text-xl text-neutral-800 text-center">Create your account</div>
                <div className="font-light text-sm text-neutral-500 text-center my-5">Already have an account? <a className="text-blue-400 hover:text-blue-500" href="/login">Sign In</a></div>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-neutral-800 text-center">
                      {error}
                    </label>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <TextField required label="First Name" value={values.firstName} onChange={(val) => setValues({ ...values, firstName: val })}></TextField>
                    <TextField required label="Last Name" value={values.lastName} onChange={(val) => setValues({ ...values, lastName: val })}></TextField>
                  </div>

                  <div>
                    <TextField required email label="Email" value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                  </div>

                  <div className="grid grid-cols-2 gap-4">

                    <PasswordField required email label="Password" value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                    <PasswordField required email label="Confirm Password" value={values.confirmPassword} onChange={(val) => setValues({ ...values, confirmPassword: val })}></PasswordField>

                  </div>

                  <div className="flex">
                    <input checked={values.tos} onChange={(e) => setValues({ ...values, tos: e.target.checked })}
                      type="checkbox"
                      className="block appearance-none rounded border mt-0.5 border-gray-200  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                    />

                    <div className="text-light text-neutral-500 ml-2 text-sm">
                      I accept the platform <a target="_blank" href="https://realtyview.com/terms-of-use/" className="text-blue-400 hover:text-blue-500">Terms of Service</a>
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={() => submit()}
                      type="button"
                      disabled={!values.firstName || !values.lastName ||  !values.email || !values.password || values.password != values.confirmPassword || !values.tos}
                      className="disabled:bg-gray-500 flex rounded w-full justify-center border border-transparent bg-blue-500 py-2 px-4 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:flex hidden max-h-screen">
            <img src={loginPhoto} className="object-cover flex-1"></img>
          </div>
        </div>

      </div>
    </>
  )
}
