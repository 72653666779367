import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'

export default function AdminUsers() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/user/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/user`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/user`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

    <PageHeader headline={"Users"} actions={<Button text="Add User" onClick={() => navigate('/admin/manage-user/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto my-10">
        <div>
          <Table
            exportColumns={['Email']} exportData={data.map(x => {
              return {
                email: x.email
              }
            })}
            data={data.map(x => {
              return {
                ...x, edit: <span className="cursor-pointer underline" onClick={() => navigate(`/admin/manage-user/${x._id}`)}>Edit</span>,
                editProfile: <span className="cursor-pointer underline" onClick={() => navigate(`/admin/manage-profile/${x._id}`)}>Edit Profile</span>,
                deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>
              }
            })} columns={[
              {
                Header: 'Email',
                accessor: 'email',
              },
              {
                Header: 'Edit Profile',
                accessor: 'editProfile',
              },
              {
                Header: 'Edit',
                accessor: 'edit',
              },
              {
                Header: 'Delete',
                accessor: 'deleteCell',
              },
            ]}></Table>
        </div>
      </div>
    </div>
  </>
  )
}