import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useRef, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate, useParams } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import FileView from './components/file-view'
import FormModal from './forms/form-modal'
import SignatureCanvas from 'react-signature-canvas'
import TextField from './forms/fields/text-field'
import ImageViewer from './components/image-viewer'
import moment from 'moment'


const getAgentName = (agent) => {
  let name = '';
  if (agent.prefix) name += `${agent.prefix} `
  if (agent.firstName) name += `${agent.firstName} `
  if (agent.middleName) name += `${agent.middleName} `
  if (agent.lastName) name += `${agent.lastName} `
  if (agent.suffix) name += `${agent.suffix} `
  return name;
}

export default function ClientDocument() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState({})
  const params = useParams();
  const sigRef = useRef(null);

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/client/offer/${params.id}`).then(x => {
      setData(x.offer)
    })
  }, [])

  const submitSignature = () => {
    api(`${process.env.REACT_APP_API_URL}/client/offer/${params.id}`, { name: values.name, signatureDataUrl: sigRef.current?.getTrimmedCanvas().toDataURL() }).then(x => {
      navigate('/client')
    })
  }

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader homeUrl='/client' breadcrumbs={[{ name: 'Offer' }]}></PageHeader>
    <FormModal open={open} setOpen={setOpen}>

      <div className="p-10 flex flex-col w-full">
        <div>Sign Document</div>
        <TextField label="Type Your Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })} />
        <div className="w-full h-28 my-5">
          <SignatureCanvas canvasProps={{ width: 500, height: 100, className: "border border-gray-200" }} ref={sigRef} penColor='black' />

        </div>
        <Button text="Submit" onClick={() => submitSignature()}></Button>
      </div>
    </FormModal>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto flex flex-col space-y-4 justify-center items-center">

        <div className="bg-white rounded shadow p-5 my-5 w-full">

          <div className="grid grid-cols-2">
            <div className="flex space-x-4">
              <ImageViewer className="w-16 h-16 rounded my-auto object-cover" image={data.listing?.photos[0]} small></ImageViewer>
              <div className="flex-1 my-auto">
                <div className="font-semibold text-neutral-800">{data.listing?.address}</div>
                <div className="text-neutral-600 text-sm">Offer: ${data.offerAmount}</div>
                <div className="text-neutral-600 text-sm">Proposed Closing: {moment(data.proposedClosingDate).format('MM/DD/YYYY')}</div>
              </div>
            </div>
            <div className="flex space-x-4">
              <ImageViewer person className="w-16 h-16 rounded my-auto object-cover" image={data.owner.agent?.image} small></ImageViewer>
              <div className="flex-1 my-auto">
                <div className="font-semibold text-neutral-800">{getAgentName(data.owner.agent)}</div>
                {data.owner.agent?.mobileNumber && <div className="text-neutral-600 text-sm">Mobile: {data.owner.agent?.mobileNumber}</div>}
                {data.owner.agent?.officeNumber && <div className="text-neutral-600 text-sm">Office: {data.owner.agent?.officeNumber}</div>}
                <div className="text-neutral-600 text-sm">{data.owner.agent?.contactEmail}</div>
              </div>
            </div>
          </div>
        </div>



        <FileView url={`client/offer-document/${params.id}`}></FileView>
        {!data.signed && <div>
          <Button text="Sign" onClick={() => setOpen(true)}></Button>
        </div>}
      </div>
    </div>
  </>
  )
}